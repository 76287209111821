import { get } from 'lodash'
import moment from 'moment'
import { calculateAge } from './constants';

export function convertFhirPatientToDisplayPatient(fhirPatient) {
  const resource = fhirPatient?.resource || fhirPatient
  let bdate = get(resource, 'birthDate', '')
  bdate = moment(bdate).format("ll")
  bdate = bdate === 'Invalid date' ? '' : bdate
  let lastUpdate = get(resource, 'meta.lastUpdated', '')
  lastUpdate = moment(new Date(lastUpdate)).format("ll")
  lastUpdate = lastUpdate === 'Invalid date' ? '' : lastUpdate
  const city = get(resource, 'address[0].city', '')
  const state = get(resource, 'address[0].state', '')

  const displayPatient = {};

  displayPatient['id'] = get(resource, 'id', null)
  displayPatient['firstName'] = get(resource, 'name[0].given[0]', '')
  displayPatient['lastName'] = get(resource, 'name[0].family', '')
  displayPatient['birthDate'] = bdate
  displayPatient['age'] = calculateAge(bdate)
  displayPatient['location'] = city ? `${city}, ${state}` : ''
  displayPatient['gender'] = get(resource, 'gender', '')
  displayPatient['lastUpdate'] = lastUpdate
  displayPatient['address'] = get(resource, 'address[0].line', '')
  displayPatient['phone'] = get(resource, 'telecom[0].value', '')
  displayPatient['deceased'] = get(resource, 'deceasedBoolean', 'N/A')

  return displayPatient;
}
