import { useEffect, useState } from "react";
import { CarePlansAPI } from "../CarePlansAPI";
import { CarePlanBundle } from "./fhir-utils";

export function usePatientCarePlanBundle(patientId, setCurrentCarePlan) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [carePlanBundle, setCarePlanBundle] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const carePlanBundle = await CarePlansAPI.getPatientCarePlansBundle(
          patientId
        );
        setCarePlanBundle(
          new CarePlanBundle(carePlanBundle, setCurrentCarePlan)
        );
      } catch (error) {
        console.error(error);
        setError(error);
      }
      setLoading(false);
    })();
  }, [patientId]);

  return { loading, error, carePlanBundle };
}
