import React, { Fragment } from 'react'
import moment from 'moment'
import Alert from '@material-ui/lab/Alert'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'

export function ItemList({
  valueLabelPairsArray,
  ItemIconComponent,
  itemLimit,
  classes,
  handleItemClick,
  open,
}) {
  if (
    valueLabelPairsArray
    && valueLabelPairsArray.length === 1
    && valueLabelPairsArray[0].error
  ) {
    return (
      <Alert
        className={classes.nested}
        severity="error"
      >
        {valueLabelPairsArray[0].error}
      </Alert>
    )
  }
  else if (valueLabelPairsArray && valueLabelPairsArray.length === 0) {
    return <Fragment/>
  }
  else {
    return (
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <List
          component="div"
          disablePadding
          dense={false}
        >
          {
            (
              valueLabelPairsArray || []
            ).map((valueLabelPair, index) => {
              if (index + 1 > itemLimit) {
                return undefined;
              }
              return (
                <ListItem
                  button
                  className={classes.nested}
                  key={index}
                  onClick={() => handleItemClick(valueLabelPair.value)}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <ItemIconComponent/>
                  </ListItemIcon>
                  {/*<ListItemText
                    secondary={valueLabelPair.label}
                    onClick={() => handleItemClick(valueLabelPair.value)}
                  />*/}
                  <div
                    className={classes.itemLabel}
                  >
                    <span>
                    {valueLabelPair.label}
                    </span>
                  </div>
                  <div className={classes.patientInfo}>
                    <div>
                      <span className={classes.infoLabel}>MRN: </span>
                      <span>{valueLabelPair.value.patientid}</span>
                    </div>
                    <div>
                      <span className={classes.infoLabel}>Last Viewed: </span>
                      <span>{moment(new Date(valueLabelPair.value.timestamp)).format("ll")}</span>
                    </div>
                  </div>

                </ListItem>
              )
            })
          }
        </List>
      </Collapse>
    )
  }
}
