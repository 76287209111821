import React from "react";
import { MenuItem } from "@material-ui/core";
import { noCarePlans } from "../../utils/careplan-utils";

export const EMPTY_VALUE = "--- EMPTY VALUE ---";
const ERROR_VALUE = "⛔ Unable to load Care Plans - An error occurred.";
const LOADING_VALUE = "Loading Patient Care Plans...";
const NO_CAREPLANS_VALUE = "⚠️ No Care Plans were found.";

export function getCarePlanMenuItems(carePlans, loading, error) {
  if (loading) {
    return <MenuItem value={LOADING_VALUE}>{LOADING_VALUE}</MenuItem>;
  }
  if (noCarePlans(carePlans, error)) {
    return <MenuItem value={NO_CAREPLANS_VALUE}>{NO_CAREPLANS_VALUE}</MenuItem>;
  }
  if (error) {
    return <MenuItem value={ERROR_VALUE}>{ERROR_VALUE}</MenuItem>;
  }
  return [
    <MenuItem key={EMPTY_VALUE} value={EMPTY_VALUE}>
      --- Select a Care Plan ---
    </MenuItem>,
    ...getCarePlanOptions(carePlans).map((option) => (
      <MenuItem key={option.id} value={option.id}>
        {option.display}
      </MenuItem>
    )),
  ];
}

export function getCarePlanSelectedItemValue(
  carePlans,
  loading,
  error,
  selectedCarePlanId
) {
  if (loading) {
    return LOADING_VALUE;
  }
  if (noCarePlans(carePlans, error)) {
    return NO_CAREPLANS_VALUE;
  }
  if (error) {
    return ERROR_VALUE;
  }
  return selectedCarePlanId;
}

export function getCarePlanValueRenderer(error) {
  if (error) {
    return (value) => <span style={{ color: "red" }}>{value}</span>;
  }
}

function getCarePlanOptions(carePlans) {
  return (carePlans || [])
    .map((carePlan) =>
      !carePlan?.getId()
        ? null
        : {
            id: carePlan.getId(),
            display: carePlan.getDisplayOption(),
          }
    )
    .filter(Boolean)
    .sort((o1, o2) => (o1.display <= o2.display ? -1 : 1));
}
