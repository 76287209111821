import React, { useContext, useEffect, useState } from "react";
import FhirReferralDocumentList from "./FhirReferralDocumentList";
import { DocumentUtils } from "../../../services";
import { Context } from "../../../store/AppStore";
import { useHistory } from "react-router-dom";
import { getReferralPatientId } from "../../../services/FhirReferralStates";
import { DocumentCatalogsStore } from "../Documents/DocumentCatalogsStore";

export default function FhirReferralDocuments({
  referral,
  documents,
  patientId,
  onDocumentDelete,
}) {
  const history = useHistory();
  const dispatch = useContext(Context)[1];
  const [catalogs, setCatalogs] = useState(null);

  useEffect(
    () => {
      (async () => {
        setCatalogs(await DocumentCatalogsStore.getDocumentCatalogs());
      })();
    }
  )

  const handleDocument = async (data) => {
    if (data) {
      dispatch({ type: "UPDATE_DOCUMENT_DATA", payload: data });
      history.push(
        `/patient/${patientId || getReferralPatientId(referral)}/file/${
          data.document.id
        }/${data.iconName || "file-alt"}`
      );
    }
  };

  if (!catalogs) {
    return null;
  }

  return (
    <>
      <h1
        style={{
          marginTop: "20px",
          marginBottom: "10px",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        Documents
      </h1>
      <FhirReferralDocumentList
        data={documents.map((document) =>
          DocumentUtils.getDisplayInfo(document, catalogs)
        )}
        showDocument={handleDocument}
        onDocumentDelete={onDocumentDelete}
      />
    </>
  );
}
