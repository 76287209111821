import React, { Component } from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import {
  Restore,
  ZoomIn,
  ZoomOut,
  //ZoomOutMap
} from '@material-ui/icons'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import FileActionsBar from '../FileActionsBar/FileActionsBar'
import colors from '../../../../utils/colors'
import styles from '../../../../styles/viewer.module.css'
import Tiff from 'tiff.js';

const theme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        color: colors.gray01,
        cursor: 'pointer',
        height: '25px',
        margin: '0 5px',
        width: '25px'
      }
    }
  },
})

export default class ImageViewer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rotation: 0
    }
  }

  rotateRight = () => {
    let newRotation = this.state.rotation + 90;
    if(newRotation >= 360) {
      newRotation =- 360;
    }
    this.setState({
      rotation: newRotation,
    })
  }

  rotateLeft = () => {
    let newRotation = this.state.rotation - 90;
    if(newRotation >= 360) {
      newRotation =- 360;
    }
    this.setState({
      rotation: newRotation,
    })
  }

  render() {
    const {
      fileData,
      fileType,
      handleBack,
      handlePrint,
      fileContentType,
    } = this.props
    const { rotation } = this.state

    return(
      <ThemeProvider theme={theme}>
        <div className={styles.viewer}>
          <FileActionsBar
            fileType={fileType}
            fileData={fileData}
            handleBack={handleBack}
            handleLeft={this.rotateLeft}
            handlePrint={handlePrint}
            handleRight={this.rotateRight}
          />
          <TransformWrapper
            defaultScale={1}
            defaultPositionX={0}
            defaultPositionY={0}
            limitToWrapper={true}
          >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <React.Fragment>
              <div className={styles.zoomTools}>
                <ZoomIn onClick={zoomIn} />
                <Restore onClick={resetTransform} />
                <ZoomOut onClick={zoomOut} />
              </div>
              <div className={styles.imgContainer}>
                <TransformComponent className={styles.imgContainer}>
                  <img
                    alt="img"
                    className={styles.imgViewer}
                    src={getImageDataUrl(fileContentType, fileData)}
                    style={{
                      transform: `rotate(${rotation}deg)`,
                      maxWidth: '100%',
                    }}
                  />
                </TransformComponent>
              </div>
            </React.Fragment>
          )}
          </TransformWrapper>
        </div>
      </ThemeProvider>
    )
  }
}

function getImageDataUrl(mimeType, base64) {
  if (mimeType === 'image/tiff') {
    const tiff = new Tiff({ buffer: base64ToArrayBuffer(base64) });
    return tiff.toDataURL();
  }
  return `data:${mimeType};base64,${base64}`;
}

function base64ToArrayBuffer(base64) {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}
