import React, { Fragment } from "react";
import { statusRenderer } from "../value-renderers";
import { ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { theme } from "../theme";
import { tableIcons } from "../table-icons";
import ReferralsTable from "material-table";
import AssessmentTable from "material-table";

const AssessmentsList = ({
  showAllAssessments,
  assessments,
  onAssessmentClick,
}) => {
  const columns = [
    {
      title: "Id",
      render: (referral) => <div></div>,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Name",
      render: (referral) => <div></div>,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
  ];

  if (assessments) {
    return (
      <ThemeProvider theme={theme}>
        <AssessmentTable
          title=""
          columns={columns}
          data={assessments}
          icons={tableIcons}
          // onRowClick={(e, data) => showDocument(data)}
          options={{
            emptyRowsWhenPaging: false,
            maxBodyHeight: "100%",
            padding: "dense",
            pageSize: 10,
            search: true,
            sorting: true,
          }}
        />
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <div>
          <CircularProgress />
        </div>
      </ThemeProvider>
    );
  }
};

export default AssessmentsList;
