import _ from "lodash";
import { validateTarget } from "./property-utils";

const DISPLAY_TO_VALUE = {};
const VALUE_TO_DISPLAY = {};

function getDisplayValueMappings(propertyPath, values) {
  let displayToValue = DISPLAY_TO_VALUE[propertyPath];
  let valueToDisplay = VALUE_TO_DISPLAY[propertyPath];
  if (!displayToValue) {
    displayToValue = _.keyBy(values, _.startCase);
    DISPLAY_TO_VALUE[propertyPath] = displayToValue;
    valueToDisplay = Object.entries(displayToValue).reduce(
      (valueToDisplay, [display, value]) => {
        valueToDisplay[value] = display;
        return valueToDisplay;
      },
      {}
    );
    VALUE_TO_DISPLAY[propertyPath] = valueToDisplay;
  }
  return { values, displayToValue, valueToDisplay };
}

function newCatalogSetter({ propertyPath, mappings, optionMapper }) {
  return (target, value) => {
    validateTarget(target, propertyPath, true);
    const { values, displayToValue } = mappings;
    const comparisonValues = optionMapper
      ? values.map(optionMapper).map((option) => option.value)
      : values;
    const newValue = comparisonValues.includes(value)
      ? value
      : displayToValue[value];
    if (!newValue) {
      throw new Error(
        [
          `Attempted to set an invalid value: "${value}" on property "${propertyPath}". Valid values are: [ `,
          comparisonValues.map((value) => `"${value}"`).join(", "),
          " ].",
        ].join("")
      );
    }
    _.set(target, propertyPath, value);
  };
}

function newCatalogGetter(propertyPath, mappings) {
  return (target) => {
    validateTarget(target, propertyPath);
    const value = _.get(target, propertyPath);
    return mappings.valueToDisplay[value] || value || "";
  };
}

const DEFAULT_OPTION_MAPPER = (_) => ({ value: _, display: _ });

export function newCatalogProperty({
  propertyPath,
  catalog,
  items,
  optionMapper = DEFAULT_OPTION_MAPPER,
}) {
  const mappings = getDisplayValueMappings(propertyPath, items);
  const { values, valueToDisplay } = mappings;
  const getValues = () =>
    values.map((value) =>
      !optionMapper || optionMapper === DEFAULT_OPTION_MAPPER
        ? valueToDisplay[value]
        : optionMapper(value)
    );
  return {
    getFrom: newCatalogGetter(propertyPath, mappings),
    setTo: newCatalogSetter({ propertyPath, mappings, optionMapper }),
    items,
    catalog,
    getValues,
  };
}

export function newLazyCatalogProperty({
  propertyPath,
  catalogFetcher,
  itemMapper = (_) => _,
  optionMapper = null,
}) {
  let property;
  const init = async () => {
    if (property) {
      return;
    }
    const catalog = await catalogFetcher();
    const items = itemMapper(catalog);
    property = newCatalogProperty({
      propertyPath,
      catalog,
      items,
      optionMapper,
    });
  };
  return {
    get items() {
      return property?.items || [];
    },
    getItems: async () => {
      await init();
      return property.items;
    },
    getCatalog: async () => {
      await init();
      return property.catalog;
    },
    getValues: async () => {
      await init();
      return property.getValues;
    },
  };
}
