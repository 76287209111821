import axios from 'axios'
import auth from '../utils/auth'

export function UserHistory(dataUrl, username, useMockData) {
  if (useMockData) {
    return new Promise(resolve => {
      resolve(mockData())
    })
  }
  else {
    return serverData(dataUrl, username)
  }
}

function serverData(dataUrl) {
  return new Promise((resolve, reject) => {
    if (!dataUrl) {
      reject(new Error('User History URL was not provided.'))
    }
    else {
      let user = auth.user();
      const username = user && user.userinfo && user.userinfo.preferred_username
      const url = `${dataUrl}/${username}`
      const _axios= axios.create({
        baseURL: `${dataUrl}/`,
        timeout: 5000,
        headers: auth.header()
      })
      _axios.get(url)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
      ;
    }
  })
}

function mockData() {
  return {
    "code": "RESULTS_COMPLETE",
    "status": "OK",
    "data": {
      "instances": [
        {
          "name": "EHR-0001",
          "url": "http://ehr-0001.systeminstance.app"
        },
        {
          "name": "EHR-0002",
          "url": "http://ehr-0002.systeminstance.app"
        },
        {
          "name": "EHR-0003",
          "url": "http://ehr-0003.systeminstance.app"
        },
      ],
      "history": [
        {
          "timestamp": "2020-04-16T23:56:12.000Z",
          "patientName": "Brenda Winter",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/29634388/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T23:51:12.000Z",
          "patientName": "Kenny Forrest",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/1089335/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T23:46:12.000Z",
          "patientName": "Tye Atkins",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/7796807/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T23:41:12.000Z",
          "patientName": "Amara Gilmour",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/6857030/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T23:36:12.000Z",
          "patientName": "Ronald Cowan",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/4021600/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T23:31:12.000Z",
          "patientName": "Amir Naylor",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/13691490/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T23:26:12.000Z",
          "patientName": "Russell Pineda",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/24151594/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T23:21:12.000Z",
          "patientName": "Giacomo Nichols",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/26640540/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T23:16:12.000Z",
          "patientName": "Laurence Mccarty",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/19108036/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T23:11:12.000Z",
          "patientName": "Taylah Welch",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/13092574/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T23:06:12.000Z",
          "patientName": "Deniz Reeve",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/24125427/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T23:01:12.000Z",
          "patientName": "Daniaal Maddox",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/14213493/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T22:56:12.000Z",
          "patientName": "Kiran Palmer",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/15832878/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T22:51:12.000Z",
          "patientName": "Santiago Harmon",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/10317703/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T22:46:12.000Z",
          "patientName": "Karan Mahoney",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/29325666/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T22:41:12.000Z",
          "patientName": "Ellisha Lucero",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/17490770/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T22:36:12.000Z",
          "patientName": "Vivien Russell",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/26102444/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T22:31:12.000Z",
          "patientName": "Sinead Dorsey",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/11518151/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T22:26:12.000Z",
          "patientName": "Reo Hail",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/27589157/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T22:21:12.000Z",
          "patientName": "Brenda Betts",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/23601052/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T22:16:12.000Z",
          "patientName": "Mateo Newman",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/19396808/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T22:11:12.000Z",
          "patientName": "Keanan Cardenas",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/2882742/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T22:06:12.000Z",
          "patientName": "Parris Rayner",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/18099057/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T22:01:12.000Z",
          "patientName": "Sameer Tierney",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/2465367/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T21:56:12.000Z",
          "patientName": "Jeanette Hirst",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/18643954/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T21:51:12.000Z",
          "patientName": "Ezekiel Ortega",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/30519409/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T21:46:12.000Z",
          "patientName": "Elodie Paterson",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/28284511/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T21:41:12.000Z",
          "patientName": "Christopher Hanna",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/2338275/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T21:36:12.000Z",
          "patientName": "Agatha Guerra",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/12452380/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T21:31:12.000Z",
          "patientName": "Faizah Vaughan",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/4956194/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T21:26:12.000Z",
          "patientName": "Anya Christensen",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/23264238/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T21:21:12.000Z",
          "patientName": "Adaline Plummer",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/8966619/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T21:16:12.000Z",
          "patientName": "Ronaldo Mcdowell",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/11263624/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T21:11:12.000Z",
          "patientName": "Fannie Beard",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/18076828/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T21:06:12.000Z",
          "patientName": "Clarence Diaz",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/27714810/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T21:01:12.000Z",
          "patientName": "Briony Compton",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/29201544/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T20:56:12.000Z",
          "patientName": "Kiefer Wood",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/25826296/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T20:51:12.000Z",
          "patientName": "Conan Marsh",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/29672248/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T20:46:12.000Z",
          "patientName": "Terrence Stevens",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/14281900/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T20:41:12.000Z",
          "patientName": "Essa Strickland",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/23963907/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T20:36:12.000Z",
          "patientName": "Ailish Fitzgerald",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/1175891/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T20:31:12.000Z",
          "patientName": "Bernice Contreras",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/2523966/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T20:26:12.000Z",
          "patientName": "Maci Beattie",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/28113347/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T20:21:12.000Z",
          "patientName": "Marsha Callaghan",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/2376559/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T20:16:12.000Z",
          "patientName": "Bayley Hart",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/19304763/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T20:11:12.000Z",
          "patientName": "Oliwier Storey",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/27958293/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T20:06:12.000Z",
          "patientName": "Aarav West",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/17283116/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T20:01:12.000Z",
          "patientName": "Lana Dunn",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/2554511/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T19:56:12.000Z",
          "patientName": "Deacon Emerson",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/25904599/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T19:51:12.000Z",
          "patientName": "Adrianna Dawson",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/27502450/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T19:46:12.000Z",
          "patientName": "Junayd Whitney",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/371101/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T19:41:12.000Z",
          "patientName": "Dylan Mac",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/12326025/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T19:36:12.000Z",
          "patientName": "Annie Frank",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/22295432/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T19:31:12.000Z",
          "patientName": "Pawel Mccormack",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/3204565/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T19:26:12.000Z",
          "patientName": "Robson Stokes",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/26450289/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T19:21:12.000Z",
          "patientName": "Niam Tanner",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/30821682/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T19:16:12.000Z",
          "patientName": "Theon Hills",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/8844319/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T19:11:12.000Z",
          "patientName": "Susie Mayer",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/18826219/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T19:06:12.000Z",
          "patientName": "Kason Flowers",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/25501593/DocumentReference"
        },
        {
          "timestamp": "2020-04-16T19:01:12.000Z",
          "patientName": "Johnathan Regan",
          "url": "https://mint-proxy.patientholistics.app:3000/Patient/29043782/DocumentReference"
        }
      ]
    }
  }
}
