import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import colors from "../../../utils/colors";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: "48%",
        position: "absolute",
        bottom: "10px",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 500,
  },
}));

export function AssignDialog(props) {

  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState("");
  const [mintUserId, setMintUserId] = useState("");

  const handleSave = async () => {
    setSaving(true);
    let error;
    if (!mintUserId) {
      error = `User is required.`;
    } else {
      error = await props.handleSave(mintUserId);
    }
    if (error) {
      setSaving(false);
      setSaveError(error);
    }
  };

  const handleChange = (e) => {
    setMintUserId(e.target.value);
  };

  const reset = () => {
    setMintUserId("");
    setSaving(false);
    setSaveError("");
  };

  return (
    <div>
      <Dialog open={props.open} onEnter={reset} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Assign Referral</DialogTitle>
        {
          props.users.length > 0 &&
          <DialogContent>
            <FormControl className={classes.formControl}>
              <InputLabel id="assign-to-mint-user-id">To User:</InputLabel>
              <Select
                labelId="assign-to-mint-user-id"
                value={mintUserId}
                onChange={handleChange}
              >
                {
                  props.users.map(user => (
                    <MenuItem
                      key={user.id}
                      value={user.id}>
                      {props.userLabel(user)}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </DialogContent>
        }
        <DialogActions>
          <Button onClick={props.handleClose} color="primary" disabled={saving}>
            Cancel
          </Button>
          {
            props.users.length > 0 &&
            <Button onClick={handleSave} color="primary" disabled={saving}>
              {saveError ? "Retry" : "Save"}
            </Button>
          }
        </DialogActions>
        {
          saving &&
          <ThemeProvider theme={theme}>
            <div>
              <CircularProgress/>
            </div>
          </ThemeProvider>
        }
        {
          saveError &&
          <MuiAlert severity="error" elevation={6} variant="filled">
            {saveError}
          </MuiAlert>
        }
      </Dialog>
    </div>
  );
}

AssignDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  userLabel: PropTypes.func.isRequired,
};
