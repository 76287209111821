import { get } from "lodash";
import moment from "moment";
import { getFontAwesomeIconFromMimeType } from "../components/PatientViewer/Documents/getFontAwesomeIconFromMimeType";
import { DocumentCatalogsStore } from "../components/PatientViewer/Documents/DocumentCatalogsStore";
import { v4 as uuid } from "uuid";

const ONE_KB = 1024;
const ONE_MB = ONE_KB * ONE_KB;

function creationDate(document) {
  return moment(get(document, "content[0].attachment.creation")).toDate();
}

function getTypeCode(document, catalogs) {
  if (!document) {
    throw new Error("Need document to get type code for.")
  }
  if (!catalogs) {
    throw new Error("Need catalogs to get type code for document.")
  }
  if (!catalogs.typeCodes) {
    throw new Error("Provided catalogs don't contain type codes.")
  }
  const typeCodeItem =
    catalogs.typeCodes.find(
      (item) => item.identifier === get(document.resource || document, "type.text")
    );
  if (typeCodeItem) {
    return typeCodeItem.value
      ? `${typeCodeItem.identifier} - ${typeCodeItem.value}`
      : typeCodeItem.identifier;
  }
  return null;
}

function getSizeStr(document) {
  const sizeInBytes = get(document.resource || document, "content[0].attachment.size");
  let size = sizeInBytes;
  let unit = "bytes";
  if (sizeInBytes >= ONE_MB) {
    size = sizeInBytes / ONE_MB;
    unit = "MB";
  } else if (sizeInBytes >= ONE_KB) {
    size = sizeInBytes / ONE_KB;
    unit = "KB";
  }
  if (unit !== "bytes") {
    size = size.toFixed(2);
  }
  return `${size} ${unit}`;
}

function getIconName(document) {
  return getFontAwesomeIconFromMimeType(
    get(document.resource || document, "content[0].attachment.contentType")
  );
}

export class DocumentUtils {
  static getDisplayInfo(_document, catalogs) {
    const document = _document?.resource || _document;
    return {
      title: get(document, "content[0].attachment.title"),
      type: getTypeCode(document, catalogs),
      iconName: getIconName(document),
      creationDate: moment(creationDate(document)).format("lll"),
      contentType: get(document, "content[0].attachment.contentType"),
      size: getSizeStr(document),
      author: get(document, "author[0].display"),
      description: get(document, "description"),
      category: get(document, "category[0].text"),
      facilityType: get(document, "context.facilityType.text"),
      practiceSetting: get(document, "context.practiceSetting.text"),
      formatCode: get(document, "content[0].format.code"),
      document,
    };
  }

  static getKey(document) {
    const docIds = get(document.resource || document, "identifier", []);
    if (docIds.length === 0) {
      return uuid();
    }
    const idTypes = ["repositoryUniqueId", "documentUniqueId", "hcid"];
    return idTypes
      .map((idType) => {
        const idTypeDocId = docIds.find(
          (id) => get(id, "type.text") === idType
        );
        return get(idTypeDocId, "value", "");
      })
      .join("|");
  }

  static documentCreationDateComparator(d1, d2) {
    return creationDate(d2).getTime() - creationDate(d1).getTime();
  }

  static normalizeDocuments(documents) {
    if (!documents) {
      return documents;
    }
    return documents.map((entry) => entry?.resource || entry);
  }
}
