import React, { useEffect, useState } from "react";
import { renderReferralMetadata } from "../../../services/MintAPI";
import { Paper } from "@material-ui/core";

export default function ReferralMetadata({ referral }) {
  const [renderedMetadata, setRenderedMetadata] = useState("");
  const iframeHeight = { height: referral?.metadata?.type ? "400px" : "3em" };

  useEffect(() => {
    if (referral?.metadata?.type) {
      (async () => {
        setRenderedMetadata(await renderReferralMetadata(referral));
      })();
    }
  }, [referral]);

  return (
    <>
      <h1
        style={{
          marginTop: "20px",
          marginBottom: "10px",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        Metadata
      </h1>
      <Paper elevation={2} style={{ border: "1px solid #A8A8A8" }}>
        <iframe
          srcDoc={
            referral?.metadata?.type
              ? renderedMetadata
              : "<div style='padding-top: 0.6em'>Referral has no renderable metadata.</div>"
          }
          title="Referral Metadata"
          style={{
            overflow: "scroll",
            width: "100%",
            ...iframeHeight,
            border: "none",
          }}
        />
      </Paper>
    </>
  );
}
