import moment from "moment";

export function formatDateHumanMDY(date) {
  if (date) {
    return moment(date).format("MMM DD, YYYY");
  }
  return "";
}

export function formatDateFromNow(date) {
  if (date) {
    return moment(date).fromNow();
  }
  return "";
}

export function formatDateHumanMDYFromNow(date) {
  if (date) {
    return [formatDateHumanMDY(date), " (", formatDateFromNow(date), ")"].join("");
  }
  return "";
}

export function formatDateYMD(date) {
  return moment(date).format("YYYY-MM-DD");
}
