import {
  getPatientData,
  getPatientDataByQuery,
  getPatientDataByReferralId,
} from "../services/MintAPI";
import { get } from "lodash";
import moment from "moment";
import { NotFoundException } from "./exceptions";
import { useFhirReferrals } from "./constants";
import { convertFhirPatientToDisplayPatient } from "./convertFhirPatientToDisplayPatient";

export async function getNormalizedPatientData(patientId, referralId = null) {
  let fhirPatients = null;
  if (useFhirReferrals) {
    fhirPatients = await getPatientDataByQuery({ _id: patientId });
  } else {
    if (!patientId && referralId) {
      fhirPatients = await getPatientDataByReferralId(referralId);
    } else {
      fhirPatients = await getPatientData(patientId);
    }
  }
  if (get(fhirPatients, "length", 0) === 0) {
    throw new NotFoundException(
      `Patient with ID '${patientId}' was not found.`
    );
  }
  const resource = get(fhirPatients[0], "resource", null) || null;
  const firstName = get(resource, "name[0].given[0]", "") || "";
  const lastName = get(resource, "name[0].family", "") || "";
  const gender = get(resource, "gender", "");
  let bdate = get(resource, "birthDate", "");
  bdate = moment(bdate, "YYYY-MM-DD").format("ll");
  bdate = bdate === "Invalid date" ? "" : bdate;

  const displayPatient = convertFhirPatientToDisplayPatient(fhirPatients[0]);

  // console.log('DEBUG displayPatient: ', displayPatient);

  const normalizedPatientData = {
    ...displayPatient,
    name: `${firstName} ${lastName}`,
    firstName,
    givenName: firstName,
    lastName,
    familyName: lastName,
    gender,
    sex: gender,
    deceased: !!resource?.deceased,
    ...(resource?.address?.[0]?.line?.[0] && {
      streetAddress: resource?.address?.[0]?.line?.[0],
    }),
    ...(resource?.address?.[0]?.city && {
      city: resource?.address?.[0]?.city,
    }),
    ...(resource?.address?.[0]?.state && {
      state: resource?.address?.[0]?.state,
    }),
    ...(resource?.address?.[0]?.postalCode && {
      zip: resource?.address?.[0]?.postalCode,
    }),
    id: get(resource, "id", null) || null,
    birthDate: bdate || "N/A",
    birthdate: resource?.birthDate,
    fhirPatient: resource,
  }
  // delete normalizedPatientData['id'];

  // console.log("DEBUG normalizedPatientData: ", normalizedPatientData);
  return normalizedPatientData;
}
