import _ from 'lodash'
import React, { forwardRef, useEffect, useState } from 'react'
import PatientsTable from 'material-table'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Container, Grid, CircularProgress } from '@material-ui/core'
import {
  ArrowDownward,
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
  Visibility
} from '@material-ui/icons'
import colors from '../../utils/colors'
import '../../styles/index.css'
import styles from '../../styles/viewer.module.css';
import { convertFhirPatientToDisplayPatient } from '../../utils';

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: '50%',
        position: 'absolute',
        top: '150px'
      },
      svg: {
        color: colors.baseBlue
      }
    },
    MuiInputBase: {
      root: {
        background: colors.baseColor,
        borderRadius: '5px',
        fontSize: '12px'
      },
      input: {
        color: colors.baseBlue
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '0'
        }
      }
    },
    MuiSvgIcon: {
      colorAction: {
        color: colors.baseBlue,
        cursor: 'pointer'
      }
    },
    MuiTableCell: {
      head: {
        background: `${colors.blueGray} !important`,
        fontWeight: '600',
        textAlign: 'center'
      },
      body: {
        color: `${colors.baseGray} !important`,
        fontSize: '11px !important',
        textAlign: 'center !important'
      }
    },
    MuiTablePagination: {
      input: {
        flexShrink: 'initial'
      },
      toolbar: { background: colors.baseColor}
    },
    MuiTableRow: {
      root: {
        '&:nth-child(odd)': {
          background: colors.gray01
        },
        '&:nth-child(even)': {
          background: colors.gray02
        }
      }
    },
    MuiTableSortLabel: {
      root: {
        color: colors.baseBlue,
        fontSize: '12px',
        textTransform: 'uppercase'
      }
    },
    MuiToolbar: {
      root: {
        minHeight: `0px !important`
      }
    }
  }
})

const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
}

const getPatientData = (fhirPatients) => {
  return _.map(fhirPatients, (fhirPatient, index) => {
    const displayPatient = convertFhirPatientToDisplayPatient(fhirPatient);
    displayPatient['arrayIndex'] = index;
    return displayPatient;
  });
}

const tooltipContent = rowData => {
  return(
    <div className="tiptext">
      <p>
        <label className="tiptext-label">Name: </label>
  	<span>
	  {rowData.lastName}, {rowData.firstName}
	</span>
      </p>
      <p>
        <label className="tiptext-label">Age: </label>
  	<span>
          {rowData.age}
	</span>
      </p>
      <p>
        <label className="tiptext-label">Gender: </label>
  	<span>
	  {rowData.gender}
	</span>
      </p>
      <p>
        <label className="tiptext-label">Phone: </label>
  	<span>
	  {rowData.phone}
	</span>
      </p>
      <p>
        <label className="tiptext-label">Address: </label>
        <span>
	  {rowData.address}
	</span>
      </p>
      <p>
        <label className="tiptext-label">Location: </label>
  	<span>
	  {rowData.location}
	</span>
      </p>
      <p>
        <label className="tiptext-label">Deceased: </label>
  	<span>
	  {rowData.deceased}
	</span>
      </p>
      <p>
        <label className="tiptext-label">Last Update: </label>
  	<span>
	  {rowData.lastUpdate}
	</span>
      </p>
    </div>
  )
}

function calculateTableHeight() {
  return window.innerHeight - 280;
}

const PatientTable = ({
  initialState,
  data,
  onPatientClick,
  columns = [
    {
      title: '',
      field: '',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: rowData => {
        return(
	  <div className="tooltip right">
            <Visibility color="action" />
            {tooltipContent(rowData)}
          </div>
        )
      },
      width: 50
    },
    {
      title: 'MRN',
      field: 'id',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      width: 100
    },
    {
      title: 'First Name',
      field: 'firstName',
      cellStyle: { textAlign: 'center', textTransform: 'capitalize' },
      headerStyle: { textAlign: 'center' }
    },
    {
      title: 'Last Name',
      field: 'lastName',
      cellStyle: { textAlign: 'center', textTransform: 'capitalize' },
      headerStyle: { textAlign: 'center' }
    },
    {
      title: 'Gender',
      field: 'gender',
      cellStyle: { textAlign: 'center', textTransform: 'capitalize' },
      headerStyle: { textAlign: 'center' }
    },
    {
      title: 'Birth Date',
      field: 'birthDate',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' }
    },
    {
      title: 'Age',
      field: 'age',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' }
    },
    {
      title: 'Location',
      field: 'location',
      cellStyle: { textAlign: 'center', textTransform: 'capitalize' },
      headerStyle: { textAlign: 'center' }
    },
  ]
}) => {

  const [tableHeight, setTableHeight] = useState(calculateTableHeight());

  useEffect(
    () => {
      window.onresize = () => setTableHeight(calculateTableHeight());
      return () => {
        window.onresize = undefined;
      }
    }
  )

  if(initialState) {
    if(data.error) {
      return(
        <ThemeProvider theme={theme}>
          <Container className={styles.errorContainer}>
            {data.error}
          </Container>
        </ThemeProvider>
      )
    } else {
      return(
        <ThemeProvider theme={theme}>
          <Grid container>
            <Grid item xs={12}>
              <PatientsTable
                title=""
                columns={columns}
                data={getPatientData(data)}
                icons={tableIcons}
                onRowClick={(e, rowData) => onPatientClick({ patientData: rowData, patientTableOriginalData: data })}
                options={{
                  emptyRowsWhenPaging: false,
                  minBodyHeight: '100%',
                  maxBodyHeight: tableHeight,
                  padding: 'dense',
                  pageSize: 10,
                  search: false,
                }}
              />
            </Grid>
          </Grid>
        </ThemeProvider>
      )
    }
  } else {
    return(
      <ThemeProvider theme={theme}>
        <div>
          <CircularProgress />
        </div>
      </ThemeProvider>
    )
  }
}

export default PatientTable
