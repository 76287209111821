import { get } from "lodash";

function getOrganizationName(auth) {
  return get(auth, "loggedInUser.userinfo.orgName");
}

// export function isReferringOrganization(auth, referral) {
//   console.log("DEBUG referral in isReferringOrganization: ", referral)
//   return getOrganizationName(auth) === referral.referringOrganization;
// }

// export function isReceivingOrganization(auth, referral) {
//   return getOrganizationName(auth) === referral.receivingOrganization
// }

export function getReferralPatientId(referral) {
  // if (isReferringOrganization(auth, referral)) {
  //   return referral.referringPatientId;
  // }
  // if (isReceivingOrganization(auth, referral)) {
  //   return referral.receivingPatientId;
  // }
  return null;
}

export default class FhirReferralStates {
  static DRAFT = "draft";
  static SUBMITTED = "submitted";
  static ACTIVE = "active";
  static ON_HOLD = "on-hold";
  static COMPLETED = "completed";
  static REVOKED = "revoked";
}
