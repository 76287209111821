import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TextField } from "@material-ui/core";
import colors from "../../../../utils/colors";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: "48%",
        position: "absolute",
        bottom: "10px",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  alternatePhone: yup
    .string()
    .matches(phoneRegExp, "Please enter a valid Phone Number")
  ,
  healthNetwork: yup.string(),
  alternateAddressStreetLine1: yup.string(),
  alternateAddressStreetLine2: yup.string(),
  alternateAddressCity: yup.string(),
  alternateAddressState: yup.string(),
  alternateAddressZip: yup.string(),
  pcpId: yup.string(),
  pcpName: yup.string(),
  pcpPhone: yup
    .string()
    .matches(phoneRegExp, "Please enter a valid Phone Number")
  ,
  pcpAddressStreetLine1: yup.string(),
  pcpAddressStreetLine2: yup.string(),
  pcpAddressCity: yup.string(),
  pcpAddressState: yup.string(),
  pcpAddressZip: yup.string(),
});

const initialValues = {
  alternatePhone: "",
  healthNetwork: "",
  alternateAddressStreetLine1: "",
  alternateAddressStreetLine2: "",
  alternateAddressCity: "",
  alternateAddressState: "",
  alternateAddressZip: "",
  pcpId: "",
  pcpName: "",
  pcpPhone: "",
  pcpAddressStreetLine1: "",
  pcpAddressStreetLine2: "",
  pcpAddressCity: "",
  pcpAddressState: "",
  pcpAddressZip: "",
};

export function ReferralAddendumDialog(props) {

  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState("");
  const saveRequested = useRef(false);
  const form = useRef();

  const handleSubmit = () => {
    form.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true }),
    );
  };

  const handleSave = async (addendum) => {
    console.log({ values: addendum });
    setSaveError("");
    const error = await props.handleSave(addendum);
    if (error) {
      setSaving(false);
      setSaveError(error);
    }
  };

  const reset = () => {
    saveRequested.current = false;
    setSaving(false);
    setSaveError("");
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (addendum) => handleSave(addendum),
  });

  return (
    <div>
      <Dialog
        open={props.open} onClose={props.handleClose} onEnter={reset} aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create Addendum</DialogTitle>
        <DialogContent>
          <div>
            <form ref={form} onSubmit={formik.handleSubmit} method="dialog">
              <TextField
                fullWidth
                name="alternatePhone"
                label="Alternate Phone Number"
                value={formik.values.alternatePhone}
                onChange={formik.handleChange}
                error={formik.touched.alternatePhone && Boolean(formik.errors.alternatePhone)}
                helperText={formik.touched.alternatePhone && formik.errors.alternatePhone}
              />
              <TextField
                fullWidth
                name="healthNetwork"
                label="Health Network"
                value={formik.values.healthNetwork}
                onChange={formik.handleChange}
                error={formik.touched.healthNetwork && Boolean(formik.errors.healthNetwork)}
                helperText={formik.touched.healthNetwork && formik.errors.healthNetwork}
              />
              <TextField
                fullWidth
                name="alternateAddressStreetLine1"
                label="Street Address"
                value={formik.values.alternateAddressStreetLine1}
                onChange={formik.handleChange}
                error={formik.touched.alternateAddressStreetLine1 && Boolean(formik.errors.alternateAddressStreetLine1)}
                helperText={formik.touched.alternateAddressStreetLine1 && formik.errors.alternateAddressStreetLine1}
              />
              <TextField
                fullWidth
                name="alternateAddressStreetLine2"
                label="Street Address Line 2"
                value={formik.values.alternateAddressStreetLine2}
                onChange={formik.handleChange}
                error={formik.touched.alternateAddressStreetLine2 && Boolean(formik.errors.alternateAddressStreetLine2)}
                helperText={formik.touched.alternateAddressStreetLine2 && formik.errors.alternateAddressStreetLine2}
              />
              <TextField
                fullWidth
                name="alternateAddressCity"
                label="City"
                value={formik.values.alternateAddressCity}
                onChange={formik.handleChange}
                error={formik.touched.alternateAddressCity && Boolean(formik.errors.alternateAddressCity)}
                helperText={formik.touched.alternateAddressCity && formik.errors.alternateAddressCity}
              />
              <TextField
                fullWidth
                name="alternateAddressState"
                label="State"
                inputProps={{ maxLength: 2 }}
                value={formik.values.alternateAddressState}
                onChange={formik.handleChange}
                error={formik.touched.alternateAddressState && Boolean(formik.errors.alternateAddressState)}
                helperText={formik.touched.alternateAddressState && formik.errors.alternateAddressState}
              />
              <TextField
                fullWidth
                name="alternateAddressZip"
                label="ZIP Code"
                value={formik.values.alternateAddressZip}
                onChange={formik.handleChange}
                error={formik.touched.alternateAddressZip && Boolean(formik.errors.alternateAddressZip)}
                helperText={formik.touched.alternateAddressZip && formik.errors.alternateAddressZip}
              />
              <TextField
                fullWidth
                name="pcpId"
                label="Primary Care Physician ID"
                value={formik.values.pcpId}
                onChange={formik.handleChange}
                error={formik.touched.pcpId && Boolean(formik.errors.pcpId)}
                helperText={formik.touched.pcpId && formik.errors.pcpId}
              />
              <TextField
                fullWidth
                name="pcpName"
                label="Physician Name"
                value={formik.values.pcpName}
                onChange={formik.handleChange}
                error={formik.touched.pcpName && Boolean(formik.errors.pcpName)}
                helperText={formik.touched.pcpName && formik.errors.pcpName}
              />
              <TextField
                fullWidth
                name="pcpPhone"
                label="Physician Phone Number"
                value={formik.values.pcpPhone}
                onChange={formik.handleChange}
                error={formik.touched.pcpPhone && Boolean(formik.errors.pcpPhone)}
                helperText={formik.touched.pcpPhone && formik.errors.pcpPhone}
              />
              <TextField
                fullWidth
                name="pcpAddressStreetLine1"
                label="Physician Street Address"
                value={formik.values.pcpAddressStreetLine1}
                onChange={formik.handleChange}
                error={formik.touched.pcpAddressStreetLine1 && Boolean(formik.errors.pcpAddressStreetLine1)}
                helperText={formik.touched.pcpAddressStreetLine1 && formik.errors.pcpAddressStreetLine1}
              />
              <TextField
                fullWidth
                name="pcpAddressStreetLine2"
                label="Street Address Line 2"
                value={formik.values.pcpAddressStreetLine2}
                onChange={formik.handleChange}
                error={formik.touched.pcpAddressStreetLine2 && Boolean(formik.errors.pcpAddressStreetLine2)}
                helperText={formik.touched.pcpAddressStreetLine2 && formik.errors.pcpAddressStreetLine2}
              />
              <TextField
                fullWidth
                name="pcpAddressCity"
                label="City"
                value={formik.values.pcpAddressCity}
                onChange={formik.handleChange}
                error={formik.touched.pcpAddressCity && Boolean(formik.errors.pcpAddressCity)}
                helperText={formik.touched.pcpAddressCity && formik.errors.pcpAddressCity}
              />
              <TextField
                fullWidth
                name="pcpAddressState"
                label="State"
                inputProps={{ maxLength: 2 }}
                value={formik.values.pcpAddressState}
                onChange={formik.handleChange}
                error={formik.touched.pcpAddressState && Boolean(formik.errors.pcpAddressState)}
                helperText={formik.touched.pcpAddressState && formik.errors.pcpAddressState}
              />
              <TextField
                fullWidth
                name="pcpAddressZip"
                label="ZIP Code"
                value={formik.values.pcpAddressZip}
                onChange={formik.handleChange}
                error={formik.touched.pcpAddressZip && Boolean(formik.errors.pcpAddressZip)}
                helperText={formik.touched.pcpAddressZip && formik.errors.pcpAddressZip}
              />
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary" disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={saving}>
            {saveError ? "Retry" : "Save"}
          </Button>
        </DialogActions>
        {
          saving &&
          <ThemeProvider theme={theme}>
            <div>
              <CircularProgress/>
            </div>
          </ThemeProvider>
        }
        {
          saveError &&
          <MuiAlert severity="error" elevation={6} variant="filled">
            {saveError}
          </MuiAlert>
        }
      </Dialog>
    </div>
  );
}

ReferralAddendumDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};