import React from 'react'
import FileActionsBar from '../FileActionsBar/FileActionsBar'
import styles from '../../../../styles/viewer.module.css'

const NotAvailableViewer = ({
  fileContentType,
  fileData,
  fileType
}) => {
  return(
    <div className={styles.viewer}>
      {fileContentType &&
      <FileActionsBar
        fileContentType={fileContentType}
        fileData={fileData}
        fileType={fileType}
      />
      }
      <div className={styles.notAvailableLabel} >Preview not available</div>
    </div>
  )
}

export default NotAvailableViewer
