import React from 'react'
import styles from '../../../../styles/viewer.module.css'

const XsltButton = ({
  handleView,
  typeView
}) => {
  const label = typeView ? 'HTML' : 'XSLT'
  return(
    <div className={styles.xsltViewButton}>
      {/*eslint-disable-next-line*/}
      <a className={styles.xsltLabel} onClick={handleView}>{label}</a>
    </div>
  )
}

export default XsltButton
