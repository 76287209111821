import _ from 'lodash';
//import { isEmpty } from 'lodash';
//import styles from '../../xmlraw.module.css'
const uuid = require('uuid').v4;

const PARSER = new DOMParser();
const PRINTER = new XMLSerializer();

export function highlightXPath(xmlString: string, xpath: string, containerId: string): number
{
  const container = document.getElementById(containerId);
  if (_.isEmpty(xpath)) {
    return notFound(container, xmlString);
  }
  const xml = PARSER.parseFromString(xmlString, 'application/xml');
  const nodes = xml.evaluate(
    xpath, xml, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null
  );
  if (nodes.snapshotLength === 0) {
    return notFound(container, xmlString);
  }
  const id = uuid();
  for (let i = 0; i < nodes.snapshotLength; i++) {
    let node = nodes.snapshotItem(i);
    if (node && node.parentNode) {
      node.parentNode.insertBefore(xml.createComment('start_' + (
        i + 1
      ) + '_' + id), node);
      node.parentNode.insertBefore(xml.createComment('end_' + (
        i + 1
      ) + '_' + id), node.nextSibling);
    }
  }
  const str = PRINTER.serializeToString(xml);
  if (container) {
    container.innerHTML =
      normalizeXmlString(str)
        .replace(
          /&lt;!--start_(\d+)_.*?-->/g,
          '<span id="highlight$1" class="highlight">'
        )
        .replace(/&lt;!--end_.*?-->/g, '</span>')
    ;
  }
  return nodes.snapshotLength;
}

function normalizeXmlString(xmlString: string): string
{
  return xmlString
    .replace(/</g, '&lt;')
    .replace(/\?>/g, '?&gt;<br>');
}

function notFound(container: HTMLElement | null, xmlString: string)
{
  if (container) {
    container.innerHTML = normalizeXmlString(xmlString);
  }
  return 0;
}
