import React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import colors from '../../../../utils/colors'
import styles from '../../../../styles/viewer.module.css'
import {
  GetApp,
  Print,
  RotateLeft,
  RotateRight
} from '@material-ui/icons'

const theme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      root: {
        height: '35px'
      }
    },
    MuiSvgIcon: {
      root: {
        color: colors.gray01,
        cursor: 'pointer',
        height: '25px',
        margin: '0 5px',
        width: '25px'
      }
    },
    MuiToolbar: {
      root: {
        background: colors.baseBlue,
      },
      dense: {
        minHeight: '38px',
      }
    },
    MuiIconButton: {
      label: {
        '&>svg': {
          height: '20px',
          width: '20px',
        },
      },
      root: {
        color: colors.gray01,
      },
    }
  },
})

/*
const DownloadButton = ({
  fileType,
  filteData
}) => {}
*/

const FileActionsBar = ({
  fileContentType,
  fileData,
  fileType,
  handleBack,
  handleLeft,
  handlePrint,
  handleRight,
}) => {
  return(
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Toolbar variant="dense">
          {handleLeft &&
            <div className={styles.rotateLeftIcon}>
              <RotateLeft onClick={handleLeft} />
            </div>
          }
          {handleRight &&
            <div className={styles.rotateRightIcons}>
              <RotateRight onClick={handleRight} />
            </div>
          }
          {fileData && fileType === 'file-pdf' &&
            <a href={`data:application/pdf;base64,${fileData}`} download="file.pdf" className={styles.downloadIcon}>
              <GetApp />
            </a>
          }
          {fileData && fileType === 'file-image' &&
            <a href={`data:image/jpeg;base64,${fileData}`} download="file.jpeg" className={styles.downloadIcon}>
              <GetApp />
            </a>
          }
          {fileData && fileType === 'file-code' &&
            <a href={`data:application/xml;base64,${fileData}`} download="file.xml" className={styles.downloadIcon}>
              <GetApp />
            </a>
          }
          {fileData && fileType === 'file-alt' && fileContentType &&
            <a href={`data:${fileContentType};base64,${fileData}`} download="file" className={styles.downloadIcon}>
              <GetApp />
            </a>
          }
          {handlePrint &&
            <div className={styles.printIcon}>
              <Print onClick={handlePrint} />
            </div>
          }
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}

export default FileActionsBar
