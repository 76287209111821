import { get } from 'lodash';
import auth from "../utils/auth";

function getOrganizationName(auth) {
  return get(auth, 'loggedInUser.userinfo.orgName');
}

export function isReferringOrganization(auth, referral) {
  return getOrganizationName(auth) === referral.referringOrganization;
}

export function isReceivingOrganization(auth, referral) {
  return getOrganizationName(auth) === referral.receivingOrganization
}

export function getPatientId(auth, referral) {
  return isReferringOrganization(auth, referral)
    ? referral.referringPatientId
    : referral.receivingPatientId || referral.referringPatientId
}

function isReferringUser(auth, referral) {
  return (
    isReferringOrganization(auth, referral)
    && auth.mintUserId === referral.referringMintUserId
  );
}

export function getReferralPatientId (referral) {
  if (isReferringOrganization(auth, referral)) {
    return referral.referringPatientId;
  }
  if (isReceivingOrganization(auth, referral)) {
    return referral.receivingPatientId;
  }
  return null;
}

function isPatientProvisioned(referral) {
  return referral.receivingPatientId;
}

function isOpen (referral) {
  return (
    [
      ReferralStates.DRAFT,
      ReferralStates.REQUESTED,
      ReferralStates.RECEIVED,
      ReferralStates.ACCEPTED,
      ReferralStates.IN_PROGRESS,
      ReferralStates.ON_HOLD,
    ]
      .includes(referral.status)
  );
}

export default class ReferralStates {

  static DRAFT = 'draft';
  static REQUESTED = 'requested';
  static RECEIVED = 'received';
  static ACCEPTED = 'accepted';
  static IN_PROGRESS = 'in-progress';
  static ON_HOLD = 'on-hold';
  static COMPLETED = 'completed';
  static CANCELLED = 'cancelled';
  static REJECTED = 'rejected';

  static canWithdraw(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReferringUser(auth, referral)
      && isOpen(referral)
    );
  }

  static canSubmit(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReferringOrganization(auth, referral)
      && referral.status === ReferralStates.DRAFT
    )
  }

  static canUpdate(auth, referral, patientInfo) {
    return (
      patientInfo
      && isOpen(referral)
    );
  }

  static canAddNote(auth, referral, patientInfo) {
    return (
      patientInfo
      && isOpen(referral)
    );
  }

  static canAccept(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReceivingOrganization(auth, referral)
      && isPatientProvisioned(referral)
      && referral.status === ReferralStates.RECEIVED
    )
  }

  static canAcknowledge(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReceivingOrganization(auth, referral)
      && isPatientProvisioned(referral)
      && referral.status === ReferralStates.REQUESTED
    )
  }

  static canStart(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReceivingOrganization(auth, referral)
      && isPatientProvisioned(referral)
      && referral.status === ReferralStates.ACCEPTED
    )
  }

  static canCancel(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReceivingOrganization(auth, referral)
      && isPatientProvisioned(referral)
      && [
        ReferralStates.ACCEPTED,
        ReferralStates.IN_PROGRESS,
        ReferralStates.ON_HOLD,
      ].includes(referral.status)
    )
  }

  static canNotifyInfoProvided(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReferringOrganization(auth, referral)
      && referral.status === ReferralStates.ON_HOLD
    )
  }

  static canReject(auth, referral) {
    return (
      isReceivingOrganization(auth, referral)
      && ReferralStates.RECEIVED === referral.status
    )
  }

  static canReopen(auth, referral) {
    return referral.status === ReferralStates.COMPLETED;
  }

  static canComplete(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReceivingOrganization(auth, referral)
      && referral.status === ReferralStates.IN_PROGRESS
    )
  }

  static canRequestMissingInfo(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReceivingOrganization(auth, referral)
      && referral.status === ReferralStates.RECEIVED
    )
  }

  static canAssign(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReceivingOrganization(auth, referral)
      && [ReferralStates.RECEIVED].includes(referral.status)
    )
  }

  static canCreateChild(auth, referral, patientInfo) {
    return (
      patientInfo
      && isReceivingOrganization(auth, referral)
      && [ReferralStates.ACCEPTED, ReferralStates.ON_HOLD].includes(referral.status)
    )
  }

  static canProvision(auth, referral) {
    return (
      isReceivingOrganization(auth, referral)
      && isOpen(referral)
      && !isPatientProvisioned(referral)
    )
  }

  static canAddDocument(auth, referral) {
    return isOpen(referral);
  }
}
