import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  useField,
  useForm,
  useOptionalField,
} from "../../../../../utils/form-utils";
import { FormList, FormText } from "../../controls";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import colors from "../../../../../utils/colors";
import {
  intentProperty,
  priorityProperty,
  statusProperty,
  taskCodeProperty,
} from "../../utils/fhir-utils/domain/Activity";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: "48%",
        position: "absolute",
        bottom: "10px",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
    width: 620,
  },
  formControl: {
    marginTop: theme.spacing(0),
    margin: theme.spacing(1),
    width: 300,
  },
  formTextArea: {
    marginTop: theme.spacing(0),
    margin: theme.spacing(1),
  },
}));

const EMPTY_VALUES = {
  organization: "",
  patientName: "",
  lastModifiedDate: null,
  authoredOn: null,
  requester: "",
  taskType: "",
  status: "",
  priority: "",
  intent: "",
  owner: "",
  startDate: null,
  endData: null,
  description: "",
  comments: "",
  saving: false,
  saveError: "",
};

export function TaskEditDialog({ activity, open, onClose, onSubmit }) {
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState("");
  const form = useForm();

  const organization = useOptionalField(
    form,
    "Organization",
    activity?.getGoal().getOrganizationName() || EMPTY_VALUES.organization
  );

  const patientName = useOptionalField(
    form,
    "Patient Name",
    activity?.getGoal()?.getPatientName() || EMPTY_VALUES.patientName
  );

  const lastModifiedDate = useOptionalField(
    form,
    "Last Modified Date",
    activity?.getLastModifiedDate() || EMPTY_VALUES.lastModifiedDate
  );

  const authoredOn = useOptionalField(
    form,
    "Authored On",
    activity?.getAuthoredOnHumanMDY() || EMPTY_VALUES.authoredOn
  );

  const taskType = useField(
    form,
    "Task Type",
    activity?.getTaskType() || EMPTY_VALUES.taskType
  );

  const status = useField(
    form,
    "Status",
    activity?.getStatusId() || EMPTY_VALUES.status
  );

  const priority = useField(
    form,
    "Priority",
    activity?.getPriorityId() || EMPTY_VALUES.priority
  );

  const intent = useField(
    form,
    "Intent",
    activity?.getIntentId() || EMPTY_VALUES.intent
  );

  const requester = useField(
    form,
    "Requester",
    activity?.getRequesterReference() || EMPTY_VALUES.requester
  );

  const owner = useField(
    form,
    "Task Owner",
    activity?.getOwnerReference() || EMPTY_VALUES.owner
  );

  const startDate = useField(
    form,
    "Task Start Date",
    activity?.getStartDate() || EMPTY_VALUES.startDate
  );

  const endDate = useField(
    form,
    "Task End Date",
    activity?.getEndDate() || EMPTY_VALUES.endData
  );

  const description = useField(
    form,
    "Description",
    activity?.getDescription() || EMPTY_VALUES.description
  );

  const comments = useOptionalField(
    form,
    "Comments",
    activity?.getComments() || EMPTY_VALUES.comments
  );

  const reset = () => {
    form.reset();
    setSaving(EMPTY_VALUES.saving);
    setSaveError(EMPTY_VALUES.saveError);
  };

  const submit = async () => {
    try {
      setSaving(true);
      activity.addNewTaskIfRequired();
      activity.setAuthoredOn();
      activity.setRequester(requester.value);
      activity.setOwner(owner.value);
      await activity.setTaskType(taskType.value);
      activity.setStatus(status.value);
      activity.setPriority(priority.value);
      activity.setIntent(intent.value);
      activity.setStartDate(startDate.value);
      activity.setEndDate(endDate.value);
      activity.setDescription(description.value);
      activity.setComments(comments.value);
      await onSubmit(activity);
      reset();
      onClose();
    } catch (error) {
      setSaving(EMPTY_VALUES.saving);
      setSaveError(error.message);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onExited={reset}
      onEnter={reset}
      maxWidth="md"
    >
      <DialogTitle>{activity?.isNew() ? "Add a" : "Edit"} Task</DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormText field={organization} classes={classes} readOnly />
            </Grid>
            <Grid item xs={6}>
              <FormText field={patientName} classes={classes} readOnly />
            </Grid>
            <Grid item xs={6}>
              <FormText field={lastModifiedDate} classes={classes} readOnly />
            </Grid>
            <Grid item xs={6}>
              <FormText field={authoredOn} classes={classes} readOnly />
            </Grid>
            <Grid item xs={6}>
              <FormList
                field={requester}
                getValues={() =>
                  activity?.getCarePlan()?.getAllCareTeamMemberOptions()
                }
                classes={classes}
              />
            </Grid>
            <Grid item xs={6}>
              <FormList
                field={taskType}
                getValues={taskCodeProperty.getValues}
                classes={classes}
              />
            </Grid>
            <Grid item xs={6}>
              <FormList
                field={status}
                getValues={statusProperty.getValues}
                classes={classes}
              />
            </Grid>
            <Grid item xs={6}>
              <FormList
                field={priority}
                getValues={priorityProperty.getValues}
                classes={classes}
              />
            </Grid>
            <Grid item xs={6}>
              <FormList
                field={intent}
                getValues={intentProperty.getValues}
                classes={classes}
              />
            </Grid>
            <Grid item xs={6}>
              <FormList
                field={owner}
                getValues={() =>
                  activity?.getCarePlan()?.getAllCareTeamMemberOptions()
                }
                classes={classes}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText field={startDate} type="date" classes={classes} />
            </Grid>
            <Grid item xs={6}>
              <FormText field={endDate} type="date" classes={classes} />
            </Grid>
            <Grid item xs={12}>
              <FormText
                field={description}
                multiline
                fullWidth
                variant="outlined"
                classes={classes}
              />
            </Grid>
            <Grid item xs={12}>
              <FormText
                field={comments}
                multiline
                fullWidth
                variant="outlined"
                classes={classes}
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
          disabled={saving}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={submit}
          color="primary"
          disabled={form.isInvalid || saving}
        >
          {saveError ? "Retry" : "Save"}
        </Button>
      </DialogActions>
      {saving && (
        <ThemeProvider theme={theme}>
          <div>
            <CircularProgress />
          </div>
        </ThemeProvider>
      )}
      {saveError && (
        <MuiAlert severity="error" elevation={6} variant="filled">
          {saveError}
        </MuiAlert>
      )}
    </Dialog>
  );
}
