import { usersUrl } from '../utils/constants';
import { ErrorUtils } from '../utils/exceptions';
import { AxiosHelper } from '../utils/AxiosHelper';

const axiosInstance = ErrorUtils.configureAxiosForMintErrors(AxiosHelper.newInstance());

export default class UsersAPI {

  static async getUsers(status) {
    const result = await axiosInstance.get(
      usersUrl,
      {
        params: {
          status,
        }
      }
    );
    return ErrorUtils.toEmptyArray(result);
  }

  static async getUser(userId) {
    const result = await axiosInstance.get(
      `${usersUrl}/${userId}/' : ''}`,
    );
    return result?.data;
  }
}
