import React, { useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import colors from "../../../utils/colors";
import TransferList from "./TransferList";
import { ReferralUtils } from "../../../services";
import UploadDocumentDialog from "../Documents/UploadDocumentDialog";
import FhirReferralCard from "./FhirReferralCard";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: "48%",
        position: "absolute",
        bottom: "10px",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

function FhirReferralDocumentSelectionDialog({
  open,
  handleSave,
  handleClose,
  patient,
  referral,
  referralDocuments,
  patientDocuments,
}) {
  const [saving, setSaving] = useState(false);
  const [linkEnabled, setLinkEnabled] = useState(false);
  const [isUploadDialogVisible, setIsUploadDialogVisible] = useState(false);
  const [saveError, setSaveError] = useState("");
  const [newDocuments, setNewDocuments] = useState(null);
  const documentsToLink = useRef([]);

  const handleLinkSave = async () => {
    setSaveError("");
    setSaving(true);
    let error = await handleSave(documentsToLink.current);
    if (error) {
      setSaving(false);
      setSaveError(error);
    }
  };

  const reset = () => {
    setSaving(false);
    setLinkEnabled(false);
    setSaveError("");
    setNewDocuments(null);
    documentsToLink.current = [];
  };

  const onLinkableContentsChanged = (enabled, notYetLinked) => {
    setLinkEnabled(enabled);
    documentsToLink.current = notYetLinked;
  };

  const handleDocumentUploaded = async () => {
    // const { documents } = await ReferralUtils.getReferralWithDocuments(
    //   patient,
    //   null,
    //   null,
    //   referral
    // );
    // setNewDocuments(documents);
    hideUploadDialog();
  };

  const showUploadDialog = () => {
    setIsUploadDialogVisible(true);
  };

  const hideUploadDialog = () => {
    setIsUploadDialogVisible(false);
  };

  const onUploadDocumentRequested = () => {
    showUploadDialog();
  };

  return (
    <ThemeProvider theme={theme}>
      {isUploadDialogVisible ? (
        <UploadDocumentDialog
          open={isUploadDialogVisible}
          patientID={patient?.id}
          handleSave={handleDocumentUploaded}
          handleClose={hideUploadDialog}
        />
      ) : null}
      <Dialog
        open={open}
        onEnter={reset}
        onClose={handleClose}
        aria-labelledby="document-selection-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="document-selection-dialog-title">
          Link Documents to Referral
        </DialogTitle>
        <DialogContent>
          <FhirReferralCard referral={referral} patient={patient} />
          <TransferList
            documents={newDocuments || patientDocuments}
            linked={referralDocuments}
            referral={referral}
            onLinkableContentsChanged={onLinkableContentsChanged}
            onUploadDocumentRequested={onUploadDocumentRequested}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={saving}>
            Cancel
          </Button>
          <Button
            onClick={handleLinkSave}
            color="primary"
            disabled={saving || !linkEnabled}
          >
            Link
          </Button>
        </DialogActions>
        {saving && (
          <div>
            <CircularProgress />
          </div>
        )}
        {saveError && (
          <MuiAlert severity="error" elevation={6} variant="filled">
            {saveError}
          </MuiAlert>
        )}
      </Dialog>
    </ThemeProvider>
  );
}

FhirReferralDocumentSelectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  patient: PropTypes.any.isRequired,
  referral: ReferralUtils.REFERRAL_DETAIL_SHAPE.isRequired,
  referralDocuments: PropTypes.arrayOf(PropTypes.object),
  patientDocuments: PropTypes.arrayOf(PropTypes.object),
};

export default FhirReferralDocumentSelectionDialog;
