import React from "react";
import moment from "moment";
import { urgentStyle } from "./Referrals/urgentStyle";
import { ReferralStates } from "../../services";
import { sortBy } from 'lodash';

export const commentRenderer = (str) => str || "-----";
export const upperCaseRenderer = (str) =>
  typeof str === "string" ? str.toUpperCase() : str;
export const statusRenderer = (status) => {
  const _status = upperCaseRenderer(status);
  if (_status === ReferralStates.CANCELLED) {
    return <span style={urgentStyle(true)}>{_status}</span>;
  }
  return _status;
};

const getOnOrSince = (status) => {
  if (ReferralStates.DRAFT === status) {
    return "";
  }
  return (
    [
      ReferralStates.REQUESTED,
      ReferralStates.COMPLETED,
      ReferralStates.CANCELLED,
    ]
      .includes(status)
  )
    ? "on"
    : "since";
};

export const statusWithDateRenderer = (status, referral) => {
  return <>
    <span style={{ ...urgentStyle(status === ReferralStates.CANCELLED), fontWeight: "bold" }}>
      {upperCaseRenderer(status)}
    </span>
    {
      referral.statusChangedTs &&
      <span style={{ paddingLeft: "0.6em" }}>
        {`${getOnOrSince(status)} ${dateTimeRenderer(referral.statusChangedTs)}.`.trim()}
      </span>
    }
  </>;
};

export const dateTimeRenderer = (timestampStr) => {
  if (!timestampStr) {
    return commentRenderer(null);
  }
  return moment(timestampStr).format("LLL");
}

export const yesNoRenderer = (booleanValue) => (booleanValue ? "YES" : "NO");

export const facilityRenderer = (facility) => {
  if (!facility) {
    return commentRenderer(null);
  }
  const name = { marginBottom: '0.2em', fontStyle: 'italic' };
  const address = { fontSize: 'smaller' };
  return <>
    <div style={name}>{facility.receivingFacilityName}</div>
    <div style={address}>{facility.streetAddress}</div>
    <div style={address}>{facility.city}</div>
    <div style={address}>{facility.zip}</div>
    <div style={address}>{facility.state}</div>
  </>;
}

export const userNameRenderer = (user) => {
  if (!user) {
    return commentRenderer(null);
  }
  return user.name + (user.role ? ` (${user.role})` : '');
}

export function ReferralIdListRenderer({ referralIds, onReferralSelect }) {
  if (!referralIds || referralIds.length === 0) {
    return commentRenderer(null);
  }
  const _referralIds = [...referralIds].filter(Boolean);
  return _referralIds.length === 0 ? commentRenderer(null) : (
    <div>
      {
        sortBy(_referralIds, Number).map((referralId, index, array) => <>
          <span
            onClick={() => onReferralSelect(referralId)}
            style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
            {referralId}
          </span>
          {index < array.length - 1 ? ', ' : ''}
        </>)
      }
    </div>
  );
}