import React from "react";
import { Button, Grid } from "@material-ui/core";
import auth from "../../../utils/auth";
import styles from "../../../styles/viewer.module.css";

export const FhirReferralActions = ({ children }) => {
  return (
    <Grid container spacing={2} className={styles.fhirLeftButtonContainer}>
      {children}
    </Grid>
  );
};

export const FhirReferralActionButton = ({
  text,
  action,
  canShow,
  referral,
  patientInfo,
  color,
}) => {
  if (!referral || (referral && canShow(auth, referral, patientInfo))) {
    return (
      <Grid item>
        <Button variant="contained" color={color || "primary"} onClick={action}>
          {text}
        </Button>
      </Grid>
    );
  }
  return null;
};
