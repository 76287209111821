import React, { useEffect, useState } from "react";
import FhirReferralsAPI from "../../../services/FhirReferralsAPI";

const API = FhirReferralsAPI;

function useOrganizations({ organizationId, individual = false }) {
  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [error, setError] = useState([]);

  useEffect(() => {
    if (individual && organizationId) {
      manageGetOrganization();
    } else {
      manageGetOrganizations();
    }
  }, [organizationId, individual]);

  const manageGetOrganizations = async () => {
    try {
      const responseOrganizations = await API.getOrganizations();
      if (responseOrganizations?.organizations) {
        setOrganizations(responseOrganizations.organizations);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const manageGetOrganization = async () => {
    // try {
    //   const responseReferral = await API.getReferral(referralId, true);
    //   console.log("DEBUG responseReferral: ", responseReferral);
    //   if (responseReferral?.patient?.resource) {
    //     setPatient(responseReferral.patient.resource);
    //   }
    //   if (responseReferral?.referral?.resource) {
    //     setReferral(responseReferral.referral.resource);
    //   }
    //   if (responseReferral?.organizations) {
    //     setOrganizations(responseReferral.organizations);
    //   }
    // } catch (error) {
    //   setError(error.message);
    // }
  };

  return { organization, organizations, error };
}

export default useOrganizations;
