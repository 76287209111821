import React from "react";
import styles from '../../../../styles/xmlraw.module.css';
import viewerStyles from '../../../../styles/viewer.module.css'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Container, CircularProgress } from '@material-ui/core';

const theme = createMuiTheme({
  overrides: {
  },
})

export function HTMLViewer({ base64data, print, onPrintDone })
{
  if (base64data && print) {
    const html = new DOMParser().parseFromString(atob(base64data), 'text/html');
    html.body.setAttribute('onload', 'window.print()');
    base64data = btoa(unescape(encodeURIComponent(html.documentElement.outerHTML)));
    if (onPrintDone) {
      const timeout = setTimeout(
        () => {
          clearTimeout(timeout);
          onPrintDone();
        },
        2000
      );
    }
  }
  return (
    <div className={styles.textViewer}>
      {
        base64data
          ? (
            <iframe
              title='document'
              src={`data:text/html;base64,${base64data}`}
            >
              The “iframe” tag is not supported by your browser.
            </iframe>
          )
          : (
            <ThemeProvider theme={theme}>
              <Container className={viewerStyles.centeredProgress}>
                <CircularProgress/>
              </Container>
            </ThemeProvider>
          )
      }
    </div>
  );
}
