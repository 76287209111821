import {
  Box,
  FormControlLabel,
  makeStyles,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isArray } from "lodash";
import CheckIcon from "@material-ui/icons/Check";
import MuiAlert from "@material-ui/lab/Alert";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgb(0, 97, 129)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableBodyCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgb(0, 97, 129)",
    color: theme.palette.common.white,
    verticalAlign: "top",
  },
  body: {
    fontSize: 14,
    verticalAlign: "top",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {},
  tableContainer: {
    maxHeight: "40vh",
    overflowY: "auto",
  },
});

const getFullAddress = (address) => {
  let fullAddress = "";
  if (address) {
    if (isArray(address)) {
      address = address[0];
    }
    const line = address?.line ? address?.line.join(", ") : "";
    fullAddress = `${line}, ${address?.city}, ${address?.state}, ${address?.postalCode}, ${address?.country}`;
  }
  return fullAddress;
};

function HealthcareServiceRow({ row }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography variant="h6">{row?.name}</Typography>
      </Box>

      <Box>{row?.comment}</Box>
    </Box>
  );
}

function LocationRow({ row }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography variant="h6">{row?.name}</Typography>
      </Box>

      {row?.address && <Box>{getFullAddress(row.address)}</Box>}

      {row?.telecom?.map((t) => (
        <Box>{t?.value}</Box>
      ))}
    </Box>
  );
}

function OrganizationRow({ row }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography variant="h6">{row?.name}</Typography>
      </Box>

      {row?.address && <Box>{getFullAddress(row.address)}</Box>}

      {row?.telecom?.map((t) => (
        <Box>{t?.value}</Box>
      ))}
    </Box>
  );
}

function OrganizationInSystemRow({ row }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        {row?.name && (
          <Tooltip title={row?.name}>
            <CheckIcon />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}

function ServicesTable({ services, selectedService, setSelectedService }) {
  const classes = useStyles();
  const [notInNetworkAlert, setNotInNetworkAlert] = useState("");

  const handleServiceChange = (event) => {
    const selected = services.find(
      (s) => s.healthcareService.id === event.target.value
    );
    setSelectedService(selected);
  };

  useEffect(() => {
    if (selectedService && !selectedService?.organizationInSystem?.name) {
      setNotInNetworkAlert(
        "At this time we don't support out-of-network referrals. Please select an in-network provider to issue a referral"
      );
    } else {
      setNotInNetworkAlert("");
    }
  }, [selectedService]);

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          size="small"
          className={classes.table}
          aria-label="Services table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Service</StyledTableCell>
              <StyledTableCell>Location</StyledTableCell>
              <StyledTableCell>Organization</StyledTableCell>
              <StyledTableCell>In-Network</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((row) => (
              <StyledTableRow key={row.healthcareService.id}>
                <StyledTableBodyCell component="th" scope="row">
                  {row?.healthcareService && (
                    <HealthcareServiceRow row={row.healthcareService} />
                  )}
                </StyledTableBodyCell>
                <StyledTableBodyCell component="th" scope="row">
                  {row?.location && <LocationRow row={row.location} />}
                </StyledTableBodyCell>
                <StyledTableBodyCell component="th" scope="row">
                  {row?.organization && (
                    <OrganizationRow row={row.organization} />
                  )}
                </StyledTableBodyCell>
                <StyledTableBodyCell component="th" scope="row">
                  <OrganizationInSystemRow row={row?.organizationInSystem} />
                </StyledTableBodyCell>

                <StyledTableBodyCell>
                  <FormControlLabel
                    value={row.healthcareService.id}
                    control={<Radio />}
                    checked={
                      row.healthcareService.id ===
                      selectedService?.healthcareService?.id
                    }
                    onChange={handleServiceChange}
                    label=""
                  />
                </StyledTableBodyCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {notInNetworkAlert ? (
        <MuiAlert severity="warning" elevation={6} variant="filled">
          {notInNetworkAlert}
        </MuiAlert>
      ) : null}
    </>
  );
}

ServicesTable.propTypes = {
  services: PropTypes.array.isRequired,
  selectedService: PropTypes.object,
  setSelectedService: PropTypes.func.isRequired,
};

export default ServicesTable;
