import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Header from './Header'
import Footer from './Footer'
import Navigation from './Navigation'
import '../styles/index.css'
import { initHeartbeat } from '../utils';
import { routes } from '../Router';

const theme = createMuiTheme({
  overrides: {}
})

const Layout = (props) => {

  useEffect(() => initHeartbeat(), []);

  const history = useHistory()

  return (
    <ThemeProvider theme={theme}>
      <Grid container direction="column" justify="center">
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          <Navigation
            history={history}
            routes={routes}
            patientData={props.patientData}
          />
        </Grid>
        { props.children }
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  patientData: PropTypes.any,
  openFile: PropTypes.bool,
  searchNav: PropTypes.bool,
  referral: PropTypes.bool,
  openDocuments: PropTypes.func,
  openDocumentViewer: PropTypes.func,
  openPatientList: PropTypes.func,
}
export default Layout
