import React, { useEffect, useState } from "react";
import { XMLUtils } from "@nicheaim/utils-xml";
import { XSLTDocumentOptions, XSLTUrlOptions } from "@nicheaim/types-xml";
import styles from '../../../../styles/xmlviewerxslt.module.css';

export function XMLViewerXSLT(options: XSLTUrlOptions | XSLTDocumentOptions)
{
  const [content, setContent] = useState('');
  const [error, setError] = useState('');

  useEffect(
    () => getAndUpdateContent(options, setContent, setError),
    [options]
  );

  return (
    <div className={styles.xmlViewerXslt}>
      {error}
      <iframe
        title="XSLT Viewer"
        className="xml-viewer-xslt"
        src={content}
      >
        The “iframe” tag is not supported by your browser.
      </iframe>
    </div>
  );
}

function getAndUpdateContent(
  options: XSLTUrlOptions | XSLTDocumentOptions,
  setContent,
  setError
)
{
  try {
    XMLUtils.getTransformedContents(options).then(result => {
      const { documentFragment, errorMessage } = result;
      if (documentFragment) {
        const div = document.createElement('div');
        div.appendChild(result.documentFragment);
        setContent(getHtmlDataUrl(div.innerHTML.trim(), setError));
      }
      else if (errorMessage) {
        setError(errorMessage);
      }
    });
  }
  catch (error) {
    console.error(error);
    setError(error.message);
  }
}

function getHtmlDataUrl(htmlString, setError) {
  try {
    const base64Html = btoa(unescape(encodeURIComponent(htmlString)));
    return `data:text/html;base64,${base64Html}`;
  }
  catch (error) {
    console.error(error);
    setError(error.message);
    return '';
  }
}
