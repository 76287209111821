import {
  Button,
  Checkbox,
  CircularProgress,
  createMuiTheme,
  FormControlLabel,
  FormGroup,
  makeStyles,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import colors from "../../../utils/colors";
import MuiAlert from "@material-ui/lab/Alert";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: "48%",
        position: "absolute",
        bottom: "10px",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  referButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
  },
  descriptionFormField: {
    marginTop: theme.spacing(4),
  },
}));

function Refer({ service, handleSave, handleClose }) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [description, setDescription] = useState("");
  const [urgent, setUrgent] = useState(false);
  const [saveError, setSaveError] = useState("");

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleIsUrgentChange = (event) => {
    setUrgent(event.target.checked);
  };

  const canCreateReferral = () => {
    return !!service?.organizationInSystem?.name;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (canCreateReferral) {
      setIsSaving(true);
      setSaveError("");
      const referral = {
        description,
        patientId: "",
        urgent,
        // serviceCategory,
        receivingOrganizationName: service.organizationInSystem.name,
        // receiving: {
        //   organizationName
        // }
      };
      const error = await handleSave(referral);
      if (error) {
        setSaveError(error);
      }
      setDescription("");
      setUrgent(false);
      setIsSaving(false);
      handleClose();
    } else {
      setSaveError(
        "At this time we don't support out-of-network referrals. Please select an in-network provider to issue a referral"
      );
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormGroup row className={classes.descriptionFormField}>
          <FormControlLabel
            control={
              <Checkbox
                id="referral-is-urgent"
                autoFocus
                checked={urgent}
                onChange={handleIsUrgentChange}
              />
            }
            label="Urgent"
          />
        </FormGroup>

        <FormGroup row>
          <TextField
            id="Description"
            variant="outlined"
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={2}
            value={description}
            required
            onChange={handleDescriptionChange}
          />
        </FormGroup>

        <FormGroup row>
          <Button
            color="primary"
            type="submit"
            disabled={isSaving}
            className={classes.referButton}
          >
            Refer
          </Button>
        </FormGroup>
      </form>
      {isSaving && (
        <ThemeProvider theme={theme}>
          <CircularProgress />
        </ThemeProvider>
      )}
      {saveError && (
        <MuiAlert severity="error" elevation={6} variant="filled">
          {saveError}
        </MuiAlert>
      )}
    </>
  );
}

Refer.propTypes = {
  service: PropTypes.object,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Refer;
