import StackdriverErrorReporter from 'stackdriver-errors-js';

let errorHandler;

if (process.env.NODE_ENV === 'production') {
  console.error('errorHandler for production StackDriver');
  errorHandler = new StackdriverErrorReporter();
  errorHandler.start({
    key: process.env.REACT_APP_ERROR_HANDLER_APIKEY,
    projectId: process.env.REACT_APP_ERROR_HANDLER_PROJECT,
    // Other optional arguments can be supplied, see above.
  });
} else {
  console.error('errorHandler for development is console');
  errorHandler = {report: console.error};
}

export default errorHandler;
