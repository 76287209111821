import React from 'react';
import styles from '../../../styles/viewer.module.css';
import { urgentStyle } from './urgentStyle';
import { yesNoRenderer } from '../value-renderers';

const ReferralData = ({ referral }) => {
  if (referral) {
    return (
      <div className={styles.documentData}>
        <div>
          <b>Referrer</b>: {referral.referringOrganization}
        </div>
        <div>
          <b>Referee</b>: {referral.receivingOrganization}
        </div>
        <div>
          <b>Urgent</b>:
          &nbsp;
          <span style={urgentStyle(referral.urgent)}>
            {yesNoRenderer(referral.urgent)}
          </span>
        </div>
      </div>
    );
  }
  else {
    return (
      <div className={styles.documentData}>
        <div>
          Metadata Not Available
        </div>
      </div>
    );
  }
};

export default ReferralData;
