import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { omit } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { DocumentUtils, ReferralUtils } from "../../../services";
import { TransferBox } from "./TransferBox";
import { DocumentCatalogsStore } from "../Documents/DocumentCatalogsStore";

const MIN_SEARCH_TERM_LENGTH = 3;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  list: {
    width: 398,
    height: 350,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    minWidth: 400,
  },
  searchTextInputBorder: {
    display: "flex",
    alignItems: "center",
    width: 400,
    marginTop: theme.spacing(1),
  },
  searchTextInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIconButton: {
    padding: 10,
  },
  tooltipItemTitle: {
    fontVariantCaps: "small-caps",
    marginRight: "10px",
  },
  tooltipItemCell: {
    paddingTop: "4px",
    paddingBottom: "4px",
    borderTop: "1px solid rgba(255, 255, 255, 0.54)",
    borderBottom: "1px solid rgba(255, 255, 255, 0.54)",
    verticalAlign: "middle",
  },
}));

function not(a, b) {
  // return a.filter((value) => b.indexOf(value) === -1);
  var filtered = a.filter((n) => !b.some((n2) => n.id === n2.id));
  return filtered;
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function TransferList(props) {
  const {
    documents,
    linked,
    referral,
    onLinkableContentsChanged,
    onUploadDocumentRequested,
  } = props;
  const classes = useStyles();
  // const [linked, setLinked] = useState(
  //   ReferralUtils.findLinkedDocuments(documents, referral)
  // );
  const [selected, setSelected] = useState([]);
  const [available, setAvailable] = useState(not(documents, linked));
  const [linkable, setLinkable] = useState(linked);
  const [searchTerm, setSearchTerm] = useState("");
  const [catalogs, setCatalogs] = useState(null);

  useEffect(
    () => {
      (async () => {
        setCatalogs(await DocumentCatalogsStore.getDocumentCatalogs());
      })();
    }
  )

  // useEffect(() => {
  //   const _linked = ReferralUtils.findLinkedDocuments(documents, referral);
  //   setLinked(_linked);
  //   setLinkable(_linked);
  //   setAvailable(not(documents, _linked));
  // }, [documents, referral]);

  useEffect(() => {
    console.log("DEBUG setting linkable and available");
    setLinkable(linked);
    setAvailable(not(documents, linked));
  }, [documents, linked, referral]);

  useEffect(() => {
    if (documents) {
      console.log("DEBUG documents: ", documents);
    }
  }, [documents]);

  useEffect(() => {
    if (linked) {
      console.log("DEBUG linked: ", linked);
    }
  }, [linked]);

  useEffect(() => {
    if (available) {
      console.log("DEBUG available: ", available);
    }
  }, [available]);

  useEffect(() => {
    if (linkable) {
      console.log("DEBUG linkable: ", linkable);
    }
  }, [linkable]);

  const availableSelected = intersection(selected, available);
  const linkableSelected = intersection(selected, linkable);

  const clearSearchTerm = () => setSearchTerm("");

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleToggle = (value) => () => {
    const currentIndex = selected.indexOf(value);
    const newChecked = [...selected];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelected(newChecked);
  };

  const numberOfSelected = (items) => intersection(selected, items).length;

  const moveCheckedRight = () => {
    setLinkable(linkable.concat(availableSelected));
    setAvailable(not(available, availableSelected));
    setSelected(not(selected, availableSelected));
  };

  const moveAllRight = () => {
    clearSearchTerm();
    setLinkable(linkable.concat(available));
    setAvailable([]);
    setSelected([]);
  };

  const moveCheckedLeft = () => {
    setAvailable(available.concat(linkableSelected));
    setLinkable(not(linkable, linkableSelected));
    setSelected(not(selected, linkableSelected));
  };

  const moveAllLeft = () => {
    setAvailable(available.concat(not(linkable, linked)));
    setLinkable(linked);
    setSelected([]);
  };

  const selectedText = (items) => (
    <div
      style={{
        fontSize: "0.875rem",
        color: "rgba(0, 0, 0, 0.54)",
        marginTop: "0.5rem",
      }}
    >
      {`${numberOfSelected(items)}/${items.length} selected`}
    </div>
  );

  const hasSearchTerm = () =>
    searchTerm && searchTerm.length >= MIN_SEARCH_TERM_LENGTH;

  const documentMatchesSearchTerm = (document, enableSearch) => {
    if (!enableSearch || !hasSearchTerm()) {
      return true;
    }
    return Object.values(
      omit(DocumentUtils.getDisplayInfo(document, catalogs), ["iconName", "size"])
    ).find(
      (value) =>
        value &&
        typeof value === "string" &&
        value.toUpperCase().includes(searchTerm.toUpperCase())
    );
  };

  const notYetLinked = not(linkable, linked);
  const moveAllLeftDisabled = notYetLinked.length === 0;
  if (onLinkableContentsChanged) {
    onLinkableContentsChanged(!moveAllLeftDisabled, notYetLinked);
  }

  if (!catalogs) {
    return null;
  }

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        <TransferBox
          title="Available Documents"
          documents={available}
          enableSearch={false}
          handleToggle={handleToggle}
          selected={selected}
          linked={linked}
          onUploadDocumentRequested={onUploadDocumentRequested}
          enableUpload={true}
        />
        {/* {documentList("Available Documents", available, false)} */}
        {selectedText(available)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={moveAllRight}
            disabled={available.length === 0 || hasSearchTerm()}
            aria-label="Move all right"
          >
            &gt;&gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={moveCheckedRight}
            disabled={availableSelected.length === 0}
            aria-label="Move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={moveCheckedLeft}
            disabled={linkableSelected.length === 0}
            aria-label="Move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={moveAllLeft}
            disabled={moveAllLeftDisabled}
            aria-label="Move all left"
          >
            &lt;&lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <TransferBox
          title="Linked / To Be Linked Documents"
          documents={linkable}
          enableSearch={false}
          handleToggle={handleToggle}
          selected={selected}
          linked={linked}
          onUploadDocumentRequested={onUploadDocumentRequested}
        />
        {/* {documentList("Linked / To Be Linked Documents", linkable)} */}
        {selectedText(linkable)}
      </Grid>
    </Grid>
  );
}

TransferList.propTypes = {
  documents: PropTypes.object.isRequired,
  linked: PropTypes.object.isRequired,
  referral: ReferralUtils.REFERRAL_DETAIL_SHAPE.isRequired,
  onUploadDocumentRequested: PropTypes.func.isRequired,
  onLinkableContentsChanged: PropTypes.func,
};

export default TransferList;
