import React, { useEffect, useState } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Button, Typography, withStyles } from '@material-ui/core';
import { uploadDocument } from '../../../../services/MintAPI';
import styles from './document.uploader.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFontAwesomeIconFromMimeType } from '../getFontAwesomeIconFromMimeType';
import { getMimeTypeFromFilename } from '../getMimeTypeFromFilename';

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgress);

function DocumentUploader({ patientID, onFileSelected, reset, onReset, mimeTypeExtensions }) {

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [uploaded, setUploaded] = useState(false);

  useEffect(
    () => {
      if (reset) {
        setSelectedFiles(undefined);
        setCurrentFile(undefined);
        setProgress(0);
        setUploaded(false);
        onReset();
      }
    },
    [reset, onReset]
  )

  const upload = async (selectedFile, fhirDocument) => {
    if (!selectedFile) {
      return;
    }
    setCurrentFile(selectedFile);
    setProgress(0);

    try {
      await uploadDocument({
        patientID,
        file: selectedFile,
        fhirDocument,
        onUploadProgress: (event) => {
          setProgress(Math.round((100 * event.loaded) / event.total));
        }
    });
      setTimeout(() => setUploaded(true), 1000);
    }
    catch (error) {
      setProgress(0);
      setCurrentFile(undefined);
      setUploaded(false);
      return error.message;
    }
  };

  const getSelectedFile = (files = selectedFiles) => (
    files && files.length > 0 ? files[0] : { name: null }
  );

  const getSelectedFileName = (files = selectedFiles) => getSelectedFile(files).name;

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
    const selectedFile = getSelectedFile(event.target.files);
    onFileSelected({ upload, selectedFile });
  };

  const getMimeType = (filename) => {
    return getMimeTypeFromFilename(mimeTypeExtensions, filename);
  }

  const selectedFilename = getSelectedFileName();
  const mimeType = getMimeType(selectedFilename);
  const icon = getFontAwesomeIconFromMimeType(mimeType);

  return (
    <div>
      {
        currentFile && !uploaded && (
          <Box className={styles.mb25} display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <BorderLinearProgress variant="determinate" value={progress}/>
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
            </Box>
          </Box>
        )
      }
      <div style={{
        position: 'relative',
      }}>
        {
          !uploaded &&
          <label htmlFor="btn-upload">
            <input
              type="file" id="btn-upload"
              name="btn-upload"
              style={{ display: 'none' }}
              onChange={selectFile}
            />
            <Button className={styles.btnChoose} variant="outlined" component="span">
              Choose File
            </Button>
          </label>
        }
        <span style={{
          display: 'inline-block',
          position: 'absolute',
          ...(uploaded ? { left: '-15px', top: '-25px' } : { top: '2px' }),
          marginLeft: '15px',
        }}>
          {
            icon &&
            <FontAwesomeIcon
              icon={icon}
              size="2x"
              style={{
                color: '#006181',
                marginRight: '10px',
                position: 'relative',
              }}
            />
          }
          <span style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            position: 'absolute',
            ...(icon ? { bottom: '5px' } : { top: '1px' }),
            width: '300px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
            {selectedFilename}
          </span>
        </span>
      </div>
    </div>
  );
}

export default DocumentUploader;
