import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import "moment/min/locales";
import { Container, Grid } from "@material-ui/core";
import Layout from "../../Layout";
import "../../../styles/index.css";
import styles from "../../../styles/viewer.module.css";
import ReferralsAPI from "../../../services/ReferralsAPI";
import PatientData from "../PatientData";
import ReferralData from "./ReferralData";
import ReferralDetailTable from "./ReferralDetailTable";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Backdrop from '@material-ui/core/Backdrop';
import { theme } from "../theme";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReferralNotes from "./ReferralNotes";
import ReferralReferences from "./ReferralReferences";
import TextDialog from "./TextDialog";
import { ReferralActionButton, ReferralActions } from "./ReferralActions";
import ReferralStates, { getReferralPatientId } from "../../../services/ReferralStates";
import { patientDataStore } from "../../../store/PatientDataStore";
import { PatientProvisioningDialog } from "../PatientProvisioningDialog";
import auth from "../../../utils/auth";
import { getNormalizedPatientData } from "../../../utils";
import { NotFoundException } from "../../../utils/exceptions";
import ReferralDocumentSelectionDialogContainer from "./ReferralDocumentSelectionDialogContainer";
import { ReferralUtils } from "../../../services";
import ReferralDocuments from "./ReferralDocuments";
import { ReferralReferenceDialog } from "./ReferraReferenceDialog";
import { ReferralHistory } from "./ReferralHistory";
import ConfirmationDialog from "./ConfirmationDialog";
import { ReferralDialog } from "./ReferralDialog";
import { AssignDialog } from "./AssignDialog";
import { userNameRenderer } from "../value-renderers";
import { ReferralActionsMenu } from "./ReferralActionsMenu";
import ReferralMetadata from "./ReferralMetadata";

const noAttachment = () => ({
  id: null,
  type: null,
  title: "",
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ReferralDetail = () => {
  const history = useHistory();
  const classes = useStyles();
  const { patientId, referralId } = useParams();
  const [patientInfo, setPatientInfo] = useState(null);
  const [referral, setReferral] = useState(null);
  const [error, setError] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isProvisionDialogOpen, setProvisionDialogOpen] = useState(false);
  const [isShowOnlyOkButtonInDialog, setIsShowOnlyOkButtonInDialog] = useState(false);
  const [isWithdrawDialogOpen, setIsWithdrawDialogOpen] = useState(false);
  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);
  const [isAcknowledgeDialogOpen, setIsAcknowledgeDialogOpen] = useState(false);
  const [isAcceptDialogOpen, setIsAcceptDialogOpen] = useState(false);
  const [isStartDialogOpen, setIsStartDialogOpen] = useState(false);
  const [isMissingInfoDialogOpen, setIsMissingInfoDialogOpen] = useState(false);
  const [isNotifyInfoProvidedDialogOpen, setIsNotifyInfoProvidedDialogOpen] = useState(false);
  const [isReferralDocumentDialogOpen, setIsReferralDocumentDialogOpen] = useState(false);
  const [isReferralDocumentReferenceDialogOpen, setIsReferralDocumentReferenceDialogOpen] = useState(false);
  const [isRejectCommentDialogOpen, setIsRejectCommentDialogOpen] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isCompleteCommentDialogOpen, setIsCompleteCommentDialogOpen] = useState(false);
  const [isReopenCommentDialogOpen, setIsReopenCommentDialogOpen] = useState(false);
  const [isReferralAssignDialogOpen, setIsReferralAssignDialogOpen] = useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState(noAttachment());
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);
  const [dialogPatientData, setDialogPatientData] = useState({});
  const [dialogTitle, setDialogTitle] = useState('Add Referral Patient to your Organization?');
  const [isUpdateReferralDialogOpen, setIsUpdateReferralDialogOpen] = useState(false);
  const [isCreateChildReferralDialogOpen, setIsCreateChildReferralDialogOpen] = useState(false);
  const [lockScreen, setLockScreen] = useState(false);
  const [users, setUsers] = useState([]);
  const orgName = auth.loggedInUser.userinfo.orgName;
  const clearError = () => setError("");
  const acknowledgeErrorHandler = useRef(clearError);
  const goingBack = useRef(false);

  const goBackErrorHandler = () => {
    clearError();
    goingBack.current = true;
    history.goBack();
    acknowledgeErrorHandler.current = clearError;
  };

  useEffect(
    () => {
      ReferralsAPI
        .getOrganizationMembers(orgName)
        .then(users => setUsers(users));
    },
    [orgName],
  );

  const loadReferral = useCallback(
    () => {
      return new Promise(async (resolve, reject) => {
        if (!goingBack.current) {
          setLockScreen(true);
        }
        try {
          const result = await ReferralUtils.getReferralWithDocuments(patientId, referralId);
          const _referral = result.referral
              ?.children
              ?.find(child => Number(child.referralId) === Number(referralId))
            || result.referral
          ;
          resolve({
            ..._referral,
            get linkedDocuments() {
              return ReferralUtils.findLinkedDocuments(result.documents, _referral);
            },
          });
        } catch (error) {
          acknowledgeErrorHandler.current = goBackErrorHandler;
          reject(error);
        } finally {
          setLockScreen(false);
          goingBack.current = false;
        }
      });
    },
    [patientId, referralId],
  );

  const showProvisionDialogIfApplicable = useCallback(
    (
      dialogReferral = referral,
      dialogPatentInfo = patientInfo,
    ) => {
      const isOpenIdUser = auth.loggedInUser?.["authType"] === "OIDC";
      if (!dialogReferral.receivingPatientId) {
        if (!isOpenIdUser) {
          setDialogTitle([
              `Can't post Patient `,
              `to the originating system. Please use the following information`,
              `to register it manually:`,
            ]
              .join(" "),
          );
        }
        setIsShowOnlyOkButtonInDialog(!isOpenIdUser);
        setDialogPatientData(dialogPatentInfo);
        setProvisionDialogOpen(true);
      }
    },
    [patientInfo, referral],
  );

  useEffect(
    () => {

      const loadData = async () => {
        if (referralId) {
          let _referral;
          try {
            _referral = await loadReferral();
            await loadReferralCatalogs()
            setReferral(_referral);
          } catch (error) {
            setError(error.message);
          }
          if (_referral) {
            try {
              let _patientInfo = await patientDataStore.getPatientData(
                patientId || getReferralPatientId(_referral),
              );
              if (!_patientInfo) {
                _referral = await ReferralsAPI.getReferral(referralId, true);
                const searchPatientId = patientId || getReferralPatientId(_referral);
                if(searchPatientId) {
                  _patientInfo = await getNormalizedPatientData(searchPatientId);
                } else {
                  _patientInfo = await getNormalizedPatientData(searchPatientId, referralId);
                }
                setReferral(_referral);
                showProvisionDialogIfApplicable(_referral, _patientInfo);
              }
              setPatientInfo(_patientInfo);
            } catch (error) {
              if (!(error instanceof NotFoundException)) {
                setError(error.message);
              }
            } finally {
              setIsDataLoaded(true);
            }
          }
        }

      };
      loadData();
    },
    [patientId, referralId, loadReferral],
  );

  const loadReferralCatalogs = async () => {
    console.log("DEBUG ReferralDetail loadReferralCatalogs");
    try {
      const orgs = await ReferralsAPI.getOrganizations();
      console.log("DEBUG loadReferralCatalogs orgs: ", orgs);
      sessionStorage.setItem("organizations", JSON.stringify(orgs));
    } catch (error) {
      console.log(error)
    }
  };

  const openNoteDialog = () => {
    setIsNoteDialogOpen(true);
  };

  const closeNoteDialog = () => {
    setIsNoteDialogOpen(false);
  };

  const closeDocumentReferenceDialog = () => {
    setIsReferralDocumentReferenceDialogOpen(false);
  };

  const openDeleteAttachmentDialog = (attachment) => {
    setAttachmentToDelete(attachment);
  };

  const closeDeleteAttachmentDialog = () => {
    setAttachmentToDelete(noAttachment());
  };

  const addNote = async (noteText) => {
    try {
      await ReferralsAPI.addNote(referralId, noteText);
      closeNoteDialog();
      setReferral(await loadReferral());
    } catch (error) {
      return error.message;
    }
  };

  const addDocumentReference = async (annotation, documentUrl) => {
    try {
      await ReferralsAPI.addDocumentReference(referralId, annotation, documentUrl);
      closeDocumentReferenceDialog();
      setReferral(await loadReferral());
    } catch (error) {
      return error.message;
    }
  };

  const acceptReferral = async (comment) => {
    try {
      await ReferralsAPI.acceptReferral(referralId, comment);
      closeAcceptDialog();
      setReferral(await loadReferral());
    } catch (error) {
      return error.message;
    }
  };

  const startReferral = async (comment) => {
    try {
      await ReferralsAPI.startReferral(referralId, comment);
      closeStartDialog();
      setReferral(await loadReferral());
    } catch (error) {
      return error.message;
    }
  };

  const requestMissingInfoForReferral = async (comment) => {
    try {
      await ReferralsAPI.requestMissingInfoForReferral(referralId, comment);
      closeMissingInfoDialog();
      setReferral(await loadReferral());
    } catch (error) {
      return error.message;
    }
  };

  const notifyReferralInfoProvided = async (comment) => {
    try {
      await ReferralsAPI.notifyReferralInfoProvided(referralId, comment);
      closeNotifyInfoProvidedDialog();
      setReferral(await loadReferral());
    } catch (error) {
      return error.message;
    }
  };

  const completeReferral = async (comment) => {
    try {
      await ReferralsAPI.completeReferral(referralId, comment);
      closeReferralCompleteCommentDialog();
      setReferral(await loadReferral());
    } catch (error) {
      return error.message;
    }
  };

  const reopenReferral = async () => {
    try {
      await ReferralsAPI.reopenReferral(referralId);
      closeReferralReopenCommentDialog();
      setReferral(await loadReferral());
    } catch (error) {
      return error.message;
    }
  };

  const assignReferral = async (mintUserId) => {
    try {
      await ReferralsAPI.assignReferral(referralId, mintUserId);
      closeReferralAssignDialog();
      setReferral(await loadReferral());
    } catch (error) {
      return error.message;
    }
  };

  const showReferralRejectCommentDialog = () => {
    setIsRejectCommentDialogOpen(true);
  };

  const showCancelDialog = () => {
    setIsCancelDialogOpen(true);
  };

  const showReferralCompleteCommentDialog = () => {
    setIsCompleteCommentDialogOpen(true);
  };

  const showReferralReopenCommentDialog = () => {
    setIsReopenCommentDialogOpen(true);
  };

  const showReferralAssignDialog = () => {
    setIsReferralAssignDialogOpen(true);
  };

  const closeReferralRejectCommentDialog = () => {
    setIsRejectCommentDialogOpen(false);
  };

  const closeCancelDialog = () => {
    setIsCancelDialogOpen(false);
  };

  const closeReferralCompleteCommentDialog = () => {
    setIsCompleteCommentDialogOpen(false);
  };

  const closeReferralReopenCommentDialog = () => {
    setIsReopenCommentDialogOpen(false);
  };

  const closeReferralAssignDialog = () => {
    setIsReferralAssignDialogOpen(false);
  };

  const showReferralDialog = () => {
    setIsUpdateReferralDialogOpen(true);
  };

  const showCreateChildReferralDialog = () => {
    setIsCreateChildReferralDialogOpen(true);
  };

  const closeUpdateReferralDialog = () => {
    setIsUpdateReferralDialogOpen(false);
  };

  const closeCreateChildReferralDialog = () => {
    setIsCreateChildReferralDialogOpen(false);
  };

  const rejectReferral = async (comment) => {
    try {
      await ReferralsAPI.rejectReferral(referralId, comment);
      setReferral(await loadReferral());
      closeReferralRejectCommentDialog();
    } catch (error) {
      return error.message;
    }
  };

  const cancelReferral = async (comment) => {
    try {
      await ReferralsAPI.cancelReferral(referralId, comment);
      setReferral(await loadReferral());
      closeCancelDialog();
    } catch (error) {
      return error.message;
    }
  };

  const deleteReferralAttachment = async () => {
    try {
      await ReferralsAPI.deleteReferralAttachment(attachmentToDelete);
      setReferral(await loadReferral());
      closeDeleteAttachmentDialog();
    } catch (error) {
      return error.message;
    }
  };

  const withdrawReferral = async (comment) => {
    try {
      await ReferralsAPI.withdrawReferral(referralId, comment);
      if (patientId) {
        history.push(`/patient/search/${patientId}/info/1/referrals`);
      } else {
        history.push(`/referrals`);
      }
      closeWithdrawDialog();
    } catch (error) {
      return error.message;
    }
  };

  const submitReferral = async (comment) => {
    try {
      await ReferralsAPI.submitReferral(referralId, comment);
      if (patientId) {
        history.push(`/patient/search/${patientId}/info/1/referrals`);
      } else {
        history.push(`/referrals`);
      }
      closeSubmitDialog();
    } catch (error) {
      return error.message;
    }
  };

  const acknowledgeReferral = async (comment) => {
    try {
      await ReferralsAPI.acknowledgeReferral(referralId, comment);
      setReferral(await loadReferral());
      closeAcknowledgeDialog();
    } catch (error) {
      return error.message;
    }
  };

  const updateReferral = async (referralUpdates) => {
    try {
      await ReferralsAPI.updateReferral(referralId, referralUpdates);
      setReferral(await loadReferral());
      closeUpdateReferralDialog();
    } catch (error) {
      return error.message;
    }
  };

  const createChildReferral = async (childReferral) => {
    try {
      await ReferralsAPI.createReferral(childReferral);
      setReferral(await loadReferral());
      closeCreateChildReferralDialog();
    } catch (error) {
      return error.message;
    }
  };

  const onReferralSelect = (selectedReferralId) => {
    if (selectedReferralId) {
      if (referralId) {
        history.push(`/patient/search/${patientId}/info/1/referrals/${selectedReferralId}`);
      } else {
        history.push(`/referrals/${selectedReferralId}`);
      }
    }
  };

  const handleProvisionDialogSave = async (patientData) => {
    try {
      const referralPayload = {...referral, demographics: dialogPatientData};
      const { patientID, referral: provisionedReferral } =
        await ReferralsAPI.provisionReferralPatient(auth, referralPayload)
      ;
      patientData.id = patientID;
      setPatientInfo({ ...patientData });
      setReferral(provisionedReferral);
      closeProvisionDialog();
    } catch (error) {
      return error.message;
    }
  };

  const closeProvisionDialog = () => {
    setProvisionDialogOpen(false);
  };

  const closeWithdrawDialog = () => {
    setIsWithdrawDialogOpen(false);
  };

  const closeSubmitDialog = () => {
    setIsSubmitDialogOpen(false);
  };

  const closeAcknowledgeDialog = () => {
    setIsAcknowledgeDialogOpen(false);
  };

  const closeAcceptDialog = () => {
    setIsAcceptDialogOpen(false);
  };

  const closeStartDialog = () => {
    setIsStartDialogOpen(false);
  };

  const closeMissingInfoDialog = () => {
    setIsMissingInfoDialogOpen(false);
  };

  const closeNotifyInfoProvidedDialog = () => {
    setIsNotifyInfoProvidedDialogOpen(false);
  };

  const showWithdrawDialog = () => {
    setIsWithdrawDialogOpen(true);
  };

  const showSubmitDialog = () => {
    setIsSubmitDialogOpen(true);
  };

  const showAcknowledgeDialog = () => {
    setIsAcknowledgeDialogOpen(true);
  };

  const showAcceptDialog = () => {
    setIsAcceptDialogOpen(true);
  };

  const showStartDialog = () => {
    setIsStartDialogOpen(true);
  };

  const showMissingInfoDialog = () => {
    setIsMissingInfoDialogOpen(true);
  };

  const showNotifyInfoProvidedDialog = () => {
    setIsNotifyInfoProvidedDialogOpen(true);
  };

  const showReferralDocumentsDialog = () => {
    setIsReferralDocumentDialogOpen(true);
  };

  const hideReferralDocumentsDialog = () => {
    setIsReferralDocumentDialogOpen(false);
  };

  const showReferralDocumentReferenceDialog = () => {
    setIsReferralDocumentReferenceDialogOpen(true);
  };

  const handleDocumentLinked = async () => {
    setReferral(await loadReferral());
  };

  const hasNotes = isDataLoaded && referral?.notes?.length > 0;
  const hasReferences = isDataLoaded && referral?.references?.length > 0;
  const hasDocuments = referral?.linkedDocuments?.length > 0;
  const hasActivities = referral?.activity?.length > 0;

  return (
    <Fragment>
      <TextDialog
        title="Add Note"
        subtitle="Note Text"
        open={isNoteDialogOpen}
        handleSave={addNote}
        handleClose={closeNoteDialog}
      />
      <ReferralReferenceDialog
        open={isReferralDocumentReferenceDialogOpen}
        handleSave={addDocumentReference}
        handleClose={closeDocumentReferenceDialog}
      />
      <TextDialog
        open={isRejectCommentDialogOpen}
        title="Reject Referral?"
        subtitle="Reason for rejection"
        text="The referral will be rejected. This action cannot be reversed. Are you sure?"
        handleSave={rejectReferral} handleClose={closeReferralRejectCommentDialog}
      />
      <TextDialog
        open={isCancelDialogOpen}
        title="Cancel Referral?"
        subtitle="Reason for cancellation"
        text="The referral will be cancelled. This action cannot be reversed. Are you sure?"
        handleSave={cancelReferral} handleClose={closeCancelDialog}
      />
      <TextDialog
        open={isCompleteCommentDialogOpen}
        title="Complete Referral"
        subtitle="Completion Comment"
        handleSave={completeReferral} handleClose={closeReferralCompleteCommentDialog}
      />
      <TextDialog
        open={isReopenCommentDialogOpen}
        title="Reopen Referral"
        subtitle="Reopen Comment"
        handleSave={reopenReferral} handleClose={closeReferralReopenCommentDialog}
      />
      {
        users.length > 0 &&
        <AssignDialog
          open={isReferralAssignDialogOpen}
          handleSave={assignReferral}
          handleClose={closeReferralAssignDialog}
          users={users}
          userLabel={userNameRenderer}
        />
      }
      <PatientProvisioningDialog
        patientData={dialogPatientData}
        open={isProvisionDialogOpen}
        title={dialogTitle}
        handleSave={handleProvisionDialogSave}
        handleClose={closeProvisionDialog}
        showOnlyOkButton={isShowOnlyOkButtonInDialog}
      />
      <TextDialog
        open={isWithdrawDialogOpen}
        title="Withdraw Referral?"
        subtitle="Reason for withdrawal"
        text="The referral will be withdrawn. This action cannot be reversed. Are you sure?"
        handleSave={withdrawReferral}
        handleClose={closeWithdrawDialog}
      />
      <TextDialog
        open={isSubmitDialogOpen}
        title="Submit Referral"
        subtitle="Comments"
        handleSave={submitReferral}
        handleClose={closeSubmitDialog}
      />
      <TextDialog
        open={isAcknowledgeDialogOpen}
        title="Acknowledge Referral"
        subtitle="Comments"
        handleSave={acknowledgeReferral}
        handleClose={closeAcknowledgeDialog}
      />
      <TextDialog
        open={isStartDialogOpen}
        title="Start Referral"
        subtitle="Comments"
        handleSave={startReferral}
        handleClose={closeStartDialog}
      />
      <TextDialog
        open={isAcceptDialogOpen}
        title="Accept Referral"
        subtitle="Comments"
        handleSave={acceptReferral}
        handleClose={closeAcceptDialog}
      />
      <TextDialog
        open={isMissingInfoDialogOpen}
        title="Request Additional Information"
        subtitle="Required Information"
        handleSave={requestMissingInfoForReferral}
        handleClose={closeMissingInfoDialog}
      />
      <TextDialog
        open={isNotifyInfoProvidedDialogOpen}
        title="Notify Info Provided"
        subtitle="Comment"
        handleSave={notifyReferralInfoProvided}
        handleClose={closeNotifyInfoProvidedDialog}
      />
      <ConfirmationDialog
        open={!!attachmentToDelete.id}
        title={attachmentToDelete.title}
        text="This action cannot be reversed. Are you sure?"
        handleSave={deleteReferralAttachment}
        handleClose={closeDeleteAttachmentDialog}
      />
      {
        referral &&
        <>
          <ReferralDialog
            open={isUpdateReferralDialogOpen}
            referral={referral}
            patientId={patientId}
            handleSave={updateReferral}
            handleClose={closeUpdateReferralDialog}
          />
          <ReferralDialog
            open={isCreateChildReferralDialogOpen}
            patientId={patientId}
            parentReferralId={referral.referralId}
            handleSave={createChildReferral}
            handleClose={closeCreateChildReferralDialog}
          />
        </>
      }
      {
        isReferralDocumentDialogOpen &&
        <ReferralDocumentSelectionDialogContainer
          open={isReferralDocumentDialogOpen}
          handleSave={handleDocumentLinked}
          handleClose={hideReferralDocumentsDialog}
          patientId={patientId}
          referralId={referralId}
          referral={referral}
        />
      }
      {
        isReferralDocumentReferenceDialogOpen &&
        <ReferralDocumentSelectionDialogContainer
          open={isReferralDocumentDialogOpen}
          handleSave={handleDocumentLinked}
          handleClose={hideReferralDocumentsDialog}
          patientId={patientId}
          referralId={referralId}
          referral={referral}
        />
      }
      <Layout referral={true} patientData={patientInfo}>
        <Grid item xs={12}>
          <Container className="container-documents">
            <div className={styles.documentViewer}>
              {
                error &&
                <ConfirmationDialog
                  open={!!error}
                  title="Errors"
                  text={error}
                  handleSave={acknowledgeErrorHandler.current}
                  textTransformer={ReferralUtils.errorTransformer}
                />
              }
              {
                !error && isDataLoaded
                && (
                  <Fragment>
                    <PatientData patientData={patientInfo}/>
                    <Grid container spacing={2} className={styles.leftButtonContainer}>
                      <Grid item>
                        <ReferralActionButton
                          text="Add Patient to your Org"
                          action={showProvisionDialogIfApplicable}
                          canShow={ReferralStates.canProvision} referral={referral}
                          color="secondary"
                          patientInfo={patientInfo}
                        />
                        <ReferralActionsMenu
                          referral={referral}
                          patientInfo={patientInfo}
                          users={users}
                          handlers={{
                            updateHandler: showReferralDialog,
                            addNoteHandler: openNoteDialog,
                            linkDocumentHandler: showReferralDocumentsDialog,
                            addReferenceHandler: showReferralDocumentReferenceDialog,
                            withdrawHandler: showWithdrawDialog,
                            submitHandler: showSubmitDialog,
                            acknowledgeHandler: showAcknowledgeDialog,
                            acceptHandler: showAcceptDialog,
                            startHandler: showStartDialog,
                            requestInfoHandler: showMissingInfoDialog,
                            confirmInfoProvidedHandler: showNotifyInfoProvidedDialog,
                            completeHandler: showReferralCompleteCommentDialog,
                            rejectHandler: showReferralRejectCommentDialog,
                            cancelHandler: showCancelDialog,
                            reopenHandler: showReferralReopenCommentDialog,
                            assignHandler: showReferralAssignDialog,
                            addChildHandler: showCreateChildReferralDialog,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <ReferralData referral={referral}/>
                    <ReferralDetailTable referral={referral} users={users} onReferralSelect={onReferralSelect}/>
                    {
                      hasNotes &&
                      <ReferralNotes notes={referral.notes} onNoteDelete={openDeleteAttachmentDialog}/>
                    }
                    {
                      hasReferences &&
                      <ReferralReferences references={referral.references}
                                          onReferenceDelete={openDeleteAttachmentDialog}/>
                    }
                    {
                      hasDocuments &&
                      <ReferralDocuments referral={referral} patientId={patientId}
                                         onDocumentDelete={openDeleteAttachmentDialog}/>
                    }
                    <ReferralMetadata referral={referral} />
                    {
                      hasActivities &&
                      <ReferralHistory activities={referral.activity}/>
                    }
                  </Fragment>
                )
              }
              {
                !error && isDataLoaded && lockScreen &&
                <Backdrop className={classes.backdrop} open={lockScreen}>
                  <div>
                    <CircularProgress color="inherit" />
                  </div>
                </Backdrop>
              }
              {
                !error && !isDataLoaded
                && (
                  <ThemeProvider theme={theme}>
                    <div>
                      <CircularProgress/>
                    </div>
                  </ThemeProvider>
                )
              }
            </div>
          </Container>
        </Grid>
      </Layout>
    </Fragment>
  );
};

export default withRouter(ReferralDetail);
