import React from 'react';
import errorHandler from '../utils/ErrorLog';
import { Redirect } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, redirect: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
      window.onerror = (error) => {
        console.error(error);
        var resp=errorHandler.report(error);
        resp.then(function(){});
        this.setState({ hasError: true });
      }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('ErrorDidCatch', { error, errorInfo });
    var resp=errorHandler.report(error);
    resp.then(function(){});
  }
  // under componentDidCatch
  componentDidUpdate() {
    if (this.state.hasError) {
      setTimeout(() => this.setState({ redirect: true }), 5000);
    }
  }

  render() {
    if (this.state.hasError) {
      return <Redirect to="/error" noThrow />;;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
