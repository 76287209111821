import React, { Fragment, useState, useEffect, useRef } from 'react';
import {  withRouter } from 'react-router-dom';
import UsersAPI from '../../../services/UsersAPI';
import styles from '../../../styles/viewer.module.css';
import MuiAlert from '@material-ui/lab/Alert';
import MaterialTable from 'material-table';
import axios from 'axios'
import { tableIcons } from '../../PatientViewer/table-icons';
import { activDesactUserUrl, updateUserUrl } from '../../../utils/constants';
import InviteForm from '../../InviteForm';
import {Button, Snackbar} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import UpdateInfoModal from '../../UpdateInfoModal';
import userIcon from './user-icon.png';
import { startCase } from 'lodash';
import InvitesAPI from '../../../services/InvitesAPI';

const checkRoles = (roles) => {
  if (roles === 'null') return
  if (typeof roles === 'string') {
    let _roles = roles.trim();
    try {
      return JSON.parse(_roles).map(startCase).join(', ')
    }
    catch (error) {
      return _roles;
    }
  }
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UsersHome = () => {
  const [ users, setUsers ] = useState([]);
  const [ error, setError ] = useState(false);
  const [infoMessage, setInfoMessage] = useState("")
  const [openInfoMessage, setOpenInfoMessage] = useState(false)
  const [tempInfo, setTempInfo] = useState({})
  const anchorRef = useRef([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false)



  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    loadInfo()
  }, [])

  const activeDesactive = async () => {
    try {
      await axios.post(activDesactUserUrl, {
        status: tempInfo.status,
        userName: tempInfo.username
      })
      setError(false)
      setOpenInfoMessage(true)
      setInfoMessage(`${tempInfo.status === 'Active' ? 'Active' :'Inactive'} user ${tempInfo.username}`)
      await loadInfo()
    } catch (e){
      setError(true)
      setOpenInfoMessage(true)
      setInfoMessage(`Something failed activating or deactivating the user, please try again later.`, e)
    }
  }

  const handleClose = async (event) => {
    switch(event.target.innerText){
      case 'Edit' : setOpenUpdate(true); break;
      case 'Activate/Inactive': await activeDesactive(); break;
      default:
    }
    anchorRef.current = []
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleError = () => {
    setOpenInfoMessage(false)
    setError(false)
  }

  const handleInvite = async (info) => {
    try {
      await InvitesAPI.createInvite(info);
      setError(false)
      setOpenInfoMessage(true)
      setInfoMessage("Invited successfully")

    } catch(e){
      setError(true)
      setOpenInfoMessage(true)
      setInfoMessage(e.response?.data || e.message)
    }
  };

  const sendInfo = async (info) => {
    try {
      await axios.post(updateUserUrl, {...info})
      await loadInfo()
      setError(false)
      setOpenInfoMessage(true)
      setInfoMessage(`Edit User successfully`)

    } catch (e) {
      setError(true)
      setOpenInfoMessage(true)
      setInfoMessage(`Something failed editing the user, please try again later.`, e)
    }
  }

  const loadInfo = async () => {
    try{
      const users = await UsersAPI.getUsers()
      if(users){
        setUsers(users);
        setError(false);
      }
    } catch(e){
      setError(true)
      throw new Error('Fail try to load info', e)
    }

    return () => {
      setUsers([])
      setInfoMessage("")
      setTempInfo({})
    }
  }


    return(
      <Fragment>
        <div className={styles.documentViewer}>
          <Button variant="contained" color="primary" onClick={() => setModalOpen(true)} style={{margin: 12, position: 'absolute', zIndex: 1}}>
            Invite User
          </Button>
          <MaterialTable
            title=""
            columns={[
              {
                title: '',
                cellStyle: { textAlign: 'center' },
                headerStyle: { textAlign: 'center' },
                render: () => <img alt="icon" src={userIcon} style={{width: 30}}/>
              },
              {
                title: 'Username',
                field: 'username',
                cellStyle: { textAlign: 'center' },
                headerStyle: { textAlign: 'center' },
                searchable: true
              },
              {
                title: 'First Name',
                field: 'firstName',
                cellStyle: { textAlign: 'center' },
                headerStyle: { textAlign: 'center' },
                searchable: true
              },
              {
                title: 'Last Name',
                field: 'lastName',
                cellStyle: { textAlign: 'center' },
                headerStyle: { textAlign: 'center' },
                searchable: true
              },
              {
                title: 'Status',
                field: 'status',
                cellStyle: { textAlign: 'center' },
                headerStyle: { textAlign: 'center' },
                lookup: {Active: "Active", Inactive: "Inactive", Invited: "Invited"},
              },
              {
                title: 'Roles',
                field: 'roles',
                cellStyle: { textAlign: 'center' },
                headerStyle: { textAlign: 'center' },
                lookup: {User: "User", Manager: "Manager"},
                render: rowData => <p>{checkRoles(rowData.roles)}</p>
              },
            ]}
            icons={tableIcons}
            data={users}
            options={{
              emptyRowsWhenPaging: true,
              minBodyHeight: '100%',
              maxBodyHeight: '100%',
              padding: 'dense',
              pageSize: 10,
              search: true,
              actionsColumnIndex: -1
            }}
            actions={[
              {
                icon: 'menu',
                tooltip: 'Menu',
                onClick: (event, rowData) => {
                  setTempInfo({
                    tableId: rowData.tableData.id,
                    firstName: rowData.firstName,
                    lastName: rowData.lastName,
                    roles: rowData.roles,
                    status: rowData.status === 'Active' ? 'Inactive' : 'Active',
                    username: rowData.username,
                  })
                  setOpen((prevOpen) => !prevOpen);
                }
              }
            ]}
            components={{
              Action: (props) => {
                return (
                  <>
                    <MenuIcon
                      id={props.data.tableData.id}
                      aria-haspopup="true"
                      ref={el => anchorRef.current[props.data.tableData.id] = el}
                      onClick={(event) => props.action.onClick(event, props.data)}
                    />
                    <Popper
                      open={open}
                      anchorEl={anchorRef.current[tempInfo.tableId]}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                <MenuItem value="Edit" onClick={handleClose}>Edit</MenuItem>
                                <MenuItem value="Activate/Inactive" onClick={handleClose}>Activate/Inactive</MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                )
              }
            }}
          />
          <InviteForm open={modalOpen} setOpen={setModalOpen} handleInvite={handleInvite} />
          <UpdateInfoModal open={openUpdate} sendInfo={sendInfo} setOpen={() => setOpenUpdate(false)} updateType='users' tempInfo={tempInfo}/>
          <Snackbar open={openInfoMessage} autoHideDuration={2000} onClose={handleError} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert onClose={handleError} severity={error ? 'error' : 'success'}>
              {infoMessage}
            </Alert>
          </Snackbar>
        </div>
      </Fragment>
    )
  }

  export default withRouter(UsersHome);
