import auth from './auth';
import axios from 'axios';

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export class AxiosHelper {

  static newInstance(options) {
    const instance = axios.create({ ...defaultOptions, ...(options || {}) });
    instance.interceptors.request.use(
      config => {
        const token = auth.token();
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      error => error
    );
    return instance;
  }

}
