import React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { GetApp } from '@material-ui/icons'
import colors from '../../../../utils/colors'
import beautifyXml from 'xml-beautifier';
import { kebabCase } from 'lodash';

const theme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        color: colors.gray01,
        cursor: 'pointer',
        height: '25px',
        marginRight: '70px',
        right: '0',
        top: '153px',
        position: 'absolute',
        width: '25px'
      }
    }
  }
})

const DownloadButton = ({
  fileData,
  fileType,
  xsltData,
  docTitle,
}) => {
  if(fileType === 'file-pdf') {
    return(
      <ThemeProvider theme={theme}>
        <a href={`data:application/pdf;base64,${fileData}`} download="file.pdf">
          <GetApp />
        </a>
      </ThemeProvider>
    )
  }
  if(fileType === 'file-image') {
    return(
      <ThemeProvider theme={theme}>
        <a href={`data:image/jpeg;base64,${fileData}`} download="file.jpeg">
          <GetApp />
        </a>
      </ThemeProvider>
    )
  }
  if (fileType === 'file-code') {
    const filename = kebabCase(docTitle);
    const downloadFilename = xsltData ? `${filename}.xml` : `${filename}.html`;
    const mimeType = xsltData ? 'text/xml' : 'text/html';
    const urlData = xsltData ? btoa(beautifyXml(atob(xsltData))) : fileData;
    return(
      <ThemeProvider theme={theme}>
        <a href={`data:${mimeType};base64,${urlData}`} download={downloadFilename}>
          <GetApp />
        </a>
      </ThemeProvider>
    )
  }
  if(fileType === 'file-json') {
    return(
      <ThemeProvider theme={theme}>
        <a href={"data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(fileData))} download="file.json">
          <GetApp />
        </a>
      </ThemeProvider>
    )
  }
  if(fileType === 'file-html') {
    return(
      <ThemeProvider theme={theme}>
        <a href={"data:text/html;charset=utf-8," + encodeURIComponent(fileData)} download="file.html">
          <GetApp />
        </a>
      </ThemeProvider>
    )
  }
  if(fileType === 'file') {
    return(
      <ThemeProvider theme={theme}>
        <a href={`data:text/plain;base64,${fileData}`} download="file.txt">
          <GetApp />
        </a>
      </ThemeProvider>
    )
  }
}

export default DownloadButton
