import React, { useState } from "react";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FormList, FormText } from "../../controls";
import { useField, useForm } from "../../../../../utils/form-utils";
import { categoryProperty } from "../../utils/fhir-utils/domain/CareTeam";
import { managingOrganizationProperty } from "../../utils/fhir-utils/domain/CareTeam/careteam-properties";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import colors from "../../../../../utils/colors";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: "48%",
        position: "absolute",
        bottom: "10px",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));

const EMPTY_VALUES = {
  name: "",
  category: "",
  managingOrganization: "",
  saving: false,
  saveError: "",
};

export function CareTeamEditDialog({ careTeam, open, onClose, onSubmit }) {
  const classes = useStyles();
  const [saving, setSaving] = useState(EMPTY_VALUES.saving);
  const [saveError, setSaveError] = useState(EMPTY_VALUES.saveError);
  const form = useForm();

  const name = useField(
    form,
    "Care Team Name",
    careTeam?.getName() || EMPTY_VALUES.name
  );

  const category = useField(
    form,
    "Category",
    careTeam?.getCategoryId() || EMPTY_VALUES.category
  );

  const managingOrganization = useField(
    form,
    "Managing Organization",
    careTeam?.getManagingOrganizationId() || EMPTY_VALUES.managingOrganization
  );

  const reset = () => {
    form.reset();
    setSaving(EMPTY_VALUES.saving);
    setSaveError(EMPTY_VALUES.saveError);
  };

  const submit = async () => {
    if (form.isInvalid || !careTeam) {
      return;
    }
    try {
      setSaving(true);
      careTeam.setName(name.value);
      await careTeam.setCategory(category.value);
      await careTeam.setManagingOrganization(managingOrganization.value);
      await onSubmit(careTeam);
      reset();
      onClose();
    } catch (error) {
      setSaving(EMPTY_VALUES.saving);
      setSaveError(error.message);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} onExited={reset} onEnter={reset}>
      <DialogTitle>{careTeam?.isNew() ? "Add a" : "Edit"} Care Team</DialogTitle>
        <DialogContent>
          <FormGroup row>
            <FormText field={name} classes={classes} />
          </FormGroup>
          <FormGroup row>
            <FormList
              field={category}
              getValues={categoryProperty.getValues}
              classes={classes}
            />
          </FormGroup>
          <FormGroup row>
            <FormList
              field={managingOrganization}
              getValues={managingOrganizationProperty.getValues}
              classes={classes}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
            }}
            disabled={saving}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={submit}
            color="primary"
            disabled={form.isInvalid || saving}
          >
            {saveError ? "Retry" : "Save"}
          </Button>
        </DialogActions>
        {saving && (
          <ThemeProvider theme={theme}>
            <div>
              <CircularProgress />
            </div>
          </ThemeProvider>
        )}
        {saveError && (
          <MuiAlert severity="error" elevation={6} variant="filled">
            {saveError}
          </MuiAlert>
        )}
      </Dialog>
    </div>
  );
}
