import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import XsltButton from "./FileActionsBar/XsltButton";
import HtmlButton from "./FileActionsBar/HtmlButton";
import DownloadButton from "./FileActionsBar/DownloadButton";
import PrintButton from "./FileActionsBar/PrintButton";
import ImageViewer from "./Viewers/ImageViewer";
import NotAvailableViewer from "./Viewers/NotAvailableViewer";
import { HTMLViewer } from "./Viewers/HTMLViewer";
import { XMLViewer } from "./Viewers/XmlViewer";
import { getDocument, getTemplate, getViewer } from "../../../services/MintAPI";
import styles from "../../../styles/viewer.module.css";
import { Container, CircularProgress } from "@material-ui/core";
import { FhirReferralsAPI } from "../../../services";
import { useFhirReferrals } from "../../../utils/constants";

const TEMPLATE_FILE_TYPES = ["file-code", "file-alt"];
const KNOWN_FILE_TYPES = TEMPLATE_FILE_TYPES.concat([
  "file",
  "file-pdf",
  "file-image",
]);

const theme = createMuiTheme({
  overrides: {},
});

const FileViewer = ({
  fileContentType,
  fileData,
  fileType,
  handleBack,
  isEmptyDocument,
  fhirDocument,
}) => {
  const { docReferenceId, dataType } = useParams();
  const [xsltData, setXsltData] = useState(null);
  const [docTitle, setDocTitle] = useState(null);
  const [xsltView, setXsltView] = useState(false);
  const [htmlView, setHtmlView] = useState(true);
  const [docTemplate, setDocTemplate] = useState(null);
  const [documentErrorMessage, setDocumentErrorMessage] = useState(null);
  const [contentHeight, setContentHeight] = useState(calculateContentHeight());
  const [loading, setLoading] = useState(true);
  const [innerPrint, setInnerPrint] = useState(false);
  const isUnknownFileType = !KNOWN_FILE_TYPES.includes(fileType);
  const isTemplateFileType = TEMPLATE_FILE_TYPES.includes(fileType);
  const API = FhirReferralsAPI;

  useEffect(() => {
    window.onresize = () => setContentHeight(calculateContentHeight());
    const fetchData = async () => {
      let doc = [];
      if (useFhirReferrals) {
        doc = await API.getDocumentReference({
          docReferenceId: docReferenceId,
        });
      } else {
        doc = await getDocument({
          docReferenceId: docReferenceId,
          rawCCD: true,
        });
      }
      setXsltData(_.get(doc, "content[0].attachment.data", null));
      setDocTitle(_.get(doc, "content[0].attachment.title", null));
      const errorMessage = _.get(doc, "errorMessage", null);
      setDocumentErrorMessage(errorMessage);
    };
    console.log("DEBUG: fhirDocument: ", fhirDocument);
    console.log("DEBUG: isTemplateFileType: ", isTemplateFileType);
    if (fhirDocument) {
      if (isTemplateFileType) {
        getTemplate({ fhirDocument })
          .then((template) => {
            setLoading(false);
            if (template) {
              setDocTemplate(template);
            } else {
              if (dataType === "file-code") fetchData();
            }
          })
          .catch((error) => {
            setLoading(false);
            setHtmlView(false);
            if (dataType === "file-code") fetchData();
          });
      } else {
        handleGetViewer();
      }
    } else {
      setLoading(false);
    }
  }, [docReferenceId, fileData, fhirDocument, dataType, fileType]);

  const handleGetViewer = async () => {
    try {
      const viewer = await getViewer({ fhirDocument });
      console.log("DEBUG viewer: ", viewer);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrint = () => {
    const winparams =
      "dependent=yes,locationbar=no,scrollbars=yeenubar=yes," +
      "resizable,screenX=50,screenY=50,width=850,height=1050";
    const htmlPop =
      "<embed width=100% height=100%" +
      ` type="${fileContentType}"` +
      ` src="data:${fileContentType};base64,` +
      escape(fileData) +
      '"></embed>';

    const printWindow = window.open("", "PDF", winparams);
    printWindow.document.write(htmlPop);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  const handleInnerPrint = () => {
    setInnerPrint(true);
  };

  const handleInnerPrintDone = () => {
    setInnerPrint(false);
  };

  const handleHTMLPrint = () => {
    const winparams =
      "dependent=yes,locationbar=no,scrollbars=yeenubar=yes," +
      "resizable,screenX=50,screenY=50,width=850,height=1050";
    const htmlPop = docTemplate;

    const printWindow = window.open("", "PDF", winparams);
    printWindow.document.write(htmlPop);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  const handleHtmlView = () => {
    setHtmlView(!htmlView);
  };

  const handleXsltView = async () => {
    setXsltView(!xsltView);
  };

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Container className={styles.centeredProgress}>
          <CircularProgress />
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      {fileType === "file-pdf" && fileData && (
        <div className={styles.viewer}>
          <div className={styles.documentContainer}>
            <iframe
              title="Patient Document"
              style={{ width: "100%", height: "100%" }}
              src={`data:application/pdf;base64,${fileData}`}
            />
          </div>
        </div>
      )}
      {fileType === "file-image" && fileData && fileType && fileContentType && (
        <ImageViewer
          fileData={fileData}
          fileType={fileType}
          fileContentType={fileContentType}
          handleBack={handleBack}
          handlePrint={handlePrint}
        />
      )}
      {fileType === "file-code" && !docTemplate && xsltData && (
        <div className={styles.viewer}>
          <div className={styles.test}>
            <div style={{ position: "relative", top: "-140px" }}>
              {!documentErrorMessage ? (
                <XsltButton handleView={handleXsltView} typeView={xsltView} />
              ) : (
                <XsltButton typeView={xsltView} />
              )}
              <DownloadButton
                fileData={fileData}
                fileType={fileType}
                xsltData={xsltData}
                docTitle={docTitle}
              />
              <PrintButton handlePrint={handleInnerPrint} />
            </div>
          </div>
          <div className={styles.xmlViewer}>
            {xsltView && (
              <XMLViewer
                xmlUrl={`data:text/xml;base64,${xsltData}`}
                xslUrl="/assets/CDA.xsl"
              />
            )}
            {!xsltView && isEmptyDocument && (
              <h6 className={styles.errorMessage}>
                There was an error with the document, please review it.
              </h6>
            )}
            {!xsltView && !documentErrorMessage && (
              <HTMLViewer
                base64data={fileData}
                print={innerPrint}
                onPrintDone={handleInnerPrintDone}
              />
            )}
          </div>
        </div>
      )}
      {fileType === "file" && fileData && (
        <div className={styles.viewer}>
          <div className={styles.test}>
            <div style={{ position: "relative", top: "-140px" }}>
              <HtmlButton
                handleView={handleHtmlView}
                typeView={htmlView}
                fhirDoocument={fhirDocument}
                defaultLabel="TEXT"
              />
              }
              <DownloadButton fileData={fileData} fileType={"file"} />
              <PrintButton handlePrint={handlePrint} />
            </div>
          </div>
          <div className={styles.xmlViewer}>
            {renderTextLikeFile(fileData, contentHeight, htmlView)}
          </div>
        </div>
      )}
      {(fileType === "file-alt" || docTemplate) && (
        <div className={styles.viewer}>
          <div className={styles.test}>
            <div style={{ position: "relative", top: "-140px" }}>
              {!documentErrorMessage && docTemplate != null && (
                <HtmlButton
                  handleView={handleHtmlView}
                  typeView={htmlView}
                  fhirDoocument={fhirDocument}
                />
              )}
              {documentErrorMessage && docTemplate != null && (
                <HtmlButton typeView={htmlView} fhirDoocument={fhirDocument} />
              )}
              <DownloadButton
                fileData={htmlView ? docTemplate : fileData}
                fileType={htmlView ? "file-html" : "file-json"}
              />
              <PrintButton
                handlePrint={htmlView ? handleHTMLPrint : handlePrint}
              />
            </div>
          </div>
          <div className={styles.xmlViewer}>
            {htmlView && (
              <div>
                <iframe
                  srcDoc={docTemplate}
                  frameBorder="0"
                  title="survey"
                  style={{
                    overflow: "scroll",
                    height: `${contentHeight}px`,
                    width: "100%",
                  }}
                />
              </div>
            )}
            {!htmlView && isEmptyDocument && (
              <h6 className={styles.errorMessage}>
                There was an error with the document, please review it.
              </h6>
            )}
            {!htmlView && !documentErrorMessage && (
              <div
                style={{
                  overflow: "scroll",
                  height: `${contentHeight}px`,
                  width: "100%",
                }}
              >
                <pre>{formatJson(fileData)}</pre>
              </div>
            )}
          </div>
        </div>
      )}
      {isUnknownFileType && (
        <NotAvailableViewer
          fileContentType={fileContentType}
          fileData={fileData}
          fileType={fileType}
        />
      )}
    </ThemeProvider>
  );
};

function calculateContentHeight() {
  return window.innerHeight - 350;
}

function formatJson(base64FileData) {
  if (!base64FileData) {
    return "";
  }
  const fileData = atob(base64FileData);
  try {
    const jsonObj = JSON.parse(fileData);
    return JSON.stringify(jsonObj, null, 2);
  } catch (error) {
    return fileData;
  }
}

function renderTextLikeFile(base64FileData, contentHeight, typeView) {
  const fileData = atob(base64FileData);
  const style = {
    overflow: "scroll",
    height: `${contentHeight}px`,
    width: "99.5%",
  };
  if (typeView && fileData.toUpperCase().includes("<HTML")) {
    return (
      <iframe
        title="CCDA"
        style={style}
        src={`data:text/html;base64,${base64FileData}`}
      />
    );
  }
  return (
    <textarea style={style} readOnly={true}>
      {formatJson(base64FileData)}
    </textarea>
  );
}

export default FileViewer;
