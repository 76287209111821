import React, { Fragment, useRef, useState } from "react";
import DocumentsTable from "material-table";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../../styles/viewer.module.css";
import { baseTheme } from "../theme";
import { tableIcons } from "../table-icons";
import colors from "../../../utils/colors";
import IconButton from "@material-ui/core/IconButton";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmationDialog from "./ConfirmationDialog";

const tableTheme = createMuiTheme({
  overrides: {
    ...baseTheme.overrides,
    MuiTableCell: {
      head: {
        background: colors.blueGray,
        fontWeight: "600",
        textAlign: "left",
      },
      body: {
        color: `${colors.baseGray} !important`,
        fontSize: "11px !important",
        textAlign: "left !important",
      },
    },
  },
});

const FhirReferralDocumentList = ({ data, showDocument, onDocumentDelete }) => {
  const [isDeleteDocumentDialogVisible, setIsDeleteDocumentDialogVisible] =
    useState(false);
  const documentIdToDelete = useRef(null);

  const handleDeleteDocument = (documentId) => {
    onDocumentDelete(documentId);
  };

  const showDeleteDocumentDialog = () => {
    setIsDeleteDocumentDialogVisible(true);
  };

  const hideDeleteDocumentDialog = () => {
    setIsDeleteDocumentDialogVisible(false);
  };

  const columns = [
    {
      field: "format",
      cellStyle: {
        width: 5,
      },
      render: (rowData) => {
        return (
          <Tooltip title={rowData.contentType}>
            <div>
              <FontAwesomeIcon
                icon={rowData.iconName}
                size="2x"
                className={styles.typeIcon}
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Title",
      field: "title",
      cellStyle: {
        width: "20%",
      },
    },
    {
      title: "Author",
      field: "author",
      cellStyle: {
        width: "20%",
      },
    },
    {
      title: "Date / Time",
      field: "creationDate",
      cellStyle: {
        width: "20%",
      },
    },
    {
      title: "Document Type",
      field: "type",
      cellStyle: {
        width: "20%",
      },
    },
    {
      title: "Description",
      field: "description",
      cellStyle: {
        width: "20%",
      },
    },
    {
      cellStyle: {
        width: 5,
      },
      disableClick: true,
      render: (rowData) => {
        return (
          <Tooltip title="Delete Document">
            <IconButton
              aria-label="Delete Document"
              color="secondary"
              component="div"
              onClick={() => {
                documentIdToDelete.current = rowData.document.id;
                showDeleteDocumentDialog();
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faTrash}
                  size="2x"
                  className={styles.deleteIcon}
                />
              </div>
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  try {
    if (data) {
      return (
        <Fragment>
          <ConfirmationDialog
            open={isDeleteDocumentDialogVisible}
            title="Delete Document?"
            text="This action cannot be reversed. Are you sure?"
            handleSave={handleDeleteDocument}
            handleClose={hideDeleteDocumentDialog}
          />
          <ThemeProvider theme={tableTheme}>
            <DocumentsTable
              columns={columns}
              data={data}
              icons={tableIcons}
              onRowClick={(e, data) => showDocument(data)}
              style={{
                border: "1px groove #A8A8A8",
              }}
              options={{
                minBodyHeight: "100%",
                maxBodyHeight: "100%",
                padding: "dense",
                paging: true,
                search: false,
                showTitle: false,
                toolbar: false,
              }}
            />
          </ThemeProvider>
        </Fragment>
      );
    } else {
      return (
        <ThemeProvider theme={tableTheme}>
          <div>
            <CircularProgress />
          </div>
        </ThemeProvider>
      );
    }
  } catch (error) {
    return (
      <ThemeProvider theme={tableTheme}>
        <Container className={styles.errorContainer}>{error.message}</Container>
      </ThemeProvider>
    );
  }
};

export default FhirReferralDocumentList;
