import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { getUniqueSortedValues } from "../../utils/filter-utils";
import { FormList, FormText } from "../../controls";
import { useForm, useOptionalField } from "../../../../../utils/form-utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));

const EMPTY_VALUES = {
  status: "",
  owner: "",
  periodStart: null,
  periodEnd: null,
};

export function TaskFilterDialog({
  activities,
  open,
  onClose,
  onFilterSelected,
}) {
  const classes = useStyles();
  const form = useForm();
  const status = useOptionalField(form, "Status", EMPTY_VALUES.status);
  const owner = useOptionalField(form, "Owner", EMPTY_VALUES.owner);
  const periodStart = useOptionalField(
    form,
    "Start Date",
    EMPTY_VALUES.periodStart
  );
  const periodEnd = useOptionalField(
    form,
    "Start Date",
    EMPTY_VALUES.periodEnd
  );

  const setFilter = () => {
    onFilterSelected({
      status: status.value,
      owner: owner.value,
      periodStart: periodStart.value,
      periodEnd: periodEnd.value,
    });
  };

  const reset = () => {
    form.reset();
  };

  const cancel = () => {
    reset();
    onFilterSelected(EMPTY_VALUES);
    onClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Filter Tasks By</DialogTitle>
        <DialogContent>
          <FormGroup row>
            <FormList
              field={status}
              getValues={() =>
                getUniqueSortedValues(activities, (activity) =>
                  activity?.getStatus()
                )
              }
              classes={classes}
            />
          </FormGroup>
          <FormGroup row>
            <FormList
              field={owner}
              getValues={() =>
                getUniqueSortedValues(activities, (activity) =>
                  activity?.getOwnerDisplayName()
                )
              }
              classes={classes}
            />
          </FormGroup>
          <FormGroup row>
            <FormText type="date" field={periodStart} classes={classes} />
          </FormGroup>
          <FormGroup row>
            <FormText type="date" field={periodEnd} classes={classes} />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary">
            Cancel
          </Button>
          <Button onClick={setFilter} color="primary">
            Filter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
