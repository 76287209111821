import { CarePlan } from "./CarePlan";
import {
  resolveContainedResource,
  resolveRelativeResource,
} from "./resolve-utils";

export class CarePlanBundle {
  data;
  #resolvedReferences;
  #setCurrentCarePlan;

  constructor(rawBundle, setCurrentCarePlan) {
    this.data = rawBundle;
    this.#resolvedReferences = {};
    this.#setCurrentCarePlan = setCurrentCarePlan;
  }

  getResources(resourceType) {
    return getBundleResources(this.data, resourceType);
  }

  getCarePlans() {
    return this.getResources("CarePlan").map(
      (rawCarePlan) => new CarePlan(rawCarePlan, this)
    );
  }

  addGoal(rawGoal, rawCarePlan) {
    this.data.entry = [{ resource: rawGoal }, ...this.data.entry];
    this.#setCurrentCarePlan(new CarePlan(rawCarePlan, this));
  }

  addTask(rawTask, rawCarePlan) {
    this.data.entry = [{ resource: rawTask }, ...this.data.entry];
    this.#setCurrentCarePlan(new CarePlan(rawCarePlan, this));
  }

  addCareTeam(rawCareTeam, rawCarePlan) {
    this.data.entry = [{ resource: rawCareTeam }, ...this.data.entry];
    this.#setCurrentCarePlan(new CarePlan(rawCarePlan, this));
  }

  resolve(parentResource, reference) {
    const referenceValue = reference?.reference || reference;
    if (!this.#resolvedReferences[referenceValue]) {
      this.#resolvedReferences[referenceValue] = resolveBundleResource(
        this.data,
        parentResource,
        referenceValue
      );
    }
    return this.#resolvedReferences[referenceValue];
  }

  getCarePlanResourcesOfType(resourceType, carePlanId) {
    const carePlanReferencesToResources = resolveCarePlanReferences(this);
    const careplanResources = carePlanReferencesToResources[carePlanId];
    return Object.values(careplanResources).filter(
      (resource) => resource?.resourceType === resourceType
    );
  }
}

function resolveCarePlanReferences(carePlanBundle) {
  const resolvedReferences = {};
  for (const carePlan of carePlanBundle.getCarePlans()) {
    resolvedReferences[carePlan.getId()] = {};
    for (const goalReference of carePlan.data.goal || []) {
      resolvedReferences[carePlan.getId()][goalReference.reference] =
        carePlanBundle.resolve(carePlan, goalReference);
    }
    for (const activity of carePlan.getActivities() || []) {
      for (const goalReference of activity?.data?.detail?.goal || []) {
        resolvedReferences[carePlan.getId()][goalReference.reference] =
          carePlanBundle.resolve(carePlan, goalReference);
      }
    }
    for (const careTeamReference of carePlan.data.careTeam || []) {
      resolvedReferences[carePlan.getId()][careTeamReference.reference] =
        carePlanBundle.resolve(carePlan, careTeamReference);
    }
  }
  return resolvedReferences;
}

function getBundleResources(bundle, resourceType) {
  return (bundle?.entry || [])
    .map((entry) => entry?.resource)
    .filter(
      (resource) => !resourceType || resource?.resourceType === resourceType
    );
}

function resolveBundleResource(bundle, parentResource, referenceValue) {
  return (
    resolveContainedResource(parentResource, referenceValue) ||
    resolveRelativeResource(bundle, referenceValue)
  );
}
