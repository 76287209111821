import React, { useEffect, useRef, useState } from 'react';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { XMLViewerXSLT } from "./XmlViewerXslt";
import { XMLViewerRaw } from "./XmlViewerRaw";
import { XMLUtils } from "@nicheaim/utils-xml";
import colors from '../../../../utils/colors';
import styles from '../../../../styles/xmlviewer.module.css';
import { TextViewer } from './TextViewer';
import { HTMLViewer } from './HTMLViewer';
//import 'typeface-roboto';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    fontFamily: "'Roboto', sans-serif"
  },
}));

const customTheme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      positionStatic: {
        height: '35px',
        position: 'absolute',
        top: '140px',
        transform: 'rotateY(45deg)',
        zIndex: 'auto'
      }
    },
    MuiPaper: {
      elevation4: {
        boxShadow: '0'
      }
    },
    MuiTabs: {
      root: {
        background: colors.baseBlue,
      }
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: colors.baseBlue,
        marginLeft: '40px'
      }
    }
  }
})

export function XMLViewer(options)
{
  const { buildXSLTResources, fetchXSLTResources } = XMLUtils;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const xsltResources = useRef(buildXSLTResources());

  useEffect(() => {
    if (loading) {
      fetchXSLTResources(options)
        .then(_xsltResources => {
          xsltResources.current = _xsltResources;
          setLoading(false);
        });
    }
  });

  return (
    <div className={classes.root}>
      {
        loading
          ? <Loading/>
          : xsltResources.current.noXml()
          ? (
            <ErrorMessage message={xsltResources.current.xml.errorMessage}/>
          )
          : (
            <TabBar
              value={value}
              setValue={setValue}
              options={toOptions(xsltResources.current)}

            />
          )
      }
    </div>
  );
}

function Loading()
{
  return <div>Loading...</div>;
}

function ErrorMessage(props)
{
  return <>{props.message}</>;
}

function toOptions(xsltResources)
{
  if (xsltResources.xml.value) {
    return {
      xml: xsltResources.xml.value.document,
      xsl: xsltResources.xsl.value ? xsltResources.xsl.value.document : undefined,
      raw: xsltResources.xml.value.raw,
      base64data: xsltResources.xml.value.base64data,
      isHtml: xsltResources.xml.value.isHtml,
      xmlString: xsltResources.xml.value.string
    };
  }
  else {
    throw new Error('No XML');
  }
}

function TabBar(props)
{
  const { value, setValue, options } = props;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let tabIndex = -1;
  let panelIndex = -1;

  return <ThemeProvider theme={customTheme}>
    <div style={{ position: 'relative', bottom: '190px' }}>
      <AppBar position="static">

      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="XML Views"
      >
        {
          !options.isHtml &&
          <Tab label="XSLT" {...a11yProps(++tabIndex)} />
        }
        {
          !options.isHtml &&
          <Tab label="XPATH" {...a11yProps(++tabIndex)} />
        }
        {
          (options.isHtml || options.htmlProvided) &&
          <Tab label="HTML" {...a11yProps(++tabIndex)} />
        }
        <Tab label="TEXT" {...a11yProps(++tabIndex)} />
      </Tabs>
    </AppBar>
    </div>
    {
      !options.isHtml &&
      <TabPanel
        value={value}
        index={++panelIndex}
      >
        <XMLViewerXSLT
          xml={options.xml}
          xsl={options.xsl}
        />
      </TabPanel>
    }

    {
      !options.isHtml &&
      <TabPanel
        value={value}
        index={++panelIndex}
      >
        <XMLViewerRaw xmlString={options.xmlString}/>
      </TabPanel>
    }

    {
      (options.isHtml || options.htmlProvided) &&
      <TabPanel
        value={value}
        index={++panelIndex}
      >
        <HTMLViewer base64data={options.base64data}/>
      </TabPanel>
    }

    <TabPanel
      value={value}
      index={++panelIndex}
    >
      <TextViewer text={options.raw}/>
    </TabPanel>

  </ThemeProvider>;
}

function TabPanel(props)
{
  const { children, value, index, ...other } = props;

  return (
    <div
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className={styles.viewerContainer}>{children}</div>}
    </div>
  );
}

function a11yProps(index)
{
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
