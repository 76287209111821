import React from 'react'
import { theme } from './curesTheme';
import { withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { tableIcons } from '../table-icons';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import CuresTable from 'material-table'
import styles from '../../../styles/viewer.module.css';
import moment from 'moment';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#F3F3F3',
    color: '#000000',
    border: '1px solid #C8C7CB',
  },
}))(Tooltip);

const CuresList = ({
    loading,
    data,
    columns = [
      {
        title: 'Medication',
        field: 'medication',
        cellStyle: {
          width: '21%',
        },
        headerStyle: {
          width: '21%',
          whiteSpace: 'nowrap',
        }
      },
      {
        title: 'Note',
        field: 'note',
        cellStyle: {
          width: '21%',
          maxWidth: '21%',
          wordBreak: 'break-all'
        },
        headerStyle: {
          width: '21%',
          maxWidth: '21%',
          whiteSpace: 'nowrap',
        }
      },
      {
        title: "Prescriber",
        field: 'prescriber',
        cellStyle: {
          wordBreak: 'break-all'
        },
        render: rowData => {
            const { npi, deaNumber } = rowData.prescriberTooltip
            return (
              <HtmlTooltip
                title={
                  <div className={styles.customTooltipData}>
                    <hr/>
                    <p><b>DEA Number: </b>{deaNumber}</p>
                    <hr/>
                    <p><b>NPI: </b>{npi}</p>
                    <hr/>
                  </div>
                }
              >
                <div className={styles.iconRow}>
                  <FontAwesomeIcon
                    icon={faEye} 
                    className={styles.curesIcon}
                  />
                  <p>{rowData.prescriber}</p>
                </div>
              </HtmlTooltip>
            );
          }
      },
      {
        title: 'Pharmacy',
        field: 'pharmacy',
        render: rowData => {
          const { ncpdpid, npi, stateLicenseNumber } = rowData.pharmacyTooltip
          return (
            <HtmlTooltip
              title={
                <div className={styles.customTooltipData}>
                  <hr/>
                  <p><b>NCPDPID: </b>{ncpdpid}</p>
                  <hr/>
                  <p><b>State License Number: </b>{stateLicenseNumber}</p>
                  <hr/>
                  <p><b>NPI: </b>{npi}</p>
                  <hr/>
                </div>
              }
            >
              <div className={styles.iconRow}>
                <FontAwesomeIcon
                  icon={faEye} 
                  className={styles.curesIcon}
                />
                <p>{rowData.pharmacy}</p>
              </div>
            </HtmlTooltip>
          );
        }
      },
      {
        title: 'Last fill date',
        field: 'lastFillDate',
        render: rowData => {
          return (
            <div className={styles.centeredElement}>
              <p>{moment(rowData.lastFillDate).format('MMM D, YYYY')}</p>
            </div>
          );
        },
        headerStyle: { textAlign: 'center', padding: 0, width: '130px' },
        cellStyle: { textAlign: 'center', padding: 0, width: '130px' }
      },
      {
        title: 'Sold date',
        field: 'soldDate',
        render: rowData => {
          return (
            <div className={styles.centeredElement}>
              <p>{moment(rowData.soldDate).format('MMM D, YYYY')}</p>
            </div>
          );
        },
        headerStyle: { textAlign: 'center', padding: 0, width: '120px' },
        cellStyle: { textAlign: 'center', padding: 0, width: '120px' }
      },
      {
        title: 'Substitutions',
        field: 'substitutions',
        render: rowData => {
          return (
            <div className={styles.centeredElement}>
              <p>{rowData.substitutions}</p>
            </div>
          );
        },
        headerStyle: { textAlign: 'center', padding: 0 }
      }
    ]
  }) => {
    if(loading){
      return (
        <ThemeProvider theme={theme}>
          <div>
            <CircularProgress/>
          </div>
        </ThemeProvider>
      );
    } else try {
      if (data) {
        if (data.error) {
          return (
            <ThemeProvider theme={theme}>
              <Container className={styles.errorContainer}>
                {data.error}
              </Container>
            </ThemeProvider>
          );
        }
        else {
          return (
            <ThemeProvider theme={theme}>
              <CuresTable
                title="" 
                columns={columns} 
                data={data} 
                icons={tableIcons}
              />
            </ThemeProvider>
          );
        }
      }
      else {
        return (
          <ThemeProvider theme={theme}>
            <div>
              <CircularProgress/>
            </div>
          </ThemeProvider>
        );
      }
    }
    catch (error) {
      return (
        <ThemeProvider theme={theme}>
          <Container className={styles.errorContainer}>
            {error.message}
          </Container>
        </ThemeProvider>
      );
    }
  }

export default CuresList
