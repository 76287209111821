import { AxiosHelper } from "../../../utils/AxiosHelper";
import { internalFhirUrl } from "../../../utils/constants";

const axiosInstance = AxiosHelper.newInstance();

async function apiCall(apiCall) {
  try {
    const result = await apiCall();
    return result?.data;
  } catch (error) {
    console.error(error);
    const message = [
      error?.response?.data ? `${error?.response?.data}` : "",
      error?.response?.status ? ` (Status: ${error?.response?.status})` : "",
    ];
    throw new Error(message.join(""));
  }
}

export class CarePlansAPI {
  static async getPatientCarePlansBundle(patientId) {
    return apiCall(() =>
      axiosInstance.get(`${internalFhirUrl}/CarePlan`, {
        params: {
          subject: patientId,
        },
      })
    );
  }

  static async getOrganizations() {
    return apiCall(() => axiosInstance.get(`${internalFhirUrl}/Organization`));
  }

  static async createCarePlanChild(child) {
    if (!child) {
      throw new Error(
        "Create CarePlan child resource: A FHIR domain object was not provided."
      );
    }
    return await apiCall(() =>
      axiosInstance.post(
        `${internalFhirUrl}/${
          !child.getResourceType
            ? child.data.resourceType
            : child.getResourceType()
        }`,
        {
          parentResourceId: child.getCarePlan().getId(),
          parentResourceType: "CarePlan",
          childResource: !child.getChildResource
            ? child.data
            : child.getChildResource(),
        }
      )
    );
  }

  static async updateResource(resource) {
    const rawResource = resource?.data || resource;
    await apiCall(() =>
      axiosInstance.put(
        `${internalFhirUrl}/${rawResource.resourceType}/${rawResource.id}`,
        rawResource
      )
    );
  }

  static async getGoalCategoriesValueSet() {
    return {
      compose: {
        include: [
          {
            concept: [
              {
                code: "behavioral",
                display: "Behavioral health",
              },
              {
                code: "educational",
                display: "Education",
              },
              {
                code: "referral-medical",
                display: "Medical Referral",
              },
              {
                code: "services-social",
                display: "Social Services",
              },
              {
                code: "wellness",
                display: "Wellness",
              },
            ],
            system:
              "https://dev-fhir.patientholistics.app/fhir/CodeSystem/crs-goal-categories",
          },
        ],
      },
      experimental: false,
      id: "crs-goal-categories",
      meta: {
        lastUpdated: "2023-07-06T19:04:21.065492+00:00",
        versionId: "MTY4ODY3MDI2MTA2NTQ5MjAwMA",
      },
      name: "Custom Value Set of Goal Categories",
      publisher: "NicheAim Labs",
      resourceType: "ValueSet",
      status: "draft",
      url: "https://dev-fhir.patientholistics.app/fhir/ValueSet/crs-goal-categories",
    };
  }

  static async getGoalAchievmentStatusValueSet() {
    return {
      resourceType: "CodeSystem",
      id: "goal-achievement",
      extension: [
        {
          url: "http://hl7.org/fhir/StructureDefinition/structuredefinition-wg",
          valueCode: "pc",
        },
      ],
      url: "http://terminology.hl7.org/CodeSystem/goal-achievement",
      identifier: [
        {
          system: "urn:ietf:rfc:3986",
          value: "urn:oid:2.16.840.1.113883.4.642.4.1375",
        },
      ],
      version: "4.0.1",
      name: "GoalAchievementStatus",
      title: "Goal achievement status",
      status: "draft",
      experimental: false,
      publisher: "HL7 International - Patient Care WG",
      contact: [
        {
          telecom: [
            {
              system: "url",
              value: "http://www.hl7.org/Special/committees/patientcare/",
            },
          ],
        },
      ],
      description:
        "Describes the progression, or lack thereof, towards the goal against the target.",
      caseSensitive: true,
      valueSet: "http://hl7.org/fhir/ValueSet/goal-achievement",
      content: "complete",
      concept: [
        {
          code: "in-progress",
          display: "In Progress",
          definition:
            "The goal is being sought but has not yet been reached. (Also applies if the goal was reached in the past but there has been regression and the goal is again being sought).",
          concept: [
            {
              code: "improving",
              display: "Improving",
              definition: "The goal is being sought, and is progressing.",
            },
            {
              code: "worsening",
              display: "Worsening",
              definition: "The goal is being sought, but is regressing.",
            },
            {
              code: "no-change",
              display: "No Change",
              definition: "The goal is being sought, but the trend is flat.",
            },
          ],
        },
        {
          code: "achieved",
          display: "Achieved",
          definition: "The goal has been met.",
          concept: [
            {
              code: "sustaining",
              display: "Sustaining",
              definition:
                "The goal has been met, but ongoing activity is needed to sustain the goal objective.",
            },
          ],
        },
        {
          code: "not-achieved",
          display: "Not Achieved",
          definition:
            "The goal has not been met and there might or might not have been progress towards target.",
          concept: [
            {
              code: "no-progress",
              display: "No Progress",
              definition:
                "The goal has not been met and little to no progress towards target.",
            },
            {
              code: "not-attainable",
              display: "Not Attainable",
              definition: "The goal is not possible to be met.",
            },
          ],
        },
      ],
    };
  }

  static async getTaskCodeValueSet() {
    return {
      resourceType: "CodeSystem",
      id: "task-code",
      meta: {
        lastUpdated: "2019-11-01T09:29:23.356+11:00",
        profile: [
          "http://hl7.org/fhir/StructureDefinition/shareablecodesystem",
        ],
      },
      text: {
        status: "generated",
        div: "<div>!-- Snipped for Brevity --></div>",
      },
      extension: [
        {
          url: "http://hl7.org/fhir/StructureDefinition/structuredefinition-wg",
          valueCode: "oo",
        },
        {
          url: "http://hl7.org/fhir/StructureDefinition/structuredefinition-standards-status",
          valueCode: "draft",
        },
        {
          url: "http://hl7.org/fhir/StructureDefinition/structuredefinition-fmm",
          valueInteger: 1,
        },
      ],
      url: "http://hl7.org/fhir/CodeSystem/task-code",
      identifier: [
        {
          system: "urn:ietf:rfc:3986",
          value: "urn:oid:2.16.840.1.113883.4.642.4.1397",
        },
      ],
      version: "4.0.1",
      name: "TaskCode",
      title: "Task Codes",
      status: "active",
      experimental: false,
      publisher: "FHIR Project team",
      contact: [
        {
          telecom: [
            {
              system: "url",
              value: "http://hl7.org/fhir",
            },
          ],
        },
      ],
      description:
        "Codes indicating the type of action that is expected to be performed",
      caseSensitive: true,
      valueSet: "http://hl7.org/fhir/ValueSet/task-code",
      content: "complete",
      concept: [
        {
          code: "approve",
          display: "Activate/approve the focal resource",
          definition:
            "Take what actions are needed to transition the focus resource from 'draft' to 'active' or 'in-progress', as appropriate for the resource type.  This may involve additing additional content, approval, validation, etc.",
        },
        {
          code: "fulfill",
          display: "Fulfill the focal request",
          definition:
            "Act to perform the actions defined in the focus request.  This might result in a 'more assertive' request (order for a plan or proposal, filler order for a placer order), but is intend to eventually result in events.  The degree of fulfillment requested might be limited by Task.restriction.",
        },
        {
          code: "abort",
          display: "Mark the focal resource as no longer active",
          definition:
            "Abort, cancel or withdraw the focal resource, as appropriate for the type of resource.",
        },
        {
          code: "replace",
          display: "Replace the focal resource with the input resource",
          definition:
            "Replace the focal resource with the specified input resource",
        },
        {
          code: "change",
          display: "Change the focal resource",
          definition:
            "Update the focal resource of the owning system to reflect the content specified as the Task.focus",
        },
        {
          code: "suspend",
          display: "Suspend the focal resource",
          definition:
            "Transition the focal resource from 'active' or 'in-progress' to 'suspended'",
        },
        {
          code: "resume",
          display: "Re-activate the focal resource",
          definition:
            "Transition the focal resource from 'suspended' to 'active' or 'in-progress' as appropriate for the resource type.",
        },
      ],
    };
  }

  static async getCareTeamCategoryValueSet() {
    return {
      resourceType: "ValueSet",
      id: "care-team-category",
      meta: {
        lastUpdated: "2019-11-01T09:29:23.356+11:00",
        profile: ["http://hl7.org/fhir/StructureDefinition/shareablevalueset"],
      },
      text: {
        status: "generated",
        div: "<div>!-- Snipped for Brevity --></div>",
      },
      extension: [
        {
          url: "http://hl7.org/fhir/StructureDefinition/structuredefinition-wg",
          valueCode: "pc",
        },
        {
          url: "http://hl7.org/fhir/StructureDefinition/structuredefinition-standards-status",
          valueCode: "draft",
        },
        {
          url: "http://hl7.org/fhir/StructureDefinition/structuredefinition-fmm",
          valueInteger: 1,
        },
      ],
      url: "http://hl7.org/fhir/ValueSet/care-team-category",
      identifier: [
        {
          system: "urn:ietf:rfc:3986",
          value: "urn:oid:2.16.840.1.113883.4.642.3.155",
        },
      ],
      version: "4.0.1",
      name: "CareTeamCategory",
      title: "Care Team category",
      status: "draft",
      experimental: false,
      date: "2019-11-01T09:29:23+11:00",
      publisher: "FHIR Project",
      contact: [
        {
          telecom: [
            {
              system: "url",
              value: "http://hl7.org/fhir",
            },
            {
              system: "email",
              value: "fhir@lists.hl7.org",
            },
          ],
        },
      ],
      description: "Indicates the type of care team.",
      copyright:
        "This content from LOINCÂ® is copyright Â© 1995 Regenstrief Institute, Inc. and the LOINC Committee, and available at no cost under the license at http://loinc.org/terms-of-use.",
      compose: {
        include: [
          {
            concept: [
              {
                code: "LA27975-4",
                display: "Event-focused care team",
              },
              {
                code: "LA27976-2",
                display: "Encounter-focused care team",
              },
              {
                code: "LA27977-0",
                display: "Episode of care-focused care team",
              },
              {
                code: "LA27978-8",
                display: "Condition-focused care team",
              },
              {
                code: "LA28865-6",
                display: "Longitudinal care-coordination focused care team",
              },
              {
                code: "LA28866-4",
                display:
                  "Home & Community Based Services (HCBS)-focused care team",
              },
              {
                code: "LA27980-4",
                display: "Clinical research-focused care team",
              },
              {
                code: "LA28867-2",
                display: "Public health-focused care team",
              },
            ],
            system: "http://loinc.org",
          },
        ],
      },
    };
  }
}
