import { AxiosHelper } from '../utils/AxiosHelper';
import { ErrorUtils } from '../utils/exceptions';
import {
  addInviteUrl,
  expireInviteUrl,
  modifyInviteUrl,
  resendInviteUrl,
  checkInviteExpirationUrl,
  confirmEmailUrl,
  resendVerifyEmailUrl,
  updateInviteUrl,
  invitesUrl
} from '../utils/constants';

const axios = AxiosHelper.newInstance();

export default class InvitesAPI {

  static async resendInvite(email) {
    await axios.post(resendInviteUrl, { email });
  }

  static async expireInvite(email) {
    await axios.post(expireInviteUrl, { email });
  }

  static async modifyInvite(invite) {
    await axios.post(modifyInviteUrl, invite);
  }

  static async updateInvite(invite) {
    await axios.post(updateInviteUrl, invite);
  }

  static async createInvite(invite) {
    await axios.post(addInviteUrl, invite);
  }

  static async verifyInviteExpiration(inviteId) {
    return await axios.get(`${checkInviteExpirationUrl}/${inviteId}`);
  }

  static async resendVerificationEmail(email) {
    await axios.put(resendVerifyEmailUrl, { email });
  }

  static async confirmInviteEmail(inviteId, email) {
    return await axios.post(confirmEmailUrl, {
      token: inviteId,
      email
    });
  }

  static async getInvitesWithStatus(status) {
    const result = await axios.get(
      invitesUrl,
      {
        params: {
          status,
        }
      }
    );
    return ErrorUtils.toEmptyArray(result);
  }

}
