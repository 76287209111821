import './home.css';
import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import patientSearch from './patient-search.png';
import referralManagement from './referral-management.png';
import orgManagement from './org-management.png';
import Layout from '../Layout';
import { Link } from 'react-router-dom';
import { lastUrlKey } from '../../utils/constants';
import { useContext } from 'react';
import { AuthContext } from '../../AuthProvider';
import { DocumentCatalogsStore } from '../PatientViewer/Documents/DocumentCatalogsStore';
import { ReferralsAPI } from '../../services';
import auth from '../../utils/auth';
import { ErrorSnackbar } from '../common';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  mainMenu: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)'
  },
}));

export default function Home() {
  const role = useContext(AuthContext)

  const [catalogsLoaded, setCatalogsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [showPatientSearch, setShowPatientSearch] = useState(true);
  const [showReferralManagement, setShowReferralManagement] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        await ReferralsAPI.provisionUser(auth);
        await DocumentCatalogsStore.init();
        setCatalogsLoaded(true);
        if (await auth.orgIsCommunity()) {
          setShowPatientSearch(false)
          setShowReferralManagement(true);
        }
      } catch (error) {
        setError(error.message);
      }
    })();
  }, []);

  useEffect(() => {
    console.log('DEBUG role: ', role)
  }, [role])
  

  const classes = useStyles();

  const lastUrl = localStorage.getItem(lastUrlKey);
  localStorage.removeItem(lastUrlKey);
  if (lastUrl) {
    window.location.href = lastUrl;
    return null;
  }

  if (error) {
    return <ErrorSnackbar errorMessage={error} />;
  }
  if (!catalogsLoaded) {
    return <Fragment />;
  }

  return (
    <Layout className={classes.root}>
      <Grid container className={classes.mainMenu} justify="center" spacing={10} xs={12}>
        {
          showPatientSearch &&
          <Grid item>
            <div className="button-container">
              <Link to="/patients">Patient Search</Link>
              <img src={patientSearch} alt="Patient Search"/>
            </div>
          </Grid>
        }
        {
          showReferralManagement &&
          <Grid item>
            <div className="button-container">
              <Link to="/referrals">Referral Management</Link>
              <img src={referralManagement} alt="Referral Management"/>
            </div>
          </Grid>
        }
        {
          role?.userinfo?.application_roles?.includes("Manager") &&
          <Grid item>
            <div className="button-container">
              <Link to="/org">Org Management</Link>
              <img src={orgManagement} alt="Org Management"/>
            </div>
          </Grid>
        }
      </Grid>
      {
        error &&
        <ErrorSnackbar errorMessage={error}/>
      }
    </Layout>
  );
}
