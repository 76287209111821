import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import ReferralsList from './ReferralsList';
import styles from '../../../styles/viewer.module.css';
import ReferralsAPI from '../../../services/ReferralsAPI';
import { Context } from '../../../store/AppStore';
import { ReferralActions, ReferralActionButton } from './ReferralActions';
import PreventionLinkReferralDialog from './PreventionLinkReferralDialog';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../theme';
import { Container } from '@material-ui/core';
import auth from '../../../utils/auth';
import { patientDataStore } from '../../../store/PatientDataStore';
import ReferralDocumentSelectionDialogContainer from "./ReferralDocumentSelectionDialogContainer";

const FORM_UUID = process.env.REACT_APP_REFERRAL_FORM_UUID;

const PreventionLinkReferralsViewer = (props) => {
  const history = useHistory()
  const [, dispatch] = useContext(Context)
  const { patientId } = useParams()
  const [ referrals, setReferrals ] = useState(null);
  const [ referralDialogOpen, setReferralDialogOpen ] = useState(false);
  const [ referralDialogSize, setReferralDialogSize ] = useState(null);
  const [ serviceCategories, setServiceCategories ] = useState(null);
  const [ organizationNames, setOrganizationNames ] = useState(null);
  const [ patientData, setPatientData ] = useState(null);
  const [ error, setError ] = useState('');
  const [ submitError, setSubmitError ] = useState(null);
  const [ showBackdrop, setShowBackdrop ] = useState(false);
  const [ referralDefaults, setReferralDefaults] = useState(null);
  const [isReferralDocumentDialogOpen, setIsReferralDocumentDialogOpen] = useState(false);

  const showAllReferrals = props.showAllReferrals && auth.canSeeAllReferrals;
  console.log("Bug #15396 - PreventionLinkReferralsViewer:42", {
    referrals,
    serviceCategories,
    organizationNames,
    referralDefaults,
    "props.showAllReferrals": props.showAllReferrals,
    "auth.canSeeAllReferrals": auth.canSeeAllReferrals,
    showAllReferrals,
    referralDialogSize,
  });

  const loadReferralData = useCallback(async () => {
    try {
      await ReferralsAPI.provisionUser(auth);
      if (!showAllReferrals) {
        loadReferralCatalogs();
      }
      setReferrals(
        await ReferralsAPI.getReferrals({
          patientId: props.showAllReferrals ? undefined : patientId,
          direction: (await auth.orgIsCommunity()) ? "incoming" : undefined,
        })
      );
      setReferralDefaults(
        await ReferralsAPI.getPreventionLinkReferralDefaults()
      );
    } catch (error) {
      setError(error.message);
    }
  }, [patientId, props.showAllReferrals, showAllReferrals]);

  const loadReferralCatalogs = () => {
    Promise.all([
      new Promise(async (resolve) => {
        resolve(await ReferralsAPI.getServiceCategories(auth));
      }),
      new Promise(async (resolve) => {
        resolve(await ReferralsAPI.getOrganizations(auth));
      }),
    ])
      .then(([categories, organizations]) => {
        setServiceCategories(categories)
        setOrganizationNames(organizations)
      })
    ;
  }

  const handleReferralSave = async (formEvent) => {
    const newReferral =
      formEvent?.type === "form-submitted" && formEvent?.payload
        ? formEvent.payload
        : null;

    if (!newReferral) {
      return;
    }
    const linkedDocuments = newReferral["linked_documents"];
    if (linkedDocuments) {
      delete newReferral["linked_documents"];
    }
    try {
      setShowBackdrop(true);
      const { referralId } = await ReferralsAPI.createPreventionLinkReferral(
        patientId,
        newReferral
      );
      for (const retrievalParameters of linkedDocuments || []) {
        await ReferralsAPI.linkDocument(referralId, retrievalParameters);
      }
      await loadReferralData();
      setShowBackdrop(false);
      setReferralDialogOpen(false);
      history.push(`/patient/search/${patientId}/info/1/referrals/${referralId}`);
      return '';
    }
    catch (error) {
      setSubmitError(error);
      setShowBackdrop(false);
      return error.message;
    }
  }

  const messageListener = useRef(
    async (event) => {
      const formEvent = event.data;
      if (formEvent.type === 'form-submitted') {
        await handleReferralSave(formEvent);
      }
      if (formEvent.type === 'link-documents') {
        setIsReferralDocumentDialogOpen(true);
      }
    }
  );

  useEffect(
    () => {
      loadReferralData()
    },
    [loadReferralData]
  );

  useEffect(
    () => {
      const listener = messageListener.current;
      ReferralsAPI.getReferralFormSize(FORM_UUID)
        .then(size => {
          window.addEventListener('message', listener);
          setReferralDialogSize(size)
        })
      ;
      return () => {
        window.removeEventListener('message', listener);
      }
    },
    []
  );

  useEffect(
    () => {
      patientDataStore.getPatientData(patientId).then(patientData => setPatientData(patientData));
    },
    [patientId]
  );

  const handleReferralClick = (referral) => {
    if (referral) {
      dispatch({ type: 'UPDATE_REFERRAL', payload: referral });
      if (props.showAllReferrals) {
        history.push(`/referrals/${referral.referralId}`);
      }
      else {
        history.push(`/patient/search/${patientId}/info/1/referrals/${referral.referralId}`);
      }
    }
  };

  const handleNewReferralClick = () => {
    setReferralDialogOpen(true);
  }

  const handleReferralDialogClosed = () => {
    setReferralDialogOpen(false);
  }

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Container className={styles.errorContainer}>
          {error}
        </Container>
      </ThemeProvider>
    )
  }
  return(
    <Fragment>
      {
        serviceCategories && organizationNames && referralDialogSize && patientData && referralDefaults &&
        <PreventionLinkReferralDialog
          open={referralDialogOpen}
          handleClose={handleReferralDialogClosed}
          formUUID={FORM_UUID}
          size={referralDialogSize}
          patientData={patientData}
          showBackdrop={showBackdrop}
          submitError={submitError}
          referralDefaults={referralDefaults}
        />
      }
      {
        isReferralDocumentDialogOpen &&
        <ReferralDocumentSelectionDialogContainer
          open={isReferralDocumentDialogOpen}
          handleSave={() => {}}
          handleClose={() => {
            setIsReferralDocumentDialogOpen(false);
          }}
          patientId={patientId}
        />
      }
      <div className={styles.documentViewer}>
        <ReferralsList
          showAllReferrals={showAllReferrals}
          referrals={referrals}
          onReferralClick={handleReferralClick}
          ReferralActions={
            (
              referrals
              && serviceCategories
              && organizationNames
              && referralDefaults
              && !showAllReferrals
              && referralDialogSize
            )
              ? (
                <ReferralActions>
                  <ReferralActionButton text="Create Referral" action={handleNewReferralClick}/>
                </ReferralActions>
              )
              : null
          }
        />
      </div>
    </Fragment>
  )
}

export default withRouter(PreventionLinkReferralsViewer);
