import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function LoadingButton(props) {
  const classes = useStyles();

  const handleButtonClick = async () => {
    await props.onClick();
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          variant={props.variant || "contained"}
          color={props.color || "primary"}
          disabled={props.disabled || props.loading}
          onClick={handleButtonClick}
        >
          {props.children}
        </Button>
        {
          props.loading &&
          <CircularProgress size={24} className={classes.buttonProgress} />
        }
      </div>
    </div>
  );
}

LoadingButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export { LoadingButton };
