import React, { useRef, useState } from "react";
import { CarePlanDetails } from "./CarePlanDetails";
import { CarePlanSelector } from "./CarePlanSelector";
import { usePatientCarePlanBundle } from "../utils/usePatientCarePlanBundle";

export function CarePlanViewer({ patientData }) {
  const [currentCarePlan, setCurrentCarePlan] = useState(null);
  const previousCarePlan = useRef(null);
  const { carePlanBundle, loading, error } = usePatientCarePlanBundle(
    patientData.id,
    setCurrentCarePlan
  );

  const onCarePlanChange = (carePlan) => {
    if (
      !currentCarePlan ||
      !carePlan ||
      currentCarePlan !== previousCarePlan.current
    ) {
      previousCarePlan.current = carePlan;
      setCurrentCarePlan(carePlan);
    }
  };

  return (
    <>
      <CarePlanSelector
        loading={loading}
        error={error}
        carePlanBundle={carePlanBundle}
        onCarePlanChange={onCarePlanChange}
      />
      <CarePlanDetails carePlan={currentCarePlan} />
    </>
  );
}
