import React from 'react'
import styles from '../../../styles/viewer.module.css'

const DocumentData = ({
  document = {
    author: 'N/A',
    title: '',
  }
}) => {
  if(document) {
    return(
      <div className={styles.documentData}>
        <div className={styles.documentTitle}>
          {document.title}
        </div>
        <div>
          Author: {document.author} / {document.custodian}
        </div>
        {document.date &&
          <div>
            Date: {document.date}
          </div>
        }
      </div>
    )
  } else {
    return(
      <div className={styles.documentData}>
        <div>
          Metadata Not Available
        </div>
      </div>
    )
  }
}

export default DocumentData
