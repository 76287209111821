import React from 'react';
import { Button, FormHelperText, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    color: 'grey',
    textAlign: 'center'
  },
  button: {
    width: '100%'
  }
}));

const EmailFormConfirm = ({ checkEmailToken, handleNext, setDisableButton, disableButton}) => {
  const classes = useStyles();

    return (
      <Grid container spacing={4}>
        <Grid item container justify="center">
          <h3 className={classes.title}>Please, confirm your email.</h3>
        </Grid>
        <Grid item xs={12} container justify="center">
          <Formik
            initialValues={{email: ''}}
            validate={values => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              } else {
                setDisableButton(false)
              }

              return errors
            }}
            onSubmit={async (values, { setSubmitting, setFieldError, resetForm }) => {
              const isConfirm = await checkEmailToken(values.email)
              if(isConfirm){
                handleNext()
                setSubmitting(false);
                setDisableButton(true);
              } else {
                setDisableButton(true);
                setFieldError("email", "Oh!, It seems that is not a correct email")
                setTimeout(() => {
                  resetForm()
                }, 2000)
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit
            }) => (
              <Grid container justify="center" spacing={4} direction="column" alignItems="center">
                <Grid item container justify="center" xs={12} md={5}>
                  <TextField
                    id="email"
                    label="Email"
                    name="email"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    variant="outlined"
                  />
                  {errors.email && touched.email && <FormHelperText error>{errors.email}</FormHelperText>}
                </Grid>
                <Grid item container justify="center" xs={12} md={5}>
                  <Button
                    size="small"
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={disableButton}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            )}
          </Formik>
        </Grid>
      </Grid>
    )
  }

  export default EmailFormConfirm
