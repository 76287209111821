import React, { useEffect, useState } from "react";
import _ from "lodash";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export function FormList({ field, getValues, classes }) {
  const getValuesResult = getValues ? getValues() : [];
  const [values, setValues] = useState(
    getValuesResult instanceof Promise ? [] : getValuesResult
  );

  useEffect(() => {
    if (getValuesResult instanceof Promise) {
      (async () => {
        const innerGetValues = await getValuesResult;
        setValues(innerGetValues());
      })();
    }
  }, []);

  const fieldName = _.kebabCase(field.name);
  const labelId = `${fieldName}-label`;
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={labelId}>{field.name}</InputLabel>
      <Select
        name={fieldName}
        labelId={labelId}
        value={field.value}
        error={!field.optional && field.value === ""}
        onChange={field.changeHandler}
        defaultValue=""
      >
        <MenuItem key={`${fieldName}-option-empty`} value="">
          [Any {field.name}]
        </MenuItem>
        {values.map((option, index) => {
          const value = option?.value || option;
          const display = option?.display || option;
          return (
            <MenuItem
              key={`${fieldName}-option-${value}-${index}`}
              value={value}
            >
              {display}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
