import React, { useContext, useEffect, useState } from "react";
import { get } from "lodash";
import ReferralDocumentList from './ReferralDocumentList';
import { DocumentUtils } from '../../../services';
import { Context } from '../../../store/AppStore';
import { useHistory } from 'react-router-dom';
import { getReferralPatientId } from "../../../services/ReferralStates";
import { DocumentCatalogsStore } from "../Documents/DocumentCatalogsStore";

const TYPE_TO_MIMETYPES = {
  'file-pdf': ["application/pdf"],
  "file": ["text/plain"] ,
  "file-alt": ["application/json"] ,
  "file-image": ["image/jpeg", "image/tiff", "image/tif", "image/png"],
};

export default function ReferralDocuments({ referral, patientId, onDocumentDelete }) {

  const history = useHistory()
  const dispatch = useContext(Context)[1];
  const [catalogs, setCatalogs] = useState(null);

  useEffect(
    () => {
      (async () => {
        setCatalogs(await DocumentCatalogsStore.getDocumentCatalogs());
      })();
    }
  )

  const handleDocument = async (data) => {
    if( data ) {
      dispatch({ type: 'UPDATE_DOCUMENT_DATA', payload: data})
      const contentType = get(data, "document.content[0].attachment.contentType");
      const dataType =
        Object.entries(TYPE_TO_MIMETYPES).find(([_, mimeTypes]) =>
          mimeTypes.some((mimeType) => mimeType === contentType)
        )?.[0] || data?.iconName || "file-alt";
      history.push(`/patient/${patientId || getReferralPatientId(referral)}/file/${data.document.id}/${dataType}`);
    }
  }

  if (!catalogs) {
    return null;
  }

  return (
    <>
      <h1 style={{
        marginTop: '20px',
        marginBottom: '10px',
        fontSize: '18px',
        fontWeight: 'bold',
      }}>
        Documents
      </h1>
      <ReferralDocumentList
        data={
          referral.linkedDocuments.map(
            document => DocumentUtils.getDisplayInfo(document, catalogs)
          )
        }
        showDocument={handleDocument}
        onDocumentDelete={onDocumentDelete}
      />
    </>
  );
}
