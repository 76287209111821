import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { getUniqueSortedValues } from "../../utils/filter-utils";
import { FormList } from "../../controls";
import { useForm, useOptionalField } from "../../../../../utils/form-utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));

const EMPTY_VALUES = {
  category: "",
  managingOrganization: "",
  status: "",
};

export function CareTeamFilterDialog({
  careTeams,
  open,
  onClose,
  onFilterSelected,
}) {
  const classes = useStyles();
  const form = useForm();
  const category = useOptionalField(form, "Category", EMPTY_VALUES.category);
  const managingOrganization = useOptionalField(
    form,
    "Managing Organization",
    EMPTY_VALUES.managingOrganization
  );
  const status = useOptionalField(form, "Status", EMPTY_VALUES.status);

  const setFilter = () => {
    onFilterSelected({
      managingOrganization: managingOrganization.value,
      category: category.value,
      status: status.value,
    });
  };

  const reset = () => {
    form.reset();
  };

  const cancel = () => {
    reset();
    onFilterSelected(EMPTY_VALUES);
    onClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Filter Care Teams By</DialogTitle>
        <DialogContent>
          <FormGroup row>
            <FormList
              field={managingOrganization}
              getValues={() =>
                getUniqueSortedValues(careTeams, (careTeam) =>
                  careTeam?.getManagingOrganization()
                )
              }
              classes={classes}
            />
          </FormGroup>
          <FormGroup row>
            <FormList
              field={category}
              getValues={() =>
                getUniqueSortedValues(careTeams, (careTeam) =>
                  careTeam?.getCategory()
                )
              }
              classes={classes}
            />
          </FormGroup>
          <FormGroup row>
            <FormList
              field={status}
              getValues={() =>
                getUniqueSortedValues(careTeams, (careTeam) =>
                  careTeam?.getStatus()
                )
              }
              classes={classes}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary">
            Cancel
          </Button>
          <Button onClick={setFilter} color="primary">
            Filter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
