import React from "react";
import styles from "../../../styles/viewer.module.css";
import { urgentStyle } from "./urgentStyle";
import { yesNoRenderer } from "../value-renderers";
import { getReferee, getReferrer } from "./utils";

const FhirReferralData = ({ referral, organizations }) => {
  if (referral) {
    return (
      <div className={styles.fhirDocumentData}>
        <div>
          <b>Referrer</b>: {getReferrer(referral)?.display}
        </div>
        <div>
          <b>Referee</b>: {getReferee(referral)?.display}
        </div>
        <div>
          <b>Urgent</b>: &nbsp;
          <span style={urgentStyle(referral.urgent)}>
            {yesNoRenderer(referral.urgent)}
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.fhirDocumentData}>
        <div>Metadata Not Available</div>
      </div>
    );
  }
};

export default FhirReferralData;
