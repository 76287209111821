import moment from "moment";

export const getDateTime = (referral) => {
  const formattedDateTime = referral?.occurrenceDateTime
    ? moment(referral.occurrenceDateTime)
    : null;
  return formattedDateTime?.format("LLL");
};

export const getIdFromReference = (reference) => {
  return reference.split("/")[1];
};

const getOrganizationFromReference = (reference, organizations, category) => {
  const organizationId = reference ? getIdFromReference(reference) : null;

  if (organizationId) {
    const organization = organizations.find(
      (org) => org?.id === organizationId
    );
    if (organization) {
      return organization;
    }
  }

  return null;
};

// export const getReferrer = (referral, organizations) => {
//   return getOrganizationFromReference(
//     referral?.requester?.reference,
//     organizations
//   );
// };

export const getReferrer = (referral) => {
  return referral?.requester ?? null;
};

// export const getReferee = (referral, organizations) => {
//   return getOrganizationFromReference(
//     referral?.performer?.[0]?.reference,
//     organizations
//   );
// };

export const getReferee = (referral) => {
  const found = referral?.performer?.find((x) => {
    if (x?.reference) {
      return (
        x?.reference.split("/")?.[0] === "Organization" ||
        x?.reference.split("/")?.[0] === "PractitionerRole"
      );
    } else if (x?.display) {
      return true;
    }
  });
  return found ?? null;
};

export const getDescription = (referral) => {
  let description = "";
  if (referral?.note?.[0]?.text) {
    description = referral.note[0].text;
  }
  return description;
};

export const formattedPatientInfo = (patient) => {
  if (!patient) return null;
  const name = patient?.name?.[0]?.given?.[0] ? patient.name[0].given[0] : "";
  const mrn = patient?.identifier
    ? patient.identifier.find((i) => i?.system?.includes("mrn"))?.value
    : null;
  const formattedPatient = {
    name,
    id: mrn,
    birthDate: patient?.birthDate,
    fhirPatient: patient,
  };
  return formattedPatient;
};
