import React from 'react';
import Store from './store/AppStore';
import { Router } from './Router';
import AuthProvider from './AuthProvider';

function App() {
  return (
    <AuthProvider>
      <Store>
        <Router />
      </Store>
    </AuthProvider>
  )
}

export default App
