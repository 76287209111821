import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { get } from 'lodash';
import {
  userNameRenderer,
  commentRenderer,
  dateTimeRenderer,
  facilityRenderer,
  statusWithDateRenderer,
  ReferralIdListRenderer,
} from "../value-renderers";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgb(0, 97, 129)',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function newRow (field, title, renderer) {
  return {
    title,
    renderValue(obj, users, onReferralSelect) {
      return renderer(get(obj, field), obj, users, onReferralSelect)
    }
  }
}

const userRenderer = (userId, _, users) => {
  return userNameRenderer(users?.find(user => user.id === userId));
}

const rows = [
  newRow('createTs', 'Created On', dateTimeRenderer),
  newRow('referringMintUserId', 'Created By', userRenderer),
  newRow('status', 'Status', statusWithDateRenderer),
  newRow('description', 'Description', commentRenderer),
  newRow('facility', 'Receiving Facility', facilityRenderer),
  newRow('assignedMintUserId', 'Assigned To', userRenderer),
  newRow('children', 'Children', (children, referral, users, onReferralSelect) => (
    <ReferralIdListRenderer referralIds={children?.map(child => child.referralId)} onReferralSelect={onReferralSelect}/>
  )),
  newRow('parentReferralId', 'Parent', (parentReferralId, referral, users, onReferralSelect) => (
    <ReferralIdListRenderer referralIds={[parentReferralId]} onReferralSelect={onReferralSelect}/>
  )),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function ReferralDetailTable({ referral, users, onReferralSelect }) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table size="small" className={classes.table} aria-label="Referral Details Table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Referral Detail</StyledTableCell>
            <StyledTableCell>&nbsp;</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.title}>
              <StyledTableCell component="th" scope="row">
                {row.title}
              </StyledTableCell>
              <StyledTableCell>
                {row.renderValue(referral, users, onReferralSelect)}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
