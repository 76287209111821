import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFileImage,
  faFileAudio,
  faFileVideo,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFile,
  faFileCode,
  faFileArchive,
} from '@fortawesome/free-solid-svg-icons';

library.add(faFileImage, faFileAudio, faFileVideo, faFilePdf, faFileWord, faFileExcel,
  faFilePowerpoint, faFile, faFileCode, faFileArchive);

export function getFontAwesomeIconFromMimeType(mimeType) {
  if (!mimeType) {
    return null;
  }
  // List of official MIME Types:
  // http://www.iana.org/assignments/media-types/media-types.xhtml
  const icon_classes = {
    // Media
    image: "file-image",
    audio: "file-audio",
    video: "file-video",
    // Documents
    "application/pdf": "file-pdf",
    "application/msword": "file-word",
    "application/vnd.ms-word": "file-word",
    "application/vnd.oasis.opendocument.text": "file-word",
    "application/vnd.openxmlformats-officedocument.wordprocessingml":
      "file-word",
    "application/vnd.ms-excel": "file-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml":
      "file-excel",
    "application/vnd.oasis.opendocument.spreadsheet": "file-excel",
    "application/vnd.ms-powerpoint": "file-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml":
      "file-powerpoint",
    "application/vnd.oasis.opendocument.presentation": "file-powerpoint",
    "text/plain": "file-text",
    "text/html": "file-code",
    "application/json": "file-code",
    "application/xml": "file-code",
    "text/xml": "file-code",
    // Archives
    "application/gzip": "file-archive",
    "application/zip": "file-archive"
  };

  for (let key in icon_classes) {
    if (icon_classes.hasOwnProperty(key)) {
      if (mimeType.search(key) === 0) {
        // Found it
        return icon_classes[key];
      }
    }
    else {
      return "file";
    }
  }
}
