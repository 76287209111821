import React, { Fragment, useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import styles from '../../../styles/viewer.module.css';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment'
import MaterialTable from 'material-table';
import { tableIcons } from '../../PatientViewer/table-icons';
import {Button} from '@material-ui/core';
import InviteForm from '../../InviteForm';
import Tooltip from '@material-ui/core/Tooltip';
import MenuIcon from '@material-ui/icons/Menu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grid from '@material-ui/core/Grid';
import UpdateInfoModal from '../../UpdateInfoModal';
import InvitesAPI from '../../../services/InvitesAPI';

const formatDate = (date) => {
  return moment(date).format("MM-DD-YYYY");
}

const formatTime = (date) => {
  return moment(date).format("hh:mm A");
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

function maskNumber(num){
  const country = num.slice(0,1)
  const lada = num.slice(1,3)
  const beforeDash = num.slice(3, 7)
  const afterDash = num.slice(7, 11)
  return `(${country} ${lada}) ${beforeDash} - ${afterDash}`
}

const InviteTable = () => {
  const [ invites, setInvites ] = useState([]);
  const [ error, setError ] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState("")
  const [openMessage, setOpenMessage] = useState(false)
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false)
  const [tempInfo, setTempInfo] = useState({})
  const anchorRef = useRef([]);
  const prevOpen = useRef(open);

  useEffect(() => {
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    loadInfo()
  }, [])

  const loadInfo = async () => {
    try{
      const res = await InvitesAPI.getInvitesWithStatus()
      if(res){
        setInvites(res);
        setError(false)
      }
    } catch(e){
      setError(true)
      throw new Error('Fail try to load info', e)
    }

    return () => {
      setInvites([])
      setMessage("")
      setTempInfo({})
    }
  }

  const resendOrExpire = async (type) => {
    try {
      if(type === 'resend'){
        await InvitesAPI.resendInvite(tempInfo.email);
        setError(false)
        setOpenMessage(true)
        setMessage("Resend Invitation successfully")
      } else if( type === 'expire'){
        await InvitesAPI.expireInvite(tempInfo.email);
        setError(false)
        setOpenMessage(true)
        setMessage("Expire successfully")
      }
      await loadInfo()
    } catch (e){
      setError(true)
      setOpenMessage(true)
      setMessage(e?.response?.data || e.message)
    }

  }

  const handleClose = async (event) => {
    switch(event.target.innerText){
      case 'Edit' : setOpenUpdate(true); break
      case 'Resend invitation' : await resendOrExpire('resend'); break
      case 'Expire invitation' : await resendOrExpire('expire'); break
      default:
    }
    anchorRef.current = []
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

    const handleError = () => {
      setOpenMessage(false)
    }

    const handleInvite = async (info) => {
      try {
        await InvitesAPI.createInvite(info);
        setError(false)
        setOpenMessage(true)
        setMessage("Invited successfully")
        await loadInfo()
      } catch(e) {
        setError(true)
        setOpenMessage(true)
        setMessage(e.response?.data || e.message)
      }
    };

    const sendInfo = async (info) => {
      try {
        await InvitesAPI.modifyInvite(info)
        await loadInfo()
        setError(false)
        setOpenMessage(true)
        setMessage("Edit Invite successfully")

      } catch (e) {
        setError(true)
        setOpenMessage(true)
        setMessage("Something failed editing the invite, try again later.", e)
      }
    }

    return(
      <Fragment>
        <div className={styles.documentViewer}>
          <Button variant="contained" color="primary" onClick={() => setModalOpen(true)} style={{margin: 12, position: 'absolute', zIndex: 1}}>
            Invite User
          </Button>
            <MaterialTable
              title=""
              columns={[
                {
                  title: 'Email',
                  field: 'email',
                  cellStyle: { textAlign: 'center' },
                  headerStyle: { textAlign: 'center' }
                },
                {
                  title: 'First Name',
                  field: 'firstName',
                  cellStyle: { textAlign: 'center' },
                  headerStyle: { textAlign: 'center' }
                },
                {
                  title: 'Last Name',
                  field: 'lastName',
                  cellStyle: { textAlign: 'center' },
                  headerStyle: { textAlign: 'center' }
                },
                {
                  title: 'Cellphone',
                  field: 'cellPhone',
                  cellStyle: { textAlign: 'center' },
                  headerStyle: { textAlign: 'center' },
                  render: rowData =>  <p>{maskNumber(rowData.cellPhone)}</p>
                },
                {
                  title: 'Expire on',
                  field: 'expireOn',
                  cellStyle: { textAlign: 'center' },
                  headerStyle: { textAlign: 'center' },
                  render: rowData => <>
                    <div style={{ width: '85px' }}>
                      {formatDate(rowData.expireOn)}
                    </div>
                    <div>
                      {formatTime(rowData.expireOn)}
                    </div>
                  </>
                },
                {
                  title: 'Role',
                  field: 'roles',
                  cellStyle: { textAlign: 'center' },
                  headerStyle: { textAlign: 'center' }
                },
                {
                  title: 'Created on',
                  field: 'createdOn',
                  cellStyle: { textAlign: 'center' },
                  headerStyle: { textAlign: 'center' },
                  render: rowData => <>
                    <div style={{ width: '85px' }}>
                      {formatDate(rowData.createdOn)}
                    </div>
                    <div>
                      {formatTime(rowData.createdOn)}
                    </div>
                  </>
                },
                {
                  title: 'associated email',
                  field: 'associatedEmail',
                  cellStyle: { textAlign: 'center' },
                  headerStyle: { textAlign: 'center' }
                },
                {
                  title: 'token',
                  field: 'token',
                  cellStyle: { textAlign: 'center' },
                  headerStyle: { textAlign: 'center' },
                  render: rowData =>
                    <Tooltip title={rowData.token}>
                      <p onClick={() => {
                        navigator.clipboard.writeText(rowData.token)
                        setOpenMessage(true)
                        setMessage("Copy to clipboard!")
                      }}>{truncateString(rowData.token, 10)}</p>
                    </Tooltip>
                },
              ]}
              icons={tableIcons}
              data={invites}
              options={{
                emptyRowsWhenPaging: true,
                minBodyHeight: '100%',
                maxBodyHeight: '100%',
                padding: 'dense',
                pageSize: 10,
                search: true,
                actionsColumnIndex: -1
              }}
              actions={[
                {
                  icon: 'menu',
                  tooltip: 'Menu',
                  onClick: (event, rowData) => {
                    setTempInfo({
                      tableId: rowData.tableData.id,
                      firstName: rowData.firstName,
                      lastName: rowData.lastName,
                      roles: rowData.roles,
                      email: rowData.email,
                      expireOn: rowData.expireOn,
                      cellPhone: rowData.cellPhone
                    })
                    setOpen((prevOpen) => !prevOpen);
                  }
                }
              ]}
              components={{
                Action: props => {
                  return (
                    <Grid>
                      <MenuIcon
                        id={props.data.tableData.id}
                        ref={el => anchorRef.current[props.data.tableData.id] = el}
                        onClick={(event) => props.action.onClick(event, props.data)}
                      />
                      <Popper
                        open={open}
                        anchorEl={anchorRef.current[tempInfo.tableId]}
                        role={undefined}
                        transition
                        disablePortal
                      >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                <MenuItem value="Edit" onClick={handleClose}>Edit</MenuItem>
                                <MenuItem value="Resend invitation" onClick={handleClose}>Resend invitation</MenuItem>
                                <MenuItem value="Expire invitation" onClick={handleClose}>Expire invitation</MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                    </Grid>
                  )
                }
              }}
            />
          <UpdateInfoModal open={openUpdate} sendInfo={sendInfo} setOpen={() => setOpenUpdate(false)} updateType='invites' tempInfo={tempInfo}/>
          <InviteForm open={modalOpen} setOpen={setModalOpen} handleInvite={handleInvite} />
          <Snackbar open={openMessage} autoHideDuration={2000} onClose={handleError} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert onClose={handleError} severity={error ? 'error' : 'success'}>
              {message}
            </Alert>
          </Snackbar>
        </div>
      </Fragment>
    )
  }

  export default withRouter(InviteTable);
