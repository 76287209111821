import React, { useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { EmbeddedForm } from '../Forms/EmbeddedForm';
import { referralsFormRendererUrl } from '../../../utils/constants';
import { get, isEmpty, pick } from 'lodash';
import ReferralCard from './ReferralCard';
import { ReferralUtils } from '../../../services';
import { LoadingButton } from '../../common';

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflow: 'hidden'
  },
  programsSelect: {
    margin: theme.spacing(1),
    minWidth: 850,
    maxWidth: 850,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  submitProgramsButton: {
    marginTop: theme.spacing(2.8),
  }
}));

const ProgramTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

const programList = [
  {
    name: 'Cardiac Rehabilitation',
    description: 'Cardiac rehab (CR) is a comprehensive secondary prevention program designed to improve cardiovascular health following a cardiac-related event or procedure. The program consists of 36 one-hour sessions that include team-based, supervised exercise training, education and skills development for heart-healthy living, and counseling on stress and other psychosocial factors. CR programs are certified by the American Association of Cardiovascular & Pulmonary Rehabilitation.',
  },
  {
    name: 'Medication Therapy Management',
    description: 'Medication therapy management (MTM) services are provided by advanced trained pharmacists who will review and assess the participant’s medications (prescription, OTC, herbals and supplements). A comprehensive complete medication list will be generated. Pharmacists will identify potential or actual medication related problems and health care risks and provide or recommend solutions to the healthcare providers that help mitigate those risks. Documentation will be provided to the primary care providers based on the pharmacist’s assessments of the participant.',
  },
  {
    name: 'National Diabetes Prevention Program',
    description: 'A CDC-recognized lifestyle change program consisting of 26 in-person one-hour sessions (number of sessions may vary slightly depending on the program). The program uses a research-based curriculum and trained coaches to provide evidence-based information about weight loss, healthy eating, physical activity, and other important lifestyle changes. Sessions are held in an interactive group setting. The program is proven to cut the risk of type 2 diabetes by 50% and lower the risk of heart attack and stroke.',
  },
  {
    name: 'Telehealth National Diabetes Prevention Program',
    description: 'A CDC-recognized lifestyle change program consisting of 26 in-person one-hour sessions (number of sessions may vary slightly depending on the program). The program uses a research-based curriculum and trained coaches to provide evidence-based information about weight loss, healthy eating, physical activity, and other important lifestyle changes. Sessions are held in an interactive real-time format with a live instructor. The program is proven to cut the risk of type 2 diabetes by 50% and lower the risk of heart attack and stroke.',
  },
  {
    name: 'Telehealth Diabetes Self-Management Education and Support (DSMES)',
    description: 'An evidence-based service that helps persons with type 2 diabetes effectively self-manage their diabetes and cope with the chronic disease. DSMES interventions focus on 1) medication adherence; 2) healthy eating; 3) blood glucose self-monitoring; 4) physical activity; 5) reducing risks for diabetes complications; and 6) healthy coping and problem solving. DSMES sessions are held in an interactive real-time online format with a Certified Diabetes Care and Education Specialist (CDCES) and other licensed health professionals. DSMES services are recognized by the American Diabetes Association or accredited by the Association of Diabetes Care and Education Specialists (ADCES).',
  },
  {
    name: 'Remote Patient Monitoring',
    description: 'This program integrates the use of a mobile application, designed with the primary objective of enabling services for the management of Hypertension and Cholesterol by using the mobile application.  The mobile app will enable participants enrolled in the program, to monitor and share data from Health IT devices that collect blood pressure, physical activities/exercises, lipid panel (e.g. HDL, LDL levels) and nutrition.',
  },
];

function PreventionLinkReferralDialog(props) {

  const classes = useStyles();
  const { formUUID, size, referralDefaults } = props;
  const [ programNames, setProgramNames ] = useState([]);
  const [ submitButtonPressed, setSubmitButtonPressed ] = useState(false);
  const [ formLoaded, setFormLoaded ] = useState(false);

  const handleProgramsChange = (event) => {
    setProgramNames(event.target.value);
  };

  const reset = () => {
    setProgramNames([]);
    setSubmitButtonPressed(false);
    setFormLoaded(false);
  }

  const loadForm = () => {
    setSubmitButtonPressed(true);
  }

  const handleFormLoaded = (preventionLinkIframe) => {
    ReferralUtils.setPreventionLinkIframe(preventionLinkIframe);
    setFormLoaded(true);
  }

  const referral = useMemo(
    () => ReferralUtils.patientDataToReferral(props.patientData, referralDefaults),
    [props.patientData]
  );

  return (
    <div>
      <Dialog
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
        maxWidth={false}
        open={props.open}
        onClose={props.handleClose}
        onEnter={reset}
      >
        <DialogTitle id="form-dialog-title">New PreventionLink Referral</DialogTitle>
        <DialogContent>
          <ReferralCard referral={referral} />
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <FormControl className={classes.programsSelect}>
                <InputLabel id="demo-mutiple-checkbox-label">Programs</InputLabel>
                <Select
                  labelId="program-selection-label"
                  id="program-selection"
                  multiple
                  required
                  value={programNames}
                  onChange={handleProgramsChange}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  disabled={submitButtonPressed}
                >
                  {programList.map((program) => (
                    <MenuItem key={program.name} value={program.name}>
                      <Checkbox checked={programNames.indexOf(program.name) > -1} />
                      <ProgramTooltip title={program.description} arrow>
                        <ListItemText primary={program.name} />
                      </ProgramTooltip>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className={classes.submitProgramsButton}>
                <LoadingButton
                  onClick={loadForm}
                  variant="contained"
                  color="primary"
                  disabled={isEmpty(programNames) || submitButtonPressed}
                  loading={submitButtonPressed && !formLoaded}
                >
                  Submit
                </LoadingButton>
              </FormControl>
            </Grid>
          </Grid>
          {
            submitButtonPressed &&
            <div style={{ marginTop: formLoaded ? '1rem': '0' }}>
              <EmbeddedForm
                formUUID={formUUID}
                formRendererUrl={referralsFormRendererUrl}
                size={{...size, height: '530px'}}
                formParameters={{
                  ...pick(
                    referral,
                    [
                      'givenName',
                      'middleName',
                      'familyName',
                      'birthdate'
                    ]
                  ),
                  streetAddress: get(props, 'patientData.fhirPatient.address[0].line', []).join(' '),
                  city: get(props, 'patientData.fhirPatient.address[0].city'),
                  state: get(props, 'patientData.fhirPatient.address[0].state'),
                  zip: get(props, 'patientData.fhirPatient.address[0].postalCode'),
                  programs: programNames
                }}
                onFormLoaded={handleFormLoaded}
                submitError={props.submitError}
              />
            </div>
          }
          <Backdrop className={classes.backdrop} open={!!props.showBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PreventionLinkReferralDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  formUUID: PropTypes.string.isRequired,
  size: PropTypes.object.isRequired,
  patientData: PropTypes.object.isRequired,
  submitError: PropTypes.instanceOf(Error),
  referralDefaults: PropTypes.object.isRequired,
}

export default PreventionLinkReferralDialog;
