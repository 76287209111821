import { newLazyCatalogProperty } from "../../utils/catalog-utils";
import { CarePlansAPI } from "../../../../CarePlansAPI";

export const categoryProperty = newLazyCatalogProperty({
  propertyPath: "category.coding[0].code",
  catalogFetcher: async () => await CarePlansAPI.getCareTeamCategoryValueSet(),
  itemMapper: (valueSet) => valueSet?.compose?.include?.[0]?.concept,
  optionMapper: ({ code, display }) => ({ value: code, display }),
});

export const managingOrganizationProperty = newLazyCatalogProperty({
  propertyPath: "managingOrganization",
  catalogFetcher: async () => await CarePlansAPI.getOrganizations(),
  itemMapper: (bundle) =>
    bundle?.entry
      ?.map((entry) => entry?.resource)
      .sort((o1, o2) => (o1.name > o2.name ? 1 : -1)),
  optionMapper: (organization) => ({
    value: organization.id,
    display: organization?.name,
  }),
});
