import React from "react";
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import {
  Button,
  Container,
  Grid, Typography 
} from '@material-ui/core';
import Header from '../../Header';
import Footer from '../../Footer';
import colors from '../../../utils/colors';
import '../../../styles/index.css';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: colors.baseBlue,
        color: colors.gray01,
        marginTop: '30px',
        "&:hover": {
          backgroundColor: colors.baseBlue,
          opacity: '0.8'
        }
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        top: '0'
      }
    },
    PrivateNotchedOutline: {
      legendNotched: {
        maxWidth: '70px',
        width: '70px'
      }
    }
  }
})


const Landing = ({ initiateSession }) => {

  return(
    <ThemeProvider theme={theme}>
      <Header />
      <Container className="container-login">
        <Grid item xs={12} >
            <Typography align="center">
            Welcome to Patient Viewer
            </Typography>
        </Grid>
        <Grid item xs={12} >
            <Typography align="center">
            <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                onClick={initiateSession}
            >
                Initiate Session
            </Button>
            </Typography>
        </Grid>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}

Landing.propTypes = {
    initiateSession: PropTypes.func.isRequired
  }

export default Landing;
