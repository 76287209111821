import React, { createContext, useEffect, useState } from 'react';
import auth from './utils/auth';

export const AuthContext = createContext(null);

const EMPTY_TOKEN = "";

function AuthProvider({ children }) {

  const [token, setToken] = useState(EMPTY_TOKEN);

  const getToken = async () => {
    return new Promise(resolve => {
      try {
        let { token } = auth.getTokenFromUrl();
        if (token) {
          auth.setTokenToStorage(token);
        }
        else {
          token = auth.getTokenFromStorage();
        }
        if (token) {
          resolve(token);
        }
        else {
          auth.logout('NO_TOKEN');
          resolve(EMPTY_TOKEN);
        }
      }
      catch (e) {
        console.error(e);
        auth.logout('ERROR');
        resolve(EMPTY_TOKEN);
      }
    })
  };

  useEffect(
    () => {
      console.log("AuthContext component has mounted or updated.");
      getToken().then(async (foundToken) => {
        setToken(foundToken);
        auth.logoutIfRequired(foundToken);
      });
    },
    [token]
  );

  if (token) {
    try {
      const user = auth.parseToken(token);
      return (
        <AuthContext.Provider value={user}>{children}</AuthContext.Provider>
      );
    }
    catch (error) {
      console.error(error);
      auth.logout('ERROR');
    }
  }
  else {
    return <div className="main-loading">Loading...</div>
  }
}

export default AuthProvider;
