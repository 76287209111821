import React, { forwardRef, useEffect, useState } from "react";
import ReferralsTable from 'material-table';
import { ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { theme } from '../theme';
import { tableIcons } from '../table-icons';
import { urgentStyle } from './urgentStyle';
import moment from 'moment';
import { statusRenderer } from '../value-renderers';
import auth from '../../../utils/auth';
import { ReferralUtils } from "../../../services";
import UnfoldMore from "@material-ui/icons/UnfoldMore";
import UnfoldLess from "@material-ui/icons/UnfoldLess";
import Chip from "@material-ui/core/Chip";

function calculateTableHeight() {
  return window.innerHeight - 280;
}

const STATUS_TO_COLOR = {
  "draft": { backgroundColor: "#CCCCCC", fontColor: "black" },
  "submitted": { backgroundColor: "#00FFFF", fontColor: "black" },
  "active": { backgroundColor: "#00FF00", fontColor: "black" },
  "on-hold": { backgroundColor: "#FFA500", fontColor: "black" },
  "completed": { backgroundColor: "#000080", fontColor: "white" },
  "revoked": { backgroundColor: "#8B0000", fontColor: "white" },
}

const ReferralsList = ({
  referrals,
  onReferralClick,
  showAllReferrals,
  ReferralActions,
}) => {

  const columns = [
    {
      title: 'Urgent?',
      customFilterAndSearch: (filter, referral) => {
        const urgentFilter = filter.toUpperCase();
        return (
          ("YES".startsWith(urgentFilter) && referral.urgent) ||
          ("NO".startsWith(urgentFilter) && !referral.urgent)
        );
      },
      render: (referral) => {
        const { urgent } = referral;
        return <div style={urgentStyle(urgent)}>{urgent ? 'YES' : 'NO'}</div>
      },
      field: 'urgent',
    },
    {
      title: 'Referrer',
      field: 'referringOrganization',
    },
    {
      title: 'Receiver',
      field: 'receivingOrganization',
    },
    {
      title: "Status",
      render: (referral) => (
        <div>{statusRenderer(referral.status, referral)}</div>
      ),
      field: 'status',
    },
    {
      title: 'Created On',
      render: (referral) => moment(referral.createTs).format('LLL'),
      customFilterAndSearch: (filter, referral) => {
        const dateFilter = filter.toUpperCase();
        const searchableDate = moment(referral.createTs).format('LLL').toUpperCase();
        return searchableDate.includes(dateFilter);
      },
      field: 'createTs',
    }
  ];

  const [tableHeight, setTableHeight] = useState(calculateTableHeight());
  const isGlobalReferralsList = showAllReferrals && auth.canSeeAllReferrals;

  useEffect(
    () => {
      window.onresize = () => setTableHeight(calculateTableHeight());
      return () => {
        window.onresize = undefined;
      }
    }
  )

  if (isGlobalReferralsList) {
    columns.splice(1, 0, {
      title: 'Patient',
      render: (referral) => <div>{ReferralUtils.getPatientFullName(referral)}</div>,
      customFilterAndSearch: (filter, referral) => {
        const nameFilter = filter.toUpperCase();
        const fullName = ReferralUtils.getPatientFullName(referral).toUpperCase();
        return fullName.includes(nameFilter);
      },
      customSort: (r1, r2) => {
        const fn1 = ReferralUtils.getPatientFullName(r1).toUpperCase();
        const fn2 = ReferralUtils.getPatientFullName(r2).toUpperCase();
        return fn1 > fn2
          ? 1
          : fn1 < fn2
          ? -1
          : 0
        ;
      },
    });

    columns.splice(1, 0, {
      title: 'MRN',
      render: (referral) => <div>{ReferralUtils.getPatientId(null, referral)}</div>,
      customFilterAndSearch: (filter, referral) => {
        const mrnFilter = filter.toUpperCase();
        const mrn = ReferralUtils.getPatientId(null, referral).toUpperCase();
        return mrn.includes(mrnFilter);
      },
      customSort: (r1, r2) => {
        const mrn1 = ReferralUtils.getPatientId(null, r1).toUpperCase();
        const mrn2 = ReferralUtils.getPatientId(null, r2).toUpperCase();
        return mrn1 > mrn2
          ? 1
          : mrn1 < mrn2
          ? -1
          : 0
        ;
      },
    });

    columns.splice(1, 0, {
      title: 'CHILDREN',
      render: (referral) => <div>{referral?.children?.length || 0}</div>,
      customFilterAndSearch: (filter, referral) => {
        const numChildren = parseInt(filter);
        if (isNaN(numChildren)) {
          return false;
        }
        return numChildren === referral?.children?.length || 0;
      },
      customSort: (r1, r2) => {
        const nc1 = r1?.children?.length || 0;
        const nc2 = r2?.children?.length || 0;
        return nc1 > nc2
          ? 1
          : nc1 < nc2
          ? -1
          : 0
        ;
      },
    });

  }

  columns.splice(columns.length - 1, 0, {
    title: 'Description',
    field: 'description',
  });

  if (referrals) {
    return (
      <ThemeProvider theme={theme}>
        <ReferralsTable
          title={ReferralActions}
          columns={columns}
          data={referrals}
          icons={tableIcons}
          onRowClick={(e, referral) => onReferralClick(referral)}
          options={{
            emptyRowsWhenPaging: false,
            maxBodyHeight: tableHeight,
            padding: 'dense',
            pageSize: 10,
            search: true,
            sorting: true,
          }}
          detailPanel={
            [
              referral => {
                const noDetail = !showAllReferrals || (!referral.children || referral.children.length === 0);
                return {
                  disabled: noDetail,
                  icon: forwardRef((props, ref) => {
                    const newProps = noDetail ? { ...props, style: { display: "none" } } : props;
                    return <UnfoldMore {...newProps} ref={ref}/>;
                  }),
                  openIcon: forwardRef((props, ref) => <UnfoldLess {...props} ref={ref}/>),
                  render: (referral) => {
                    return (
                      <div style={{ marginLeft: "6em" }}>
                        {
                          (referral.children || []).map(child => {
                            const { referralId, status } = child;
                            const { backgroundColor, fontColor } = STATUS_TO_COLOR[status]
                            return (
                              <Chip
                                label={`ID: ${referralId}, Status: ${status.toUpperCase()}`}
                                variant="default"
                                size="small"
                                style={{
                                  backgroundColor,
                                  borderColor: backgroundColor,
                                  color: fontColor,
                                  marginLeft: "1em",
                                  marginTop: "0.5em",
                                  marginBottom: "0.5em",
                                  fontSize: "8pt",
                                }}
                              />
                            );
                          })
                        }
                      </div>
                    );
                  },
                };
              }
            ]
          }
        />
      </ThemeProvider>
    );
  }
  else {
    return (
      <ThemeProvider theme={theme}>
        <div>
          <CircularProgress/>
        </div>
      </ThemeProvider>
    );
  }
};

export default ReferralsList;
