import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReferralUtils } from '../../../services';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ReferralDocumentSelectionDialog from './ReferralDocumentSelectionDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReferralsAPI from '../../../services/ReferralsAPI';
import { getDocuments } from "../../../services/MintAPI";

function ReferralDocumentSelectionDialogContainer(props) {

  const {
    open,
    handleClose,
    patientId,
    referralId,
  } = props;
  const [referral, setReferral] = useState(props.referral || {});
  const [documents, setDocuments] = useState(props.documents || []);
  const _patientId = ReferralUtils.getPatientId(patientId, props.referral);

  const loadData = useCallback(
    async () => {
      if (!_patientId) {
        return;
      }
      if (!referralId) {
        const _documents = await getDocuments({ patientId: _patientId });
        setDocuments(_documents);
        return;
      }
      const result = await ReferralUtils.getReferralWithDocuments(
        _patientId, referralId, props.documents, props.referral
      );
      setReferral(result.referral)
      setDocuments(result.documents);
    },
    [_patientId, props.documents, props.referral, referralId]
  );

  useEffect(
    () => {
      loadData();
    },
    [_patientId, referralId, props.referral, props.documents, loadData]
  );

  const handleSave = async (documentsToLink) => {
    try {
      if (referralId) {
        for (let document of documentsToLink) {
          await ReferralsAPI.linkDocument(referralId, document);
          await props.handleSave();
          props.handleClose();
        }
      } else {
        const preventionLinkIframe = ReferralUtils.getPreventionLinkIframe();
        if (preventionLinkIframe) {
          preventionLinkIframe.contentWindow.postMessage(
            {
              type: "linked-documents-selected",
              payload: documentsToLink.map((document) =>
                ReferralUtils.getRetrievalParameters(document)
              ),
            },
            "*"
          );
          props.handleClose();
        }
      }
    } catch (error) {
      return error.message;
    }
  };

  if (open && handleSave && handleClose && referral && documents) {
    return <ReferralDocumentSelectionDialog
      open={open}
      handleSave={handleSave}
      handleClose={handleClose}
      referral={referral}
      documents={documents}
      patientId={_patientId}
    />
  }
  return (
    <Dialog open={open && (!referral || !documents)}>
      <DialogContent>
        <CircularProgress/>
      </DialogContent>
    </Dialog>
  )
}

ReferralDocumentSelectionDialogContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  patientId: PropTypes.string,
  referralId: PropTypes.string,
  referral: ReferralUtils.REFERRAL_DETAIL_SHAPE,
  documents: PropTypes.arrayOf(PropTypes.object),
}

export default ReferralDocumentSelectionDialogContainer;
