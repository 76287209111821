import React from "react";
import { GoalList } from "./GoalList";
import { TaskList } from "./TaskList";
import { CareTeamList } from "./CareTeamList";

const tableTitleStyle = {
  fontWeight: "bold",
  marginLeft: "1em",
  marginTop: "2em",
  marginBottom: "1em",
};

const tableStyle = {
  marginLeft: "1em",
};

export function CarePlanDetails({ carePlan }) {
  if (!carePlan) {
    return null;
  }

  return (
    <>
      <div style={tableTitleStyle}>List of Goals</div>
      <div style={tableStyle}>
        <GoalList carePlan={carePlan} />
      </div>
      <div style={tableTitleStyle}>List of Tasks</div>
      <div style={tableStyle}>
        <TaskList carePlan={carePlan} />
      </div>
      <div style={tableTitleStyle}>List of Care Teams</div>
      <div style={tableStyle}>
        <CareTeamList carePlan={carePlan} />
      </div>
    </>
  );
}
