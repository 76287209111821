import React from "react";
import { Button, Grid } from "@material-ui/core";
import auth from "../../../utils/auth";
import styles from "../../../styles/viewer.module.css";

export const AssessmentsActions = ({ children }) => {
  return (
    <Grid container spacing={2} className={styles.fhirLeftButtonContainer}>
      {children}
    </Grid>
  );
};

export const AssessmentsActionButton = ({
  text,
  action,
  canShow,
  assessment,
  patientInfo,
  color,
}) => {
  if (!assessment || (assessment && canShow(auth, assessment, patientInfo))) {
    return (
      <Grid item>
        <Button variant="contained" color={color || "primary"} onClick={action}>
          {text}
        </Button>
      </Grid>
    );
  }
  return null;
};
