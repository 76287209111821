import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import colors from "../../../utils/colors";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  TextField,
} from "@material-ui/core";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: "48%",
        position: "absolute",
        bottom: "10px",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    // minWidth: 500,
  },
  checkBox: {
    margin: theme.spacing(1),
    marginBottom: 0,
  },
  right: {
    marginLeft: "auto",
  },
  cancelButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
  },
  searchButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
  },
  assessmentIframe: {
    width: "100%",
    // height: "100%",
    height: "100vh !important",
    border: "none",
    // marginBottom: "10px",
  },
}));

function NewAssessmentDialog({
  open,
  handleClose,
  assessmentId,
  token,
  onSurveyCompleted,
}) {
  const classes = useStyles();

  const [showLoader, setShowLoader] = useState(true);

  const iframeRef = useRef < HTMLIFrameElement > null;

  const iframeUrl = useMemo(() => {
    // const url = new URL(
    //   "https://dev-assessments.patientholistics.app/index.php/495813?lang=en"
    // );
    const url = new URL(
      "https://dev-assessments.patientholistics.app/index.php/183325"
    );

    // const url = new URL(`${ASSESSMENTS_BASE_URL}/survey/index/sid/${assessmentId}`);

    // url.searchParams.append("newtest", "Y");

    // const redirectUrl = new URL(window.location.href);
    // redirectUrl.search = "";
    // redirectUrl.hash = "";
    // redirectUrl.pathname = `/survey-saved`;
    // url.searchParams.append("redirect", redirectUrl.toString());

    // if (token) {
    //   url.searchParams.append("token", token);
    // }

    return url.toString();
  }, [assessmentId, token]);

  const handleShowLoader = () => {
    setShowLoader(true);
  };

  const handleHideLoader = () => {
    setShowLoader(false);
  };

  const onIframeLoad = () => {
    handleHideLoader();
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        // fullScreen
        maxWidth = {'lg'}
        open={open}
        onClose={handleClose}
        aria-labelledby="assessment-dialog"
        scroll="paper"
      >
        <DialogTitle id="assessment-dialog-title">Assessment</DialogTitle>
        <DialogContent>
          <Box position="relative" flex={1}>
            {/* <Backdrop open={showLoader} sx={{ position: "absolute" }}> */}
            {showLoader ? <CircularProgress /> : null}
            {/* </Backdrop> */}
            <iframe
              className={classes.assessmentIframe}
              // ref={iframeRef}
              hidden={showLoader}
              key={iframeUrl}
              src={iframeUrl}
              title="Running assessment"
              onLoad={onIframeLoad}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

// AssessmentDialog.propTypes = {
// open: PropTypes.bool.isRequired,
// handleClose: PropTypes.func.isRequired,
// handleSave: PropTypes.func.isRequired,
// };

export default NewAssessmentDialog;
