import React from 'react'
import styles from '../../../../styles/viewer.module.css'
import { get } from 'lodash';

const HtmlButton = ({
  handleView,
  typeView,
  fhirDoocument,
  defaultLabel,
}) => {
  const label = typeView ? getLabel(fhirDoocument, defaultLabel) : 'HTML'
  return(
    <div className={styles.xsltViewButton}>
      {/*eslint-disable-next-line*/}
      <a className={styles.xsltLabel} onClick={handleView}>{label}</a>
    </div>
  )
}

function getLabel(fhirDocument, defaultLabel) {
  if (defaultLabel) {
    return defaultLabel;
  }
  const mimeType = get(fhirDocument, 'content[0].attachment.contentType');
  if (!mimeType) {
    return 'JSON';
  }
  let pos = mimeType.lastIndexOf('.');
  if (pos === -1) {
    pos = mimeType.lastIndexOf('/');
  }
  if (pos === -1) {
    return 'JSON';
  }
  return mimeType.substring(pos + 1).toUpperCase();
}

export default HtmlButton
