import React, { useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { theme } from "../../../theme";
import { tableIcons } from "../../../table-icons";
import { getFilteredItems } from "../../utils/filter-utils";
import { CareTeamFilterDialog } from "./CareTeamFilterDialog";
import { Button, Grid } from "@material-ui/core";
import { CareTeamEditDialog } from "./CareTeamEditDialog";
import { CarePlansAPI } from "../../CarePlansAPI";
import { CareTeam } from "../../utils/fhir-utils/domain/CareTeam";

export function CareTeamList({ carePlan }) {
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [filter, setFilter] = useState({});
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedCareTeam, setSelectedCareTeam] = useState(null);

  if (!carePlan) {
    return null;
  }

  const careTeams = carePlan.getCareTeams();

  const onFilterSelected = (filter) => {
    setFilterDialogOpen(false);
    setFilter(filter);
  };

  const onSubmit = async (careTeam) => {
    if (careTeam.isNew()) {
      const newCareTeam = await CarePlansAPI.createCarePlanChild(careTeam);
      careTeam.addToCarePlan(newCareTeam);
    } else {
      await CarePlansAPI.updateResource(careTeam);
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <MaterialTable
          data={getFilteredItems(careTeams, filter)}
          icons={tableIcons}
          onRowClick={(_, careTeam) => {
            setSelectedCareTeam(careTeam);
            setEditDialogOpen(true);
          }}
          options={{
            emptyRowsWhenPaging: false,
            padding: "dense",
            pageSize: 10,
            search: true,
            sorting: true,
          }}
          columns={[
            {
              title: "Name",
              render: (careTeam) => {
                return careTeam?.getName();
              },
              customFilterAndSearch: (filter, careTeam) => {
                return careTeam
                  .getName()
                  .toUpperCase()
                  ?.includes(filter?.toUpperCase());
              },
              customSort: (t1, t2) => {
                return t1.getName() <= t2.getName() ? -1 : 1;
              },
            },
            {
              title: "Nᴼ of Members",
              render: (careTeam) => {
                return careTeam?.getNumberOfMembers();
              },
              customFilterAndSearch: (filter, careTeam) => {
                return String(careTeam.getNumberOfMembers()).includes(
                  filter?.toUpperCase()
                );
              },
              customSort: (t1, t2) => {
                return t1.getNumberOfMembers() <= t2.getNumberOfMembers()
                  ? -1
                  : 1;
              },
            },
            {
              title: "Members",
              render: (careTeam) => {
                return careTeam?.getMemberDisplayNames();
              },
              customFilterAndSearch: (filter, careTeam) => {
                return careTeam
                  .getMemberDisplayNames()
                  .toUpperCase()
                  .includes(filter?.toUpperCase());
              },
              customSort: (t1, t2) => {
                return t1.getMemberDisplayNames() <= t2.getMemberDisplayNames()
                  ? -1
                  : 1;
              },
            },
            {
              title: "Category",
              render: (careTeam) => {
                return careTeam?.getCategory();
              },
              customFilterAndSearch: (filter, careTeam) => {
                return careTeam
                  .getCategory()
                  .toUpperCase()
                  ?.includes(filter?.toUpperCase());
              },
              customSort: (t1, t2) => {
                return t1.getCategory() <= t2.getCategory() ? -1 : 1;
              },
            },
            {
              title: "Managing Organization",
              render: (careTeam) => {
                return careTeam?.getManagingOrganization();
              },
              customFilterAndSearch: (filter, careTeam) => {
                return careTeam
                  .getManagingOrganization()
                  .toUpperCase()
                  ?.includes(filter?.toUpperCase());
              },
              customSort: (t1, t2) => {
                return t1.getManagingOrganization() <=
                  t2.getManagingOrganization()
                  ? -1
                  : 1;
              },
            },
            {
              title: "Status",
              render: (careTeam) => {
                return careTeam?.getStatus();
              },
              customFilterAndSearch: (filter, careTeam) => {
                return careTeam
                  .getStatus()
                  .toUpperCase()
                  ?.includes(filter?.toUpperCase());
              },
              customSort: (t1, t2) => {
                return t1.getStatus() <= t2.getStatus() ? -1 : 1;
              },
            },
          ]}
          title={
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSelectedCareTeam(new CareTeam(null, carePlan));
                    setEditDialogOpen(true);
                  }}
                >
                  Add a Care Team
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setFilterDialogOpen(true);
                  }}
                >
                  Filter
                </Button>
              </Grid>
            </Grid>
          }
        />
      </ThemeProvider>
      <CareTeamFilterDialog
        careTeams={careTeams}
        open={filterDialogOpen}
        onClose={() => {
          setFilterDialogOpen(false);
        }}
        onFilterSelected={onFilterSelected}
      />
      <CareTeamEditDialog
        careTeam={selectedCareTeam}
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
        }}
        onSubmit={onSubmit}
      />
    </>
  );
}
