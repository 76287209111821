import moment from "moment";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import FhirReferralsAPI from "../../../services/FhirReferralsAPI";

const API = FhirReferralsAPI;

function useReferrals({
  patientId,
  showAllReferrals,
  individual = false,
  referralId = null,
}) {
  const [referrals, setReferrals] = useState([]);
  const [referral, setReferral] = useState(null);
  const [patient, setPatient] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [error, setError] = useState([]);
  const [notes, setNotes] = useState([]);
  const [referralDocuments, setReferralDocuments] = useState([]);
  const [patientDocuments, setPatientDocuments] = useState([]);

  const isOpen = useCallback(() => {
    return true;
    // return [
    //   FhirReferralStates.DRAFT,
    //   FhirReferralStates.SUBMITTED,
    //   FhirReferralStates.ACTIVE,
    //   FhirReferralStates.ON_HOLD,
    // ].includes(referral.status);
  }, [referral, patient]);

  const isPatientProvisioned = useCallback(() => {
    return referral?.id ? true : false;
  }, [referral, patient]);

  const canAddNote = useCallback(() => {
    if ((referral, patient)) {
      return patient && isOpen(referral) && isPatientProvisioned(referral);
    }
  }, [referral, patient]);

  const canAddDocument = useCallback(() => {
    if ((referral, patient)) {
      return patient && isOpen(referral) && isPatientProvisioned(referral);
    }
  }, [referral, patient]);

  const canAccept = useCallback(() => {
    if ((referral, patient)) {
      return patient && isOpen(referral) && isPatientProvisioned(referral);
    }
  }, [referral, patient]);

  const canReject = useCallback(() => {
    if ((referral, patient)) {
      return patient && isOpen(referral) && isPatientProvisioned(referral);
    }
  }, [referral, patient]);

  const canComplete = useCallback(() => {
    if ((referral, patient)) {
      return patient && isOpen(referral) && isPatientProvisioned(referral);
    }
  }, [referral, patient]);

  const hasNotes = useCallback(() => {
    return notes.length > 0;
  }, [notes]);

  const hasReferralDocuments = useCallback(() => {
    return referralDocuments.length > 0;
  }, [referralDocuments]);

  const hasPatientDocuments = useCallback(() => {
    return patientDocuments.length > 0;
  }, [patientDocuments]);

  useEffect(() => {
    if (individual && referralId) {
      manageGetReferral();
      manageGetServiceCategories();
    } else {
      if (showAllReferrals !== undefined) {
        manageGetReferrals();
        manageGetServiceCategories();
      }
    }
  }, [patientId, showAllReferrals, individual, referralId]);

  useEffect(() => {
    if (referral && referralId) {
      manageGetNotes();
      manageGetReferralDocuments();
    }
  }, [referral, referralId]);

  useEffect(() => {
    if (patientId && patient && individual) {
      manageGetPatientDocuments();
    }
  }, [patientId, patient, individual]);

  const manageGetReferrals = async () => {
    console.log("DEBUG manageGetReferrals patientId: ", patientId);
    try {
      const responseReferrals = await API.getReferrals(
        showAllReferrals ? undefined : patientId
      );
      console.log("DEBUG responseReferrals: ", responseReferrals);
      if (responseReferrals?.referrals) {
        setReferrals(responseReferrals.referrals);
        setOrganizations(responseReferrals.organizations);
      }
      if (responseReferrals?.patient && patientId) {
        setPatient(responseReferrals.patient);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const manageGetReferral = async () => {
    try {
      const responseReferral = await API.getReferral(referralId, true);
      // console.log("DEBUG responseReferral: ", responseReferral);
      if (responseReferral?.patient?.resource) {
        setPatient(responseReferral.patient.resource);
      }
      if (responseReferral?.referral?.resource) {
        setReferral(responseReferral.referral.resource);
      }
      if (responseReferral?.organizations) {
        setOrganizations(responseReferral.organizations);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const manageCreateReferral = async (payload) => {
    try {
      const response = await API.createReferral(payload);
      console.log("DEBUG saveReferral response: ", response);
      manageGetReferrals();
      return response?.data;
    } catch (error) {
      setError(error.message);
    }
  };

  const manageGetServiceCategories = async () => {
    try {
      const response = await API.getServiceCategories();
      if (response?.serviceCategories) {
        setServiceCategories(response.serviceCategories);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const manageGetNotes = async () => {
    try {
      const communications = await API.getCommunications(referralId);
      if (communications) {
        setNotes(communications);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const manageGetDocumentReferences = async (query) => {
    try {
      const docs = await API.getDocumentReferences(query);
      if (docs) {
        return docs;
      }
    } catch (error) {
      setError(error.message);
    }
    return [];
  };

  const manageGetReferralDocuments = async () => {
    const query = `?related=${referralId}`;
    const docs = await manageGetDocumentReferences(query);
    setReferralDocuments(docs);
  };

  const manageGetPatientDocuments = async () => {
    if (patient?.id) {
      const query = `?subject=${patient?.id}`;
      const docs = await manageGetDocumentReferences(query);
      setPatientDocuments(docs);
    }
  };

  const manageCreateNote = async (noteText) => {
    const referralReference = {
      reference: `ServiceRequest/${referral?.id}`,
    };

    const payload = {
      resourceType: "Communication",
      sent: moment().format("YYYY-MM-DD"),
      status: "completed",
      partOf: [referralReference],
      note: [
        {
          text: noteText,
          // authorReference: assignedToReference,
          time: moment().format("YYYY-MM-DD"),
        },
      ],
      subject: {
        reference: `Patient/${patient?.id}`,
        type: "Patient",
      },
      // sender: {
      //   reference: assignedToReference,
      //   type: "Practitioner",
      // },
    };
    try {
      const response = await API.createNote(payload);
      console.log("DEBUG createNote response: ", response);
      manageGetNotes();
      return response?.data;
    } catch (error) {
      setError(error.message);
    }
  };

  const manageAcceptReferral = async () => {
    await manageUpdateReferralStatus("active");
    manageGetReferral();
  };

  const manageRejectReferral = async () => {
    await manageUpdateReferralStatus("revoked");
    manageGetReferral();
  };

  const manageCompleteReferral = async () => {
    await manageUpdateReferralStatus("completed");
    manageGetReferral();
  };

  const manageUpdateReferralStatus = async (status) => {
    try {
      const payload = {
        ...referral,
        status: status,
        meta: { ...referral?.meta },
      };
      const response = await API.updateReferral(referralId, payload);
      return response?.data;
    } catch (error) {
      setError(error.message);
    }
  };

  const manageLinkDocumentReference = async (docRef, referral) => {
    console.log("DEBUG manageLinkDocumentReference docRef: ", docRef);
    console.log("DEBUG manageLinkDocumentReference referral: ", referral);
    try {
      const newRelatedReferral = {
        reference: `ServiceRequest/${referral.id}`,
        type: "ServiceRequest",
      };

      const payload = {
        ...docRef,
        meta: { ...docRef?.meta },
      };
      console.log(
        "DEBUG manageLinkDocumentReference initial payload: ",
        payload
      );

      if (docRef?.context) {
        console.log(
          "DEBUG manageLinkDocumentReference docRef has existing context: "
        );
        if (docRef?.context?.related) {
          payload.context = {
            ...docRef.context,
            related: [...docRef.context?.related, newRelatedReferral],
          };
        } else {
          payload.context = {
            ...docRef.context,
            related: [newRelatedReferral],
          };
        }
      } else {
        console.log(
          "DEBUG manageLinkDocumentReference docRef does not have existing context: "
        );
        payload.context = {
          related: [newRelatedReferral],
        };
      }
      console.log(
        "DEBUG manageLinkDocumentReference payload with context: ",
        payload
      );

      const response = await API.UpdateDocumentReference(docRef.id, payload);
      return response?.data;
    } catch (error) {
      console.log(`DEBUG error: ${error.message}`);
      setError(error.message);
    }
  };

  return {
    referral,
    referrals,
    organizations,
    notes,
    hasNotes,
    canAddNote,
    manageCreateNote,
    referralDocuments,
    hasReferralDocuments,
    patientDocuments,
    hasPatientDocuments,
    patientDocuments,
    hasPatientDocuments,
    canAddDocument,
    fetchReferralDocuments: manageGetReferralDocuments,
    fetchPatientDocuments: manageGetPatientDocuments,
    linkDocument: manageLinkDocumentReference,
    canAccept,
    acceptReferral: manageAcceptReferral,
    canReject,
    rejectReferral: manageRejectReferral,
    canComplete,
    completeReferral: manageCompleteReferral,
    patient,
    serviceCategories,
    createReferral: manageCreateReferral,
    error,
  };
}

export default useReferrals;
