import React, { ChangeEvent, useRef, useState } from "react";
import _ from 'lodash';
//import { isEmpty } from 'lodash';
import TextField from '@material-ui/core/TextField';
import styles from '../../../../styles/xmlraw.module.css'

const classnames = require('classnames');

export interface ContentUpdateCallback
{
  (xmlString: string, containerId: string, xpath?: string): number
}

export interface SearchBoxOptions
{
  viewerId: string;
  xmlString: string;
  contentUpdateCallback: ContentUpdateCallback;
}

export function SearchBox(options: SearchBoxOptions)
{
  const { viewerId, xmlString, contentUpdateCallback } = options;

  const [xpath, setXPath] = useState('');
  const error = useRef(false);
  const message = useRef('');

  const search = (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const _xpath = event.target.value;
      setXPath(_xpath);
      const occurrences = contentUpdateCallback(xmlString, viewerId, _xpath);
      if (!_.isEmpty(_xpath)) {
        if (occurrences === 0) {
          message.current = 'No occurrences found.';
          error.current = true;
        }
        else {
          if (occurrences > 1) {
            message.current = `${occurrences} occurrences found.`;
          }
          else {
            message.current = `One occurrence found.`;
          }
          error.current = false;
        }
      }
      else {
        message.current = ``;
        error.current = false;
      }
    }
    catch (_error) {
      error.current = true;
      message.current = _error.message;
    }
  };

  if (_.isEmpty(xpath)) {
    error.current = false;
  }

  return (
    <div
      className={classnames({
        "search-box-container": true,
        'search-box-grow': !_.isEmpty(xpath),
      })}>
      <TextField
        variant="outlined"
        //className="search-box"
        className={styles.searchBox}
        label="XPath"
        size="small"
        helperText={
          <span
            className={classnames({
              'found-occurrences': !error.current,
              'hide': _.isEmpty(xpath),
              'show': !_.isEmpty(xpath),
            })}>
            {_.isEmpty(xpath) ? '' : message.current}
          </span>
        }
        error={error.current}
        value={xpath}
        onChange={search}/>
    </div>
  );
}
