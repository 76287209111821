import _ from "lodash";
import moment from "moment";
import {
  formatDateHumanMDY,
  formatDateHumanMDYFromNow,
  formatDateYMD,
} from "../../../date-utils";
import { getDisplayName } from "../domain-utils";
import { ifNotEmpty } from "../../../string-utils";
import { newEmptyTask } from "../../../careplan-utils";
import { taskCodeProperty } from "./activity-properties";
import { Goal } from "../Goal";

export class Activity {
  data;

  #isNew;
  #carePlan;
  #goal;
  #rawTask;

  constructor(rawActivity, carePlan) {
    this.data = rawActivity;
    this.#isNew = !rawActivity;
    this.#carePlan = carePlan;
  }

  isNew() {
    return this.#isNew;
  }

  getChildResource() {
    return this.getRawTask();
  }

  resolve(parentResource, reference) {
    return this.#carePlan.resolve(parentResource, reference);
  }

  getCarePlan() {
    return this.#carePlan;
  }

  addToCarePlan(newTask) {
    this.#carePlan.addTask(newTask);
  }

  getResourceType() {
    return "Task";
  }

  getGoal() {
    if (!this.#goal) {
      this.#goal = new Goal(
        this.resolve(this.#carePlan, this.data?.detail?.goal?.[0]?.reference),
        this.#carePlan
      );
    }
    return this.#goal;
  }

  addNewTaskIfRequired() {
    if (this.isNew()) {
      this.#rawTask = newEmptyTask();
    }
  }

  getRawTask() {
    if (!this.#rawTask) {
      this.#rawTask = this.resolve(
        this.#carePlan,
        this.data?.outcomeReference?.[0]?.reference
      );
    }
    return this.#rawTask;
  }

  getDueDate() {
    return this.getRawTask()?.executionPeriod?.end || "";
  }

  getDueDateMDY() {
    return ifNotEmpty(this.getDueDate(), formatDateHumanMDY) || "";
  }

  getDueDateMDYFromNow() {
    return ifNotEmpty(this.getDueDate(), formatDateHumanMDYFromNow);
  }

  getLastModifiedDate() {
    return ifNotEmpty(
      this.getRawTask()?.meta?.lastUpdated,
      formatDateHumanMDY,
      ""
    );
  }

  getAuthoredOn() {
    return this.getRawTask()?.authoredOn;
  }

  getAuthoredOnHumanMDY() {
    return ifNotEmpty(this.getAuthoredOn(), formatDateHumanMDY, "");
  }

  setAuthoredOn() {
    const rawTask = this.getRawTask();
    if (this.#isNew) {
      rawTask.authoredOn = new Date().toISOString();
    } else {
      rawTask.authoredOn = rawTask?.meta?.lastUpdated;
    }
  }

  getDescription() {
    return this.getRawTask()?.description || "";
  }

  setDescription(description) {
    this.getRawTask().description = description;
  }

  getComments() {
    return this.getRawTask()?.note?.[0]?.text;
  }

  setComments(comments, author) {
    const firstNote = this.getRawTask().note?.[0] || {};
    if (!comments || firstNote.text === comments) {
      return;
    }
    firstNote.text = comments;
    firstNote.authorString = firstNote.authorString || author;
    firstNote.time = new Date().toISOString();
    this.getRawTask().note = [firstNote];
  }

  getStatus() {
    return ifNotEmpty(this.getRawTask()?.status, _.startCase) || "";
  }

  getStatusId() {
    return this.getRawTask()?.status;
  }

  setStatus(statusCode) {
    this.getRawTask().status = statusCode;
  }

  getPriorityId() {
    return this.getRawTask()?.priority;
  }

  setPriority(priorityCode) {
    this.getRawTask().priority = priorityCode;
  }

  getIntentId() {
    return this.getRawTask()?.intent;
  }

  setIntent(intentCode) {
    this.getRawTask().intent = intentCode;
  }

  getStartDate() {
    const startDate = this.getRawTask()?.executionPeriod?.start;
    if (startDate) {
      return formatDateYMD(startDate);
    }
    return startDate;
  }

  setStartDate(startDate) {
    const rawTask = this.getRawTask();
    if (!rawTask) {
      return;
    }
    rawTask.executionPeriod = {
      ...(rawTask.executionPeriod || {}),
      start: moment(startDate).toDate().toISOString(),
    };
  }

  getEndDate() {
    const endDate = this.getRawTask()?.executionPeriod?.end;
    if (endDate) {
      return formatDateYMD(endDate);
    }
    return endDate;
  }

  setEndDate(endDate) {
    const rawTask = this.getRawTask();
    if (!rawTask) {
      return;
    }
    rawTask.executionPeriod = {
      ...(rawTask.executionPeriod || {}),
      end: moment(endDate).toDate().toISOString(),
    };
  }

  getOwnerReference() {
    return this.getRawTask()?.owner?.reference;
  }

  getOwner() {
    return this.resolve(this.#carePlan, this.getOwnerReference());
  }

  setOwner(ownerReferenceValue) {
    this.getRawTask().owner = {
      reference: ownerReferenceValue,
    };
  }

  getOwnerDisplayName() {
    return getDisplayName(this.getOwner()) || "";
  }

  getRequesterReference() {
    return this.getRawTask()?.requester?.reference;
  }

  setRequester(requesterReferenceValue) {
    this.getRawTask().requester = {
      reference: requesterReferenceValue,
    };
  }

  getTaskType() {
    return this.getRawTask()?.code?.coding?.[0]?.code;
  }

  async setTaskType(taskCode) {
    const catalog = await taskCodeProperty.getCatalog();
    const concept = catalog.concept.find(
      (concept) => concept.code === taskCode
    );
    this.getRawTask().code = {
      coding: [
        {
          system: catalog.valueSet,
          code: taskCode,
          display: concept.display,
        },
      ],
      text: concept.display,
    };
  }
}
