const mintProxyPortAndHost = process.env.REACT_APP_MINT_PROXY_HOST;
export const referralsFormApiUrl = process.env.REACT_APP_FORM_API_URL;
export const referralsFormRendererUrl = process.env.REACT_APP_FORM_RENDERER_URL;

export const checkUsername = `${mintProxyPortAndHost}/api/auth/username`;
export const shouldLogout = `${mintProxyPortAndHost}/api/auth/shouldLogout`;
export const login = `${mintProxyPortAndHost}/api/auth/login`;
export const basicLogin = `${mintProxyPortAndHost}/api/auth/basic/login`;
export const sessionInfoUrl = `${mintProxyPortAndHost}/api/auth/sessionInfo`;
export const shouldLogoutUrl = `${mintProxyPortAndHost}/api/auth/shouldLogout`;
export const userHistoryUrl = `${mintProxyPortAndHost}/api/users-history`;
export const patientsHistoryUrl = `${userHistoryUrl}/patients`;
export const referralsUrl = `${mintProxyPortAndHost}/api/Patient/referrals`;
export const usersUrl = `${mintProxyPortAndHost}/api/users`;
export const serviceCategoriesUrl = `${referralsUrl}/service-categories`;
export const organizationsUrl = `${referralsUrl}/organizations`;
export const organizationPairsUrl = `${referralsUrl}/organization-pairs`;
export const getServerLastCommit = `${mintProxyPortAndHost}/api/app-info/last-commit`;
export const curesUrl = `${mintProxyPortAndHost}/api/cures`;
export const invitesUrl = `${mintProxyPortAndHost}/api/Invites`;
export const addInviteUrl = `${mintProxyPortAndHost}/api/Invites/invite`;
export const updateInviteUrl = `${mintProxyPortAndHost}/api/Invites/update-invite`;
export const confirmEmailUrl = `${mintProxyPortAndHost}/api/Invites/confirm-email`;
export const activDesactUserUrl = `${mintProxyPortAndHost}/api/Users/activate-user`;
export const updateUserUrl = `${mintProxyPortAndHost}/api/Users/update-user`;
export const resendInviteUrl = `${mintProxyPortAndHost}/api/Invites/resend-invite`;
export const expireInviteUrl = `${mintProxyPortAndHost}/api/Invites/expire-invite`;
export const modifyInviteUrl = `${mintProxyPortAndHost}/api/Invites/modify-invite`;
export const resendVerifyEmailUrl = `${mintProxyPortAndHost}/api/Invites/resend-verify-email`;
export const checkInviteExpirationUrl = `${mintProxyPortAndHost}/api/Invites/check-invite-expiration`;
export const internalFhirUrl = `${mintProxyPortAndHost}/api/fhir`;

export const mintProxyUrl = `${mintProxyPortAndHost}`;
export const logoutUrl = `${mintProxyPortAndHost}/api/auth/logout`;
export const fhirTypesUrl =
  "https://hl7.org/fhir/valueset-c80-doc-typecodes.json";
export const fhirTokenUrl = `${mintProxyPortAndHost}/api/auth/fhir-token`;

export const heartbeatExpireSeconds = 60;
export const lastUrlKey = "patientViewerLastUrl";

/**
 * ACTIONS
 */
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const mockData = false;

export const timeoutSessionMessageFlag = getBooleanValue(
  process.env.REACT_APP_TIMEOUT_SESSION_MESSAGE_FLAG
);
export const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

export const timerCheckFocus = 60000 * 10; //in miliseconds
export const timerWaitAnswer = 60000; //in miliseconds

export const calculateAge = (dob) => {
  const birthDate = new Date(dob);
  const difference = Date.now() - birthDate.getTime();
  const age = new Date(difference);

  return Math.abs(age.getUTCFullYear() - 1970);
};

function getBooleanValue(envValue) {
  if (!envValue) {
    return false;
  }
  if (typeof envValue === "string") {
    return envValue.trim().toLowerCase() === "true";
  }
  return envValue === true;
}

export const DEVELOPMENT_DB_USERNAME =
  process.env.REACT_APP_DEVELOPMENT_DB_USERNAME ||
  "arturo.escalante@nicheaim.com";
export const DEVELOPMENT_DB_FULLNAME =
  process.env.REACT_APP_DEVELOPMENT_DB_FULLNAME || "Arturo Escalante";
export const DEVELOPMENT_DB_ORGNAME =
  process.env.REACT_APP_DEVELOPMENT_DB_ORGNAME || "Care Flow";

export const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
export const firebaseAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const firebaseStorageBucket =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const firebaseMessageSenderId =
  process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID;
export const firebaseAppId = process.env.REACT_APP_FIREBASE_APP_ID;
export const firebaseServicesConfigCollection =
  process.env.REACT_APP_FIREBASE_SERVICES_CONFIG_COLLECTION;
export const firebaseServicesDocumentId =
  process.env.REACT_APP_FIREBASE_SERVICES_DOCUMENT_ID;
export const enableServiceDirectory = getBooleanValue(
  process.env.REACT_APP_ENABLE_SERVICE_DIRECTORY
);

export const useFhirReferrals = getBooleanValue(
  process.env.REACT_APP_USE_FHIR_REFERRALS
);

export const fhirUrlsToReplace = (
  process.env.REACT_APP_FHIR_URLS_TO_REPLACE || ""
)
  .split(",")
  .map((url) => url.trim());
export const fhirReplacementUrl = process.env.REACT_APP_FHIR_REPLACEMENT_URL;
