import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/viewer.module.css";
import React from "react";

export function DeleteIconButton({ caption, onClick }) {
  return (
    <Tooltip title={caption}>
      <IconButton
        aria-label={caption}
        color="secondary"
        component="div"
        style={{
          width: "12px",
          height: "12px",
        }}
        onClick={onClick}
      >
        <div>
          <FontAwesomeIcon icon={faTrash} className={styles.deleteIcon}/>
        </div>
      </IconButton>
    </Tooltip>
  );
}