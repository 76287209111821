import React, { useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import colors from "../../../utils/colors";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: '48%',
        position: 'absolute',
        bottom: '10px'
      },
      svg: {
        color: colors.baseBlue
      }
    },
  }
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 500,
  },
  checkBox: {
    margin: theme.spacing(1),
    marginBottom: 0,
  }
}));

export function ReferralReferenceDialog(props) {

  const classes = useStyles();
  const [annotation, setAnnotation] = useState('');
  const [annotationError, setAnnotationError] = useState(false);
  const [documentUrl, setDocumentUrl] = useState('');
  const [emptyDocumentUrlError, setEmptyDocumentUrlError] = useState(false);
  const [invalidDocumentUrlError, setinvalidDocumentUrlError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState('');
  const saveRequested = useRef(false);

  const isEmpty = (value) => value.trim() !== '';

  const isValid = (value, ignoreSaveRequested) => {
    return (saveRequested.current && !ignoreSaveRequested) ? isEmpty(value) : true;
  }

  const isInvalidURL = (url) => {
    try {
      new URL(url);
      return false;
    }
    catch (error) {
      return true;
    }
  }

  const isInvalid = (value) => !isValid(value);

  const handleSave = async () => {
    saveRequested.current = true;
    setSaveError('');
    setAnnotationError(isInvalid(annotation));
    setEmptyDocumentUrlError(isInvalid(documentUrl));
    if (isInvalid(annotation) || isInvalid(documentUrl) || isInvalidURL(documentUrl)) {
      return;
    }
    setSaving(true);
    let error = await props.handleSave(annotation, documentUrl);
    if (error) {
      setSaving(false);
      setSaveError(error);
    }
  }

  const handleAnnotationChange = (event) => {
    setAnnotation(event.target.value);
    setAnnotationError(isInvalid(event.target.value, true));
  }

  const handleDocumentUrlChange = (event) => {
    setDocumentUrl(event.target.value);
    setEmptyDocumentUrlError(false);
    setinvalidDocumentUrlError(false);
    if (isInvalid(event.target.value)) {
      setEmptyDocumentUrlError(true);
      setinvalidDocumentUrlError(false);
    }
    else {
      setinvalidDocumentUrlError(isInvalidURL(event.target.value));
    }
  }

  const reset = () => {
    saveRequested.current = false;
    setAnnotation('');
    setAnnotationError(false);
    setDocumentUrl('');
    setEmptyDocumentUrlError(false);
    setinvalidDocumentUrlError(false);
    setSaving(false);
    setSaveError('');
  }

  return (
    <div>
      <Dialog
        open={props.open} onClose={props.handleClose} onEnter={reset} aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Reference</DialogTitle>
        <DialogContent>
          <FormGroup row>
            <FormControl className={classes.formControl} error={annotationError}>
              <TextField
                id="referral-annotation"
                required
                multiline
                rows={4}
                variant="outlined"
                margin="dense"
                label="Annotation"
                fullWidth
                value={annotation}
                onChange={handleAnnotationChange}
              />
              {
                annotationError &&
                <FormHelperText>Annotation is required.</FormHelperText>
              }
            </FormControl>
          </FormGroup>
          <FormGroup row>
            <FormControl className={classes.formControl} error={emptyDocumentUrlError || invalidDocumentUrlError}>
              <TextField
                id="referral-document-url"
                required
                multiline
                rows={4}
                variant="outlined"
                margin="dense"
                label="Document URL"
                fullWidth
                value={documentUrl}
                onChange={handleDocumentUrlChange}
              />
              {
                emptyDocumentUrlError &&
                <FormHelperText>Document URL is required.</FormHelperText>
              }
              {
                invalidDocumentUrlError &&
                <FormHelperText>Document URL is invalid.</FormHelperText>
              }
            </FormControl>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary" disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={saving || saveError}>
            Save
          </Button>
        </DialogActions>
        {
          saving &&
          <ThemeProvider theme={theme}>
            <div>
              <CircularProgress/>
            </div>
          </ThemeProvider>
        }
        {
          saveError &&
          <MuiAlert severity="error" elevation={6} variant="filled">
            {saveError}
          </MuiAlert>
        }
      </Dialog>
    </div>
  );
}

ReferralReferenceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}
