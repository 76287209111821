const USER_NOT_FOUND = 'CURES_USER_NOT_FOUND';
const USER_INCOMPLETE = 'USER_INCOMPLETE';

const ERROR_CODES = [
  USER_NOT_FOUND,
  USER_INCOMPLETE,
];

function getMessage(messageSource) {
  return typeof messageSource === 'string' ? messageSource : messageSource.message;
}

export class CURESError extends Error {

  code;
  user;

  constructor(message, code) {
    super(message);
    this.code = code;
  }

  get userNotFound() {
    return this.code === USER_NOT_FOUND;
  }

  get userIncomplete() {
    return this.code === USER_INCOMPLETE;
  }

  static throw(messageSource) {
    const message = getMessage(messageSource);
    throw new CURESError(message, ERROR_CODES.find(errorCode => message.includes(errorCode)));
  }

  static throwUserIncomplete(user) {
    const error = new CURESError(USER_INCOMPLETE, USER_INCOMPLETE);
    error.user = user;
    throw error;
  }

}
