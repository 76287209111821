import _ from "lodash";
import { ifNotEmpty } from "../../string-utils";

const RESOURCE_TYPES_TO_DISPLAY_FUNCTIONS = {
  Patient: getPersonFullName,
  RelatedPerson: getPersonFullName,
  Practitioner: getPersonFullName,
  PractitionerRole: (role, bundle) => {
    const practitioner = ifNotEmpty(
      role?.practitioner?.reference,
      (referenceValue) => bundle.resolve(role, referenceValue),
      null
    );
    const specialtiesCommaSeparated = (role?.specialty || [])
      .map((specialty) => specialty.text)
      .filter(Boolean)
      .join(", ");
    return [
      specialtiesCommaSeparated,
      ifNotEmpty(practitioner, getDisplayName, ""),
    ]
      .filter(Boolean)
      .join(" - ");
  },
};

function getPersonFullName(person) {
  return `${getFullName(person?.name)} (${person.resourceType})`;
}

function getFullName(humanName) {
  if (humanName) {
    if (Array.isArray(humanName) && humanName.length > 0) {
      humanName = humanName[0];
    }
    return _.startCase(
      [(humanName.given || []).filter(Boolean).join(" "), humanName.family]
        .filter(Boolean)
        .join(" ")
        .toLowerCase()
    );
  }
}

export function getDisplayName(resource, bundle) {
  const displayFn = RESOURCE_TYPES_TO_DISPLAY_FUNCTIONS[resource?.resourceType];
  if (displayFn) {
    return displayFn(resource, bundle);
  }
  return resource?.name || "";
}
