import React, { Fragment } from "react";
import { observer } from "mobx-react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import colors from "../utils/colors";
import { calculateAge } from "../utils/constants";
import { isEqual, dropRight } from "lodash";
import "../styles/index.css";

const theme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: colors.baseBlue,
        color: colors.gray01,
      },
    },
    MuiButtonBase: {
      root: {
        color: colors.gray01 + " !important",
        "&:disabled": {
          color: colors.black + " !important",
          opacity: "0.38",
        },
      },
    },
    MuiPaper: {
      root: {
        height: "50px",
      },
    },
    MuiSvgIcon: {
      root: {
        height: "22px",
        paddingRight: "3px",
        width: "22px",
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: "-webkit-fill-available !important",
      },
    },
  },
});

const Navigation = observer(({ routes, patientData, history }) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="navigation-bar">
        <Container className="container-navigation" maxWidth="xl">
          <div className="breadcrumb">
            {getBreadcrumbs(
              history,
              routes,
              window.location.pathname,
              patientData
            )}
            {patientData && (
              <div className="navigation-patient-data">
                <p>
                  {patientData.name} [MRN:{" "}
                  {patientData.id ? patientData.id : "N/A"}]
                </p>
                <p>
                  DOB: {patientData.birthDate}{" "}
                  {patientData.birthDate !== "N/A"
                    ? `[Age: ${calculateAge(patientData.birthDate)}]`
                    : ""}
                </p>
              </div>
            )}
          </div>
        </Container>
      </div>
    </ThemeProvider>
  );
});

function getBreadcrumbRoutes(routes, currentPathname) {
  const breadcrumbRoutes = [];

  const currentRoute = routes.find((route) =>
    currentPathname.match(route.pathnameRegex)
  );
  if (currentRoute) {
    breadcrumbRoutes.push(currentRoute);
    const breadcrumbs = currentRoute.breadcrumbs;
    for (let i = 1; i <= breadcrumbs.length - 1; i++) {
      const subRoute = routes.find((route) =>
        isEqual(route.breadcrumbs, dropRight(breadcrumbs, i))
      );
      if (subRoute) {
        breadcrumbRoutes.push(subRoute);
      }
    }
  }
  return breadcrumbRoutes.reverse();
}

function getBreadcrumbs(history, routes, currentPathname, patientData) {
  const breadcrumbRoutes = getBreadcrumbRoutes(routes, currentPathname);

  if (breadcrumbRoutes.length === 0) {
    return <div />;
  }
  return breadcrumbRoutes.map((route, index) => (
    // eslint-disable-next-line
    <a
      key={`breadcrumb-route-${index}`}
      className="breadcrumb__step breadcrumb__step--active"
      onClick={() => route.onClick(history, currentPathname, patientData)}
    >
      <Fragment>
        {React.createElement(route.icon, {
          size: "2x",
          className: "navigation-icon",
        })}
        {route.breadcrumbs[route.breadcrumbs.length - 1]}
      </Fragment>
    </a>
  ));
}

export default Navigation;
