import _ from "lodash";
import { ifNotEmpty } from "../../../string-utils";
import { getDisplayName } from "../domain-utils";
import {
  categoryProperty,
  managingOrganizationProperty,
} from "./careteam-properties";
import { getIdFromRelativeReference } from "../resolve-utils";

export class CareTeam {
  data;

  #isNew;
  #carePlan;
  #managingOrganization;

  constructor(rawCareTeam, carePlan) {
    this.data = rawCareTeam || { resourceType: "CareTeam" };
    this.#isNew = !rawCareTeam;
    this.#carePlan = carePlan;
  }

  isNew() {
    return this.#isNew;
  }

  getCarePlan() {
    return this.#carePlan;
  }

  addToCarePlan(newCareTeam) {
    this.#carePlan.addCareTeam(newCareTeam);
  }

  resolve(parentResource, reference) {
    return this.#carePlan.resolve(parentResource, reference);
  }

  getMembers() {
    return (this.data?.participant || [])
      .map((participant) =>
        this.resolve(this.data, participant?.member?.reference)
      )
      .filter(Boolean);
  }

  getName() {
    return this.data?.name || "";
  }

  setName(name) {
    this.data.name = name;
  }

  getCategory() {
    const category = this.data?.category?.[0];
    return category?.text || category?.coding?.[0]?.display || "";
  }

  getCategoryId() {
    return this.data?.category?.[0]?.coding?.[0]?.code || "";
  }

  async setCategory(categoryCode) {
    const include = (await categoryProperty.getCatalog())?.compose
      ?.include?.[0];
    const item = (include?.concept || []).find(
      (concept) => concept.code === categoryCode
    );
    if (item) {
      this.data.category = [
        {
          coding: [
            {
              code: item.code,
              system: include.system,
              display: item.display,
            },
          ],
          text: item.display,
        },
      ];
    }
  }

  async setManagingOrganization(organizationId) {
    this.data.managingOrganization = [
      { reference: `Organization/${organizationId}` },
    ];
  }

  _getManagingOrganization() {
    if (!this.#managingOrganization) {
      this.#managingOrganization = this.resolve(
        this.data,
        this.data?.managingOrganization?.[0]?.reference
      );
      if (!this.#managingOrganization) {
        this.#managingOrganization = managingOrganizationProperty.items.find(
          (organization) =>
            organization.id ===
            getIdFromRelativeReference(
              this.data?.managingOrganization?.[0]?.reference
            )
        );
      }
    }
    return this.#managingOrganization;
  }

  getManagingOrganization() {
    return this._getManagingOrganization()?.name || "";
  }

  getManagingOrganizationId() {
    return (
      this._getManagingOrganization()?.id ||
      ifNotEmpty(
        this.data?.managingOrganization?.[0]?.reference,
        getIdFromRelativeReference,
        ""
      )
    );
  }

  getStatus() {
    return ifNotEmpty(this.data?.status, _.startCase, "");
  }

  getNumberOfMembers() {
    return this.getMembers().length;
  }

  getMemberDisplayNames() {
    return (
      this.getMembers()
        .map((member) => getDisplayName(member))
        .join(", ") || ""
    );
  }
}
