import { useCallback, useEffect, useRef, useState } from "react";

function isInvalid(value, optional) {
  return !optional && (!value || value?.trim() === "");
}

export function useForm() {
  const fields = useRef({});
  const errors = useRef({});
  const [isInvalid, setIsInvalid] = useState(true);

  const addField = (name, field) => {
    fields.current[name] = field;
  };

  const notifyError = (fieldName, error) => {
    errors.current[fieldName] = error;
    let errorCount = 0;
    Object.values(errors.current).forEach((error) => {
      if (error) {
        errorCount++;
      }
    });
    setIsInvalid(errorCount > 0);
  };

  const reset = () => {
    Object.values(fields.current).forEach((field) => {
      field.reset();
    });
    setIsInvalid(false);
  };

  return {
    isInvalid,
    addField,
    notifyError,
    reset,
  };
}

export function useOptionalField(
  form,
  name,
  initialValue,
  addToForm,
  validator,
  formatter
) {
  return useField(
    form,
    name,
    initialValue,
    addToForm,
    validator,
    formatter,
    true
  );
}

export function useField(
  form,
  name,
  initialValue,
  addToForm = true,
  validator = (_) => "",
  formatter = (_) => _,
  optional = false
) {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState("");
  const lastError = useRef("");

  const requiredErrorMsg = `${name} is required.`;

  const validate = useCallback(() => {
    lastError.current = isInvalid(value, optional) ? requiredErrorMsg : "";
    const validationErrorMsg = validator(value);
    if (validationErrorMsg) {
      lastError.current = validationErrorMsg;
    }
    setError(lastError.current);
  }, [value, name]);

  const reset = () => {
    setValue(initialValue);
    setError("");
    lastError.current = "";
  };

  useEffect(() => {
    if (addToForm) {
      validate();
      form.notifyError(name, lastError.current);
    }
  }, [addToForm, value, validate, form, name, error]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const changeHandler = (event) => {
    setValue(formatter(event?.target?.value));
  };

  if (addToForm) {
    form.addField(name, { reset });
  }

  return {
    value,
    changeHandler,
    error,
    name,
    requiredErrorMsg,
    optional,
  };
}

export function useFieldUnpacked(form, name, initialValue, addToForm = true) {
  const field = useField(form, name, initialValue, addToForm);
  return [field.value, field.changeHandler, field.error, field.name];
}
