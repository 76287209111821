import moment from "moment";
import { formatDateYMD } from "./date-utils";

const FILTER_PREDICATES = {
  status: (item, filter) => {
    return !filter?.status || item?.getStatus() === filter.status;
  },
  category: (item, filter) => {
    return !filter?.category || item?.getCategory() === filter.category;
  },
  owner: (item, filter) => {
    return !filter?.owner || item?.getOwnerDisplayName() === filter.owner;
  },
  managingOrganization: (item, filter) => {
    return (
      !filter?.managingOrganization ||
      item?.getManagingOrganization() === filter.managingOrganization
    );
  },
  periodStart: (item, filter) => {
    return (
      !filter?.periodStart ||
      moment(item?.getDueDate()).isSameOrAfter(
        formatDateYMD(filter.periodStart)
      )
    );
  },
  periodEnd: (item, filter) => {
    return (
      !filter?.periodEnd ||
      moment(item?.getDueDate()).isSameOrBefore(formatDateYMD(filter.periodEnd))
    );
  },
};

function newMatcher(filter) {
  const predicates = Object.entries(filter)
    .filter(([_, filterValue]) => Boolean(filterValue))
    .map(([filterKey, _]) => FILTER_PREDICATES[filterKey]);

  return (item) => predicates.every((predicate) => predicate(item, filter));
}

export function getFilteredItems(items, filter) {
  const itemMatches = newMatcher(filter);
  return items?.filter((item) => itemMatches(item));
}

export function getUniqueSortedValues(items, getItemValue) {
  return [...new Set((items || []).map(getItemValue))].sort();
}
