import { get, keyBy, sortBy } from 'lodash';
import { DocumentCatalogsStore } from '../components/PatientViewer/Documents/DocumentCatalogsStore';

export class CatalogUtil {

  static normalizeCatalogs(catalogs) {
    return {
      ...catalogs,
      mimeTypes: Object
        .keys(catalogs.mimeTypeExtensions)
        .map(mimeType => ({ identifier: mimeType }))
    };
  }

  static formatDocTypeDisplay(docTypeOrDocument) {
    const isDocument = !!docTypeOrDocument.typeCode;
    if (isDocument) {
      const document = docTypeOrDocument;
      return `${document.typeCode} - ${document.type || '?'}`;
    }
    const docType = docTypeOrDocument;
    return `${docType.code} - ${docType.display || '?'}`;
  }

  static mergeWithCatalogTypeCodes(valueSetDocTypes)
  {
    const catalogDocTypes =
      get(DocumentCatalogsStore.getDocumentCatalogs(), 'typeCodes', [])
        .map(typeCode => ({
          code: typeCode.identifier,
          display: typeCode.value,
        }))
    ;

    const mergedDocTypesMap = catalogDocTypes.reduce(
      (valueSetDocTypesMap, catalogDocType) => {
        valueSetDocTypesMap[catalogDocType.code] = catalogDocType;
        return valueSetDocTypesMap;
      },
      keyBy(valueSetDocTypes, 'code')
    );

    return sortBy(Object.values(mergedDocTypesMap), 'code');
  }

}
