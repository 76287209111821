import DialogContentText from "@material-ui/core/DialogContentText";
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import colors from '../../../utils/colors';

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: '48%',
        position: 'absolute',
        bottom: '10px'
      },
      svg: {
        color: colors.baseBlue
      }
    },
  }
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 500,
  }
}));

function TextDialog(props) {

  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [comments, setComments] = useState('');

  const handleSave = async () => {
    setSaving(true);
    let error;
    if (comments?.trim()?.length === 0) {
      error = `${props.subtitle} is required.`;
    }
    else {
      error = await props.handleSave(comments);
    }
    if (error) {
      setSaving(false);
      setSaveError(error);
    }
  }

  const handleChange = (e) => {
    setComments(e.target.value);
  }

  const reset = () => {
    setSaving(false);
    setComments('');
    setSaveError('');
  }

  return (
    <div>
      <Dialog open={props.open} onEnter={reset} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          {
            props.text &&
            <DialogContentText id="alert-dialog-description">
              {props.text}
            </DialogContentText>
          }
          <FormGroup row>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                autoFocus
                required
                multiline
                rows={4}
                variant="outlined"
                margin="dense"
                label={props.subtitle}
                onChange={handleChange}
              />
            </FormControl>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary" disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={saving}>
            { saveError ? "Retry" : "Save" }
          </Button>
        </DialogActions>
        {
          saving &&
          <ThemeProvider theme={theme}>
            <div>
              <CircularProgress/>
            </div>
          </ThemeProvider>
        }
        {
          saveError &&
          <MuiAlert severity="error" elevation={6} variant="filled">
            {saveError}
          </MuiAlert>
        }
      </Dialog>
    </div>
  );
}

TextDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default TextDialog;
