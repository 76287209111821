import React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import colors from '../../../utils/colors'
import styles from '../../../styles/viewer.module.css'
import IconButton from '@material-ui/core/IconButton';
import AddCircle from '@material-ui/icons/AddCircle';
import auth from '../../../utils/auth';
import { CatalogUtil } from '../../../utils';

const theme = createMuiTheme({
  overrides: {
    MuiChip: {
      label: {
        color: colors.gray01,
        zIndex: '1'
      },
      root: {
        backgroundColor: colors.baseBlue,
        color: colors.gray01,
        fontSize: '11px',
        height: '14px',
        margin: '-2px 2px',
        padding: '10px',
        verticalAlign: 'initial',
      },
      deleteIcon: {
        color: colors.gray01,
        height: '15px',
        width: '15px'
      }
    },
    MuiFormControl: {
      root: {
        display: '-webkit-box'
      }
    },
    MuiFormLabel: {
      root: {
        color: colors.baseBlue,
        fontSize: '12px',
        opacity: '0.4',
        paddingLeft: '15px',
        zIndex: '1'
      }
    },
    MuiInput: {
      root: {
        position: 'absolute !important',
        top: '0',
        left: '0'
      }
    },
    MuiInputBase: {
      root: {
        backgroundColor: colors.gray01,
        borderRadius: '5px',
        width: '75%'
      }
    },
    MuiInputLabel: {
      shrink: {
        color: 'transparent !important'
      }
    },
    MuiPaper: {
      root: {
        //width: '80%'
      }
    },
    MuiSelect: {
      select: {
        paddingLeft: '5px',
        "&:focus": {
          background: colors.primaryColor
        }
      }
    }
  },
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 550,
    },
  },
}

const canPostDocument = auth.permissions.canPostDocument()

const TypeSelect = ({
  handleChange,
  handleDelete,
  typeDocuments = [],
  types = [],
  onAddDocument,
}) => {
      //<InputLabel id="demo-mutiple-chip-label" disableAnimation={true}>Document Types</InputLabel>
  return(
    <ThemeProvider theme={theme}>
      <div className={styles.documentTypesContainer}>
        {
          canPostDocument &&
          <span className={styles.addDocumentButtonContainer}>
            <IconButton color="primary" aria-label="Add Document" component="span" onClick={onAddDocument}>
              <AddCircle />
            </IconButton>
          </span>
        }
        <FormControl
          className={styles.formControlTypeSelect}
          style={{ left: canPostDocument ? '5%' : '2%' }}
        >
          <label id="demo-mutiple-chip-label" className={styles.typeSelectLabel}>Document Types</label>
          <Select
            labelId="document-types-label"
            id="document-types"
            multiple
            value={typeDocuments}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={styles.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value.code}
                    label={CatalogUtil.formatDocTypeDisplay(value)}
                    onDelete={() => handleDelete(value.code)}
                    onMouseDown={e => {
                      e.stopPropagation();
                    }}
                    style={styles.chip}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {types.map((type) => (
              <MenuItem key={type.code} value={type}>
                {CatalogUtil.formatDocTypeDisplay(type)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </ThemeProvider>
  )
}

export default TypeSelect
