import { db } from "./config";
import { getDoc, doc } from "firebase/firestore/lite";
import {
  firebaseServicesConfigCollection,
  firebaseServicesDocumentId,
} from "../utils/constants";

const getDefaults = async () => {
  const defaultsDocRef = doc(db, firebaseServicesConfigCollection, "defaults");
  const defaultsDocSnap = await getDoc(defaultsDocRef);
  if (defaultsDocSnap.exists()) {
    // console.log(`DEBUG: Collection defaults exists `, defaultsDocSnap.data());
    return defaultsDocSnap.data();
  } else {
    console.log(
      `DEBUG: Collection defaults does NOT exist `,
      defaultsDocSnap.data()
    );
    return null;
  }
};

export const getServicesConfig = async () => {
  try {
    let documentId = "";
    // console.log(
    //   "DEBUG firebaseServicesDocumentId: ",
    //   firebaseServicesDocumentId
    // );
    if (firebaseServicesDocumentId) {
      documentId = firebaseServicesDocumentId;
    } else {
      const defaults = await getDefaults();
      // console.log("DEBUG defaults: ", defaults);
      if (defaults?.environment) {
        documentId = defaults?.environment;
      }
    }
    // console.log("DEBUG documentId: ", documentId);

    const servicesConfigDocRef = doc(
      db,
      firebaseServicesConfigCollection,
      documentId
    );

    const docSnap = await getDoc(servicesConfigDocRef);
    if (docSnap.exists()) {
      console.log(
        // `DEBUG: Collection ${firebaseServicesConfigCollection} exists `,
        docSnap.data()
      );
      return docSnap.data();
    }
    console.log(`DEBUG: Collection ${documentId} does not exist`);
  } catch (error) {
    console.log(error);
    return null;
  }

  return null;
};
