import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Landing from './views/Landing';
import { withRouter } from 'react-router-dom'
import { validateUsername, handleLogin } from '../../services/MintAPI'
import auth from '../../utils/auth'
import MainContext from '../../utils/contexts'
import { AuthContext } from '../../AuthProvider';

const useStyles = () => ({
  root: {
    fontFamily: ['Roboto', 'sans-serif'],
   },
});

class Login extends Component {
  static contextType = AuthContext
  constructor(props) {
    super(props)
    this.state = {
      stepOne: true,
      stepTwo: false,
      validUser: '',
      apiError: '',
    }
  }

  componentDidMount() {
    if(auth.isAuthenticated(this.context)) {
      this.props.history.push("/")
    }
  }

  handleBack = () => {
    this.setState({
      stepOne: true,
      stepTwo: false
    })
  }

  handleSubmit = async e => {
    e.preventDefault()

    const { username = '', password = '' } = e.target
    const { value: user } = username;
    const { value: pass } = password;

    if ( user ) {
      const response = await validateUsername({ username: user})
      if(response) {
        const { username, error } = response;
        if(username) this.setState({ stepOne: false, stepTwo: true, validUser: username, apiError: '' })
        if(error) this.setState({ apiError: error })
      } else {
        this.setState({ apiError: 'User not valid' })
      }
    } else if (pass) {
      const { history } = this.props
      const { validUser } = this.state
      const response = await handleLogin({ username: validUser, password: pass })

      if(response) {
        const { validUser } = this.state
        const { accessToken, expiresIn, error } = response

        if(error) {
          this.setState({ apiError: error })
        } else {
          this.setState({ apiError: '' });
          const localUser = {
            username: validUser,
            token: accessToken,
            expires: expiresIn
          }

          auth.login(localUser, () => {
            history.push('/');
          })
        }
      } else {
        this.setState({ apiError: 'Password not valid' })
      }
    }
  }

  initiateSession=()=>{
    window.location = auth.getLoginUrl();
  }

  render() {
    const { classes } = this.props;

    return (
      <div id="login-container" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container>
              <Landing initiateSession={this.initiateSession} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Login.contextType = MainContext;
export default withRouter(withStyles(useStyles)(Login));

