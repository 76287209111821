import React, { useEffect, useState, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import colors from '../../utils/colors';
import auth from "../../utils/auth";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: '40%',
        position: 'absolute',
        bottom: '10px'
      },
      svg: {
        color: colors.baseBlue
      }
    },
  }
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: 100,
  }
}));

export function PatientProvisioningDialog(props) {

  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [mrn, setMrn] = useState(null);
  const [useManualMrn, setUseManualMrn] = useState(false);
  const isMrnInvalid = useManualMrn && (!mrn || mrn?.trim() === '');

  const { patientData, fhirPatient } = props;

  useEffect(() => {
    if (props.useManualMrn) {
      (async () => {
        setUseManualMrn(await auth.orgUsesManualMrnProvisioning());
      })();
    }
  }, [patientData, fhirPatient, props.useManualMrn]);

  const handleSave = async () => {
    setSaving(true);
    const error = await props.handleSave(patientData, fhirPatient, mrn);
    if (error) {
      setSaving(false);
      setSaveError(error);
    }
  }

  const reset = () => {
    setUseManualMrn(false);
    setMrn(null);
    setSaving(false);
    setSaveError('');
  }

  const handleMrnChange = (event) => {
    setMrn(event?.target?.value);
  }

  return (
    <div>
      <Dialog
        open={props.open} onEnter={reset} onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          {
            useManualMrn &&
            <FormGroup row>
              <FormControl className={classes.formControl} error={isMrnInvalid}>
                <TextField
                  required
                  variant="outlined"
                  margin="dense"
                  label="MRN"
                  fullWidth
                  value={mrn}
                  onChange={handleMrnChange}
                />
                {
                  isMrnInvalid &&
                  <FormHelperText>MRN is required.</FormHelperText>
                }
              </FormControl>
            </FormGroup>
          }
          <div>
            <p>
              <label className="tiptext-label bold-label">Name: </label>
              <span>
                {patientData.lastName}, {patientData.firstName}
              </span>
            </p>
            <p>
              <label className="tiptext-label bold-label">Age: </label>
              <span>
                {patientData.age}
              </span>
            </p>
            <p>
              <label className="tiptext-label bold-label">Gender: </label>
              <span>
                {patientData.gender}
              </span>
            </p>
            <p>
              <label className="tiptext-label bold-label">Phone: </label>
              <span>
                {patientData.phone}
              </span>
            </p>
            <p>
              <label className="tiptext-label bold-label">Address: </label>
              <span>
                {patientData.address}
              </span>
            </p>
            <p>
              <label className="tiptext-label bold-label">Location: </label>
              <span>
                {patientData.location}
              </span>
            </p>
            <p>
              <label className="tiptext-label bold-label">Deceased: </label>
              <span>
                {patientData.deceased}
              </span>
            </p>
            <p>
              <label className="tiptext-label bold-label">Last Update: </label>
              <span>
                {patientData.lastUpdate}
              </span>
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          {
            props.showOnlyOkButton &&
            <Button onClick={props.handleClose} color="primary">
              OK
            </Button>
          }
          {
            !props.showOnlyOkButton &&
            <Fragment>
              <Button onClick={handleSave} color="primary" disabled={saving || saveError || isMrnInvalid}>
                Yes
              </Button>
              <Button onClick={props.handleClose} color="primary" disabled={saving}>
                No
              </Button>
            </Fragment>
          }
        </DialogActions>
        {
          saving &&
          <ThemeProvider theme={theme}>
            <div>
              <CircularProgress/>
            </div>
          </ThemeProvider>
        }
        {
          saveError &&
          <MuiAlert severity="error" elevation={6} variant="filled">
            {saveError}
          </MuiAlert>
        }
      </Dialog>
    </div>
  );
}

PatientProvisioningDialog.propTypes = {
  patientData: PropTypes.object.isRequired,
  fhirPatient: PropTypes.object,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  showOnlyOkButton: PropTypes.bool,
  useManualMrn: PropTypes.bool,
}
