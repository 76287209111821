import md5 from 'md5';
import { isPlainObject, isString } from 'lodash';

const DEFAULT_RETRY_SECONDS = 1;
const DEFAULT_VALIDITY_SECONDS = 3;

function digest(obj) {
  if (isPlainObject(obj)) {
    return md5(JSON.stringify(obj));
  }
  if (isString(obj) && obj.length > 100) {
    return md5(obj);
  }
  return obj;
}

async function tryFetch(cache, cacheKey, fetchFn) {
  return new Promise(async resolve => {
    const result = {
      lastCall: Date.now(),
      ok: true,
      data: null,
      error: null,
    };
    try {
      result.data = await fetchFn();
      cache.set(cacheKey, result);
      resolve(result);
    }
    catch (_error) {
      result.ok = false;
      result.error = _error;
      cache.set(cacheKey, result);
      resolve(result);
    }
  });
}

function retrySecondsHaveElapsed(result, retrySeconds = DEFAULT_RETRY_SECONDS) {
  return Date.now() - result.lastCall > retrySeconds * 1000;
}

function validitySecondsHaveElapsed(result, validitySeconds = DEFAULT_VALIDITY_SECONDS) {
  return Date.now() - result.lastCall > validitySeconds * 1000;
}

export function newCache(retrySeconds = DEFAULT_RETRY_SECONDS,
  validitySeconds = DEFAULT_VALIDITY_SECONDS) {
  const cache = new Map();
  const requesting = new Map();
  const trace = new Map();
  let callNumber = 0;
  return {
    trace,
    getCached: async function (params, fetchFn, traceOptions) {
      const defaultExtractFn = (result) => result;
      let dataExtractFn, errorExtractFn;
      if (traceOptions) {
        dataExtractFn = traceOptions['dataExtractFn'] || defaultExtractFn;
        errorExtractFn = traceOptions['errorExtractFn'] || defaultExtractFn;
      }
      else {
        dataExtractFn = defaultExtractFn;
        errorExtractFn = defaultExtractFn;
      }
      return new Promise(async (resolve, reject) => {
        callNumber++;
        const cacheKey = digest(params);
        let _trace = trace.get(cacheKey);
        if (!_trace) {
          _trace = [{ ts: Date.now(), callNumber, pos: 0, ...params }];
          trace.set(cacheKey, _trace);
        }
        const cachedResult = cache.get(cacheKey);

        _trace.push({ ts: Date.now(), callNumber, pos: 1,
          cachedResultHash: cachedResult ? digest(cachedResult) : null,
          requesting: !!requesting.get(cacheKey),
        });

        if (cachedResult) {
          if (cachedResult.ok) {

            _trace.push({ ts: Date.now(), callNumber, pos: 2, resultOk: true });

            const _validitySecondsHaveElapsed = validitySecondsHaveElapsed(cachedResult,
              validitySeconds);

            _trace.push({
              ts: Date.now(), callNumber, pos: 2.1,
              data: {
                requesting: !!requesting.get(cacheKey),
                validitySeconds,
                _validitySecondsHaveElapsed
              }
            });

            if (_validitySecondsHaveElapsed && !requesting.get(cacheKey)) {

              _trace.push({ ts: Date.now(), callNumber, pos: 2.2, fetching: true });

              requesting.set(cacheKey, true);
              const result = await tryFetch(cache, cacheKey, fetchFn);
              requesting.delete(cacheKey);
              if (result.ok) {
                _trace.push({ ts: Date.now(), callNumber, pos: 2.3, result: dataExtractFn(result) });
                resolve(result.data);
              }
              else {
                _trace.push({ ts: Date.now(), callNumber, pos: 2.4, result: errorExtractFn(result) });
                resolve(result.error);
              }
            }
            else {
              _trace.push({ ts: Date.now(), callNumber, pos: 2.5, cachedResultHash: digest(cachedResult) });
              resolve(cachedResult.data);
            }
          }
          else {
            let _retrySecondsHaveElapsed = retrySecondsHaveElapsed(cachedResult, retrySeconds);

            _trace.push({
              ts: Date.now(),
              callNumber,
              pos: 3,
              data: {
                requesting: !!requesting.get(cacheKey),
                retrySeconds,
                _retrySecondsHaveElapsed
              }
            });

            if (_retrySecondsHaveElapsed && !requesting.get(cacheKey)) {
              requesting.set(cacheKey, true);
              const result = await tryFetch(cache, cacheKey, fetchFn);
              requesting.delete(cacheKey);
              if (result.ok) {
                _trace.push({ ts: Date.now(), callNumber, pos: 3.1, result: dataExtractFn(result) });
                resolve(result.data);
              }
              else {
                _trace.push({ ts: Date.now(), callNumber, pos: 3.2, result: errorExtractFn(result) });
                reject(result.error);
              }
            }
            else {
              _trace.push({ ts: Date.now(), callNumber, pos: 3.3, cachedResult: errorExtractFn(cachedResult) });
              reject(cachedResult.error);
            }
          }
        }
        else {
          _trace.push({ ts: Date.now(), callNumber, pos: 3.4, requesting: !!requesting.get(cacheKey) });
          if (!requesting.get(cacheKey)) {
            requesting.set(cacheKey, true);
            _trace.push({ ts: Date.now(), callNumber, pos: 3.5, requesting: !!requesting.get(cacheKey) });
            const result = await tryFetch(cache, cacheKey, fetchFn);
            requesting.delete(cacheKey);
            _trace.push({ ts: Date.now(), callNumber, pos: 3.6, requesting: !!requesting.get(cacheKey) });
            if (result.ok) {
              _trace.push({ ts: Date.now(), callNumber, pos: 4, result: dataExtractFn(result) });
              resolve(result.data);
            }
            else {
              _trace.push({ ts: Date.now(), callNumber, pos: 5, result: errorExtractFn(result) });
              reject(result.error);
            }
          }
          _trace.push({ ts: Date.now(), callNumber, pos: 6 });
        }
      });
    }
  };
}

export function newAxiosCache(axiosInstance, retrySeconds = DEFAULT_RETRY_SECONDS) {
  const cache = newCache(retrySeconds);
  return {
    trace: cache.trace,
    get: async function (url, config) {
      return cache.getCached(
        { url, config },
        async () => {
          return await axiosInstance.get(url, config);
        },
        {
          dataExtractFn: result => result.data.data,
          errorExtractFn: result => result.error.message,
        },
      );
    }
  };
}
