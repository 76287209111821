import _ from "lodash";
import { CarePlansAPI } from "../../../../CarePlansAPI";
import { newProperty } from "../../utils/property-utils";
import {
  newCatalogProperty,
  newLazyCatalogProperty,
} from "../../utils/catalog-utils";

export const lifecycleStatusProperty = newCatalogProperty({
  propertyPath: "lifecycleStatus",
  items: [
    "proposed",
    "planned",
    "accepted",
    "active",
    "on-hold",
    "completed",
    "cancelled",
    "entered-in-error",
    "rejected",
  ],
  optionMapper: (value) => ({ value, display: _.startCase(value) }),
});

export const achievementStatusProperty = newLazyCatalogProperty({
  propertyPath: "achievementStatus",
  catalogFetcher: CarePlansAPI.getGoalAchievmentStatusValueSet,
  itemMapper: (valueSet) =>
    valueSet.concept.reduce((items, concept) => {
      const { code, display, concept: subConcepts } = concept;
      items.push({ code, display });
      if (subConcepts) {
        for (const item of subConcepts) {
          items.push({ code: item.code, display: item.display });
        }
      }
      return items;
    }, []),
  optionMapper: ({ code, display }) => ({ value: code, display }),
});

export const categoryProperty = newLazyCatalogProperty({
  propertyPath: "category",
  catalogFetcher: CarePlansAPI.getGoalCategoriesValueSet,
  itemMapper: (valueSet) => valueSet?.compose?.include?.[0]?.concept,
  optionMapper: ({ code, display }) => ({ value: code, display }),
});

export const descriptionProperty = newProperty("description.text");
export const startDateProperty = newProperty("startDate");
export const dueDateProperty = newProperty("target[0].dueDate");
export const ownerProperty = newProperty("expressedBy.reference");
