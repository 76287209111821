import * as Sentry from '@sentry/react'
import { getErrorMessage } from './getErrorMessage'

export function handleError(error, setUserHistory, setLoading) {
  const errorMessage = getErrorMessage(error);
  Sentry.captureMessage(errorMessage)
  setLoading(false);
  setUserHistory({
    data: {
      instances: [
        {
          error: errorMessage
        }
      ],
      history: [
        {
          error: errorMessage
        }
      ]
    }
  })
}
