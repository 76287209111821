import React, { Fragment } from 'react';
import moment from 'moment';
import styles from './styles/viewer.module.css';

const {
  appVersion,
  appVersionTitle,
  appVersionBranch,
  appVersionHash,
  clientVersion,
  serverVersion
} = styles;

const CommitInfoContent = ({ commitInfo }) => {
  if (commitInfo) {
    const { branch, tag, shortHash, date } = commitInfo;
    const tagOrBranch = tag.startsWith(shortHash) ? branch : tag;
    return (
      <Fragment>
          <span className={appVersionBranch}>
          {`${tagOrBranch} `}
            <span className={appVersionHash}>
            {`(${shortHash})`}
          </span>
        </span>
          <span>
          {moment.utc(date).format(' LLLL [UTC]')}
        </span>
      </Fragment>
    );
  }
  return <Fragment/>;
}

function extractErrorMessage(errorMsg) {
  const errorStartStr = "ERROR: ";
  if (errorMsg.toUpperCase().startsWith(errorStartStr)) {
    return errorMsg.substring(errorStartStr.length);
  }
  return errorMsg;
}

export const AppVersion = ({ commitResult, isServer = false }) => {
  const { commitInfo, error } = commitResult;
  return (
    <span className={`${appVersion} ${isServer ? serverVersion : clientVersion}`}>
      <span className={appVersionTitle}>
        {isServer ? 'Server' : 'Client'}:
      </span>
      <CommitInfoContent commitInfo={commitInfo} />
      {
        error && <span>{extractErrorMessage(error)}</span>
      }
    </span>
  );
};
