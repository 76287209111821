import React from 'react';
import { Button, FormHelperText, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    color: 'grey',
    textAlign: 'center'
  },
  button: {
    width: '100%',
    padding: '8px',
    background: 'transparent !important',
    border: '1px solid silver',
    color: '#858585 !important',
    fontWeight: 600
  },
  textField: {
    padding: '0'
  },
  container: {
    padding: '0px 210px'
  }
}));

const DetailsFormConfirm = ({ updateInvite, handleNext, setDisableButton, disableButton, userDetails }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item container justify="center">
        <h3 className={classes.title}>Before finish, we need some details.</h3>
      </Grid>
      <Grid item xs={12} container justify="center" >
        <Formik
          initialValues={{
            email: userDetails.email,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
          }}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            if (!values.firstName) {
              errors.firstName = 'Required';
            }
            if (!values.lastName) {
              errors.lastName = 'Required';
            } else {
              setDisableButton(false)
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            updateInvite(values)
            handleNext('end')
            setSubmitting(false);
            /*const isConfirm = await updateInvite(values.email)
            if(isConfirm){
              handleNext()
            } else {*/
            setDisableButton(true);
            //}
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <Grid container justify="center" spacing={4} direction="row" alignItems="center" className={classes.container}>
              <Grid item justify="center" xs={12} md={12} className={classes.textField}>
                <TextField
                  id="email"
                  label="Email"
                  name="email"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  variant="outlined"
                />
                {errors.email && touched.email && <FormHelperText error>{errors.email}</FormHelperText>}
              </Grid>
              <Grid item justify="center" xs={12} md={12} className={classes.textField}>
                <TextField
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                />
                {errors.firstName && touched.firstName && <FormHelperText error>{errors.firstName}</FormHelperText>}
              </Grid>
              <Grid item justify="center" xs={12} md={12} className={classes.textField}>
                <TextField
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                />
                {errors.lastName && touched.lastName && <FormHelperText error>{errors.lastName}</FormHelperText>}
              </Grid>
              <Grid item justify="center" xs={12} md={12}>
                <Button
                  size="small"
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={disableButton}
                >
                  Finish
                </Button>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default DetailsFormConfirm
