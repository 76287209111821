const colors = {
  baseBackground: '#234256',
  baseColor: '#FFFFFF',
  gray01: '#F3F3F3',
  blueGray: '#CFD8DC',
  lightGray: '#C8C7CB',
  gray02: '#E5E5E8',
  gray03: '#949292',
  baseGray: '#444444',
  black: '#000000',
  hoverColor: '#437ca3',
  baseBlue: '#006181',
  //baseGray: '#E9E6F1'
}

export default colors
