import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    }
  }
}));

function ErrorSnackbar(props) {
  const { errorMessage } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open} autoHideDuration={null} onClose={handleClose}
      >
        <MuiAlert onClose={handleClose} variant="filled" severity="error">
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

ErrorSnackbar.propTypes = {
  errorMessage: PropTypes.string.isRequired,
}

export { ErrorSnackbar }
