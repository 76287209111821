import _ from "lodash";
import {
  newCatalogProperty,
  newLazyCatalogProperty,
} from "../../utils/catalog-utils";
import { CarePlansAPI } from "../../../../CarePlansAPI";

export const taskCodeProperty = newLazyCatalogProperty({
  propertyPath: "code",
  catalogFetcher: async () => await CarePlansAPI.getTaskCodeValueSet(),
  itemMapper: (valueSet) => valueSet?.concept,
  optionMapper: ({ code, display }) => ({ value: code, display }),
});

export const statusProperty = newCatalogProperty({
  propertyPath: "status",
  items: [
    "draft",
    "requested",
    "received",
    "accepted",
    "rejected",
    "ready",
    "cancelled",
    "in-progress",
    "on-hold",
    "failed",
    "completed",
    "entered-in-error",
  ],
  optionMapper: (value) => ({ value, display: _.startCase(value) }),
});

export const priorityProperty = newCatalogProperty({
  propertyPath: "priority",
  items: ["routine", "urgent", "asap", "stat"],
  optionMapper: (value) => ({ value, display: _.startCase(value) }),
});

export const intentProperty = newCatalogProperty({
  propertyPath: "priority",
  items: [
    "unknown",
    "proposal",
    "plan",
    "order",
    "original-order",
    "reflex-order",
    "filler-order",
    "instance-order",
    "option",
  ],
  optionMapper: (value) => ({ value, display: _.startCase(value) }),
});
