import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import colors from '../../../utils/colors';

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: '48%',
        position: 'absolute',
        bottom: '10px'
      },
      svg: {
        color: colors.baseBlue
      }
    },
  }
});

function ConfirmationDialog(props) {

  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState('');

  const handleSave = async () => {
    setSaving(true);
    const error = await props.handleSave();
    if (error) {
      setSaving(false);
      setSaveError(error);
    }
  }

  const reset = () => {
    setSaving(false);
    setSaveError('');
  }

  return (
    <div>
      <Dialog open={props.open} onEnter={reset} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          {
            props.textTransformer && props.textTransformer(props.text)
          }
          {
            !props.textTransformer &&
            <DialogContentText>
              {props.text}
            </DialogContentText>
          }
        </DialogContent>
        <DialogActions>
          {
            props.handleClose &&
            <Button onClick={props.handleClose} color="primary" disabled={saving}>
              Cancel
            </Button>
          }
          <Button onClick={handleSave} color="primary" disabled={saving}>
            {saveError ? "Retry" : "OK"}
          </Button>
        </DialogActions>
        {
          saving &&
          <ThemeProvider theme={theme}>
            <div>
              <CircularProgress/>
            </div>
          </ThemeProvider>
        }
        {
          saveError &&
          <MuiAlert severity="error" elevation={6} variant="filled">
            {saveError}
          </MuiAlert>
        }
      </Dialog>
    </div>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textTransformer: PropTypes.func,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
}

export default ConfirmationDialog;
