import React from "react";
import { TextField } from "@material-ui/core";

export function FormText({
  field,
  type = null,
  readOnly = false,
  classes,
  multiline = false,
  fullWidth = false,
  variant = "standard",
  rows = 3,
}) {
  return (
    <TextField
      required={!field.optional}
      margin="dense"
      className={multiline ? classes.formTextArea : classes.formControl}
      type={type}
      label={field.name}
      value={field.value}
      error={field.error !== ""}
      disabled={readOnly}
      multiline={multiline}
      rows={rows}
      variant={variant}
      fullWidth={fullWidth}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={field.changeHandler}
      helperText={
        field.error !== field.requiredErrorMsg ? field.error : undefined
      }
    />
  );
}
