import { getNormalizedPatientData } from '../utils';

let _patientData = null;

class PatientDataStore {

  async getPatientData(patientId) {
    if (!patientId) {
      _patientData = null;
    }
    else if (!_patientData || _patientData.id !== patientId) {
      _patientData = await getNormalizedPatientData(patientId)
    }
    return _patientData;
  }
}

export const patientDataStore = new PatientDataStore();
