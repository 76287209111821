const Reducer = (state, action) => {
  switch(action.type) {
    case 'UPDATE_DOCUMENT_LIST': {
      return {
        ...state,
        documentList: action.payload
      }
    }
    case 'UPDATE_DOCUMENT_DATA': {
      return {
        ...state,
        selectedDocument: action.payload
      }
    }
    case 'UPDATE_PATIENT_DATA': {
      return {
        ...state,
        patientData: action.payload
      }
    }
    case 'UPDATE_REFERRAL': {
      return {
        ...state,
        referral: action.payload
      }
    }
    default: return state
  }
}

export default Reducer
