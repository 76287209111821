import io from 'socket.io-client';
import { mintProxyUrl, heartbeatExpireSeconds } from './constants';
import auth from './auth';
// import auth from '@nicheaim/utils-auth';

const HEARTBEAT_EVENT = 'heartbeat';
let socket = null;
let isSocketOpen = false;
let intervalId = null;

export function initHeartbeat()
{
  // if (!auth.isSaml) {
  //   return;
  // }
  if (socket === null) {
    socket = io(mintProxyUrl, { transports: ['websocket'] });
    socket.on('connect', () => {
      isSocketOpen = true;
      socket.emit(HEARTBEAT_EVENT, auth.token);
    });
    intervalId = setInterval(
      () => {
        if (socket !== null && isSocketOpen) {
          socket.emit(HEARTBEAT_EVENT, auth.token);
        }
      },
      heartbeatExpireSeconds * 1000
    );
  }
}

export function endHeartbeat()
{
  if (!auth.isSaml) {
    return;
  }
  if (socket !== null && isSocketOpen) {
    socket.close();
    isSocketOpen = false;
    socket = null;
  }
  if (intervalId != null) {
    clearInterval(intervalId);
    intervalId = null;
  }
}
