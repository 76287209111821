import { mintProxyUrl } from "../utils/constants";
import { AxiosHelper } from "../utils/AxiosHelper";

const axiosInstance = AxiosHelper.newInstance();

export class ConfigAPI {
  static async getOrgCustomAttributes() {
    const result = await axiosInstance({
      url: `${mintProxyUrl}/api/Users/org/attributes`,
      method: "GET",
    });
    return result?.data || {};
  }
}
