import _ from "lodash";
import { CareTeam } from "./CareTeam";
import { getResourceReferenceValue } from "./resolve-utils";
import { getDisplayName } from "./domain-utils";
import { wrapPatient } from "./patient-wrapper";
import { Activity } from "./Activity";
import { Goal } from "./Goal";

export class CarePlan {
  data;
  #carePlanBundle;

  constructor(rawCarePlan, carePlanBundle) {
    this.data = rawCarePlan;
    this.#carePlanBundle = carePlanBundle;
  }

  resolve(parentResource, reference) {
    return this.#carePlanBundle.resolve(parentResource, reference);
  }

  getId() {
    return this.data.id;
  }

  getStatus() {
    return _.startCase(this.data.status);
  }

  getCareTeams() {
    return (this.data.careTeam || []).map((careTeamReference) => {
      const rawCareTeam = this.resolve(this.data, careTeamReference);
      return new CareTeam(rawCareTeam, this.#carePlanBundle);
    });
  }

  getAllCareTeamMembers() {
    let memberReferenceValues = Array.from(
      this.getCareTeams().reduce((memberReferenceValuesSet, careTeam) => {
        memberReferenceValuesSet.add(
          careTeam
            .getMembers()
            .map((member) => getResourceReferenceValue(member))
        );
        return memberReferenceValuesSet;
      }, new Set())
    );

    return (memberReferenceValues?.[0] || [])
      .map((referenceValue) => this.resolve(this.data, referenceValue))
      .sort((m1, m2) => (getDisplayName(m1) <= getDisplayName(m2) ? -1 : 1));
  }

  getSubject() {
    return wrapPatient(
      this.resolve(this.data, this.data?.subject?.reference),
      this.#carePlanBundle
    );
  }

  getSubjectReference() {
    return this.data?.subject;
  }

  getActivities() {
    return (this.data.activity || []).map(
      (rawActivity) => new Activity(rawActivity, this)
    );
  }

  getAllCareTeamMemberOptions() {
    return this.getAllCareTeamMembers()?.map((member) => ({
      value: getResourceReferenceValue(member),
      display: getDisplayName(member),
    }));
  }

  getGoals() {
    return this.#carePlanBundle
      .getCarePlanResourcesOfType("Goal", this.getId())
      .map((rawGoal) => new Goal(rawGoal, this));
  }

  addGoal(rawGoal) {
    const goalReferences = this.data.goal || [];
    goalReferences.push({ reference: getResourceReferenceValue(rawGoal) });
    this.data.goal = goalReferences;
    this.#carePlanBundle.addGoal(rawGoal, this.data);
  }

  addTask(newTask) {
    const activities = this.data.activity || [];
    activities.push({
      outcomeReference: [{ reference: getResourceReferenceValue(newTask) }],
    });
    this.data.activity = activities;
    this.#carePlanBundle.addTask(newTask, this.data);
  }

  addCareTeam(newCareTeam) {
    const careTeams = this.data.careTeam || [];
    careTeams.push({ reference: `CareTeam/${newCareTeam.id}`});
    this.data.careTeam = careTeams;
    this.#carePlanBundle.addCareTeam(newCareTeam, this.data);
  }

  getDisplayOption() {
    return (
      getDisplayDetails("title", this) ||
      getDisplayDetails("category[0].coding[0].display", this) ||
      getDisplayDetails("id", this)
    );
  }
}

function getDisplayDetails(property, carePlan) {
  const displayPropertyValue =
    property === "id"
      ? "[Care Plan with No Display Value]"
      : _.get(carePlan.data, property);

  if (!displayPropertyValue) {
    return;
  }

  return [
    property === "id"
      ? displayPropertyValue
      : _.startCase(displayPropertyValue),
    [
      carePlan.getId() ? ` (ID: ${carePlan.getId()})` : null,
      carePlan.getStatus() ? `Status: ${carePlan.getStatus()}` : null,
    ]
      .filter(Boolean)
      .join(", "),
  ].join("");
}
