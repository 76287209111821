import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { noCarePlans } from "../../utils/careplan-utils";
import {
  EMPTY_VALUE,
  getCarePlanMenuItems,
  getCarePlanSelectedItemValue,
  getCarePlanValueRenderer,
} from "./utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function CarePlanSelector({
  loading,
  error,
  carePlanBundle,
  onCarePlanChange,
}) {
  const classes = useStyles();
  const carePlans = carePlanBundle?.getCarePlans();
  const shouldDisable = loading || noCarePlans(carePlans, error) || !!error;
  const [selectedCarePlanId, setSelectedCarePlanId] = useState(EMPTY_VALUE);

  useEffect(() => {
    const selectedCarePlan = (carePlans || []).find(
      (carePlan) => carePlan.getId() === selectedCarePlanId
    );
    if (selectedCarePlan) {
      onCarePlanChange(selectedCarePlan);
    } else {
      onCarePlanChange(null);
    }
  }, [selectedCarePlanId, carePlans, onCarePlanChange]);

  return (
    <FormControl
      required
      variant={shouldDisable ? "filled" : undefined}
      className={classes.formControl}
      error={!!error}
    >
      <InputLabel id="select-patient-care-plan-label">Care Plan</InputLabel>
      <Select
        labelId={error ? undefined : "select-patient-care-plan-label"}
        value={getCarePlanSelectedItemValue(
          carePlans,
          loading,
          error,
          selectedCarePlanId
        )}
        renderValue={getCarePlanValueRenderer(error)}
        onChange={(event) => {
          setSelectedCarePlanId(event.target.value);
        }}
        disabled={shouldDisable}
        autoWidth
      >
        {getCarePlanMenuItems(carePlans, loading)}
      </Select>
      {loading && <LinearProgress />}
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
}
