import { RESOURCE_TYPES } from "./resource-types";

const RELATIVE_REFERENCE_REGEXP_STRING = [
  "(",
  RESOURCE_TYPES.join("|"),
  ")",
  "\\/[A-Za-z0-9\\-\\.]{1,64}(\\/_history\\/[A-Za-z0-9\\-\\.]{1,64})?",
].join("");

const RELATIVE_REFERENCE_REGEXP = new RegExp(RELATIVE_REFERENCE_REGEXP_STRING);

export function isInternalIdReference(reference) {
  return !!reference?.startsWith("#");
}

export function getBareInternalId(reference) {
  return reference?.length > 0 ? reference?.substring(1) || "" : "";
}

export function isRelativeIdRererence(reference) {
  return RELATIVE_REFERENCE_REGEXP.test(reference || "");
}

export function getIdFromRelativeReference(reference) {
  const referenceComponents = reference?.split("/") || [];
  return referenceComponents.length === 2 ? referenceComponents[1] : "";
}

export function getResourceReferenceValue(resource) {
  if (!resource) {
    return null;
  }
  return `${resource.resourceType}/${resource.id}`;
}

function findResourceInBundle(bundle, referenceValue) {
  const entry = bundle.entry?.find(
    (entry) =>
      entry?.resource?.id === getIdFromRelativeReference(referenceValue)
  );
  if (!entry) {
    return null;
  }
  return entry.resource;
}

export function resolveContainedResource(parentResource, referenceValue) {
  if (!parentResource || !isInternalIdReference(referenceValue)) {
    return null;
  }
  const resource = parentResource?.contained?.find(
    (resource) => resource.id === getBareInternalId(referenceValue)
  );
  if (!resource) {
    return null;
  }
  return resource;
}

export function resolveRelativeResource(bundle, referenceValue) {
  if (!bundle || !isRelativeIdRererence(referenceValue)) {
    return null;
  }
  return findResourceInBundle(bundle, referenceValue);
}
