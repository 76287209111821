import React from 'react';
import { Modal, TextField, Button, Grid, FormHelperText, FormControl, Input, InputLabel, Select } from '@material-ui/core';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';

const theme = createMuiTheme({
  overrides: {
    MuiInputBaseMultiline: {
      backgroundColor: 'white'
    }
  }
})

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    border: '0.1px solid #000',
    borderRadius: '6px',
    backgroundColor: 'white',
    padding: 24,
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    minWidth: 300,
    maxWidth: 600
  },
  title: {
    fontWeight: 700,
    fontSize: '1.4rem'
  }
}));

function TextMaskCustom(props) {
  return (
    <InputMask mask="(1 99) 9999 - 9999" {...props} />
  );
}



function UpdateInfoModal({ open, setOpen, sendInfo, updateType, tempInfo }) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false)
  }

  const body = updateType === 'users' ? (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1 className={classes.title}>Update Info</h1>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{ 
                email: tempInfo.username ? tempInfo.username : '', 
                firstName: tempInfo.firstName ? tempInfo.firstName : '', 
                lastName: tempInfo.lastName ? tempInfo.lastName : '', 
              }}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                if (!values.firstName) {
                  errors.firstName = 'Required';
                }
                if (!values.lastName) {
                  errors.lastName = 'Required';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                const toCapitalize = (word) => {
                  return word.replace(/^\w/, (c) => c.toUpperCase())
                }
                sendInfo({
                  userName: tempInfo.username,
                  newUsername: values.email,
                  firstName: toCapitalize(values.firstName),
                  lastName: toCapitalize(values.lastName)
                })
                setSubmitting(false);
                handleClose()
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit
              }) => (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField 
                      id="email" 
                      label="New Email" 
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      fullWidth
                    />
                    {errors.email && touched.email && <FormHelperText error>{errors.email}</FormHelperText>}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      id="firstName" 
                      label="First Name"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      fullWidth
                    />
                    {errors.firstName && touched.firstName && <FormHelperText error>{errors.firstName}</FormHelperText>}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      id="lastName" 
                      label="Last Name" 
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      fullWidth
                    />
                    {errors.lastName && touched.lastName && <FormHelperText error>{errors.lastName}</FormHelperText>}
                  </Grid>
                  <Grid container direction="row" justify="flex-end" alignItems="center" spacing={12}>
                    <Button variant="outlined" color="primary" style={{margin: 8, border: 0}} onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="outlined" color="primary" style={{margin: 8, border: 0, marginRight: 0}} onClick={handleSubmit}>
                        Save
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Formik>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1 className={classes.title}>Update Info</h1>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{ 
                email: tempInfo.email ? tempInfo.email : '', 
                firstName: tempInfo.firstName ? tempInfo.firstName : '', 
                lastName: tempInfo.lastName ? tempInfo.lastName : '', 
                cellPhone: tempInfo.cellPhone ? tempInfo.cellPhone : '', 
                roles: tempInfo.roles ? tempInfo.roles : '', 
              }}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                if (!values.firstName) {
                  errors.firstName = 'Required';
                }
                if (!values.lastName) {
                  errors.lastName = 'Required';
                }
                if (!values.cellPhone) {
                  errors.cellPhone = 'Required';
                }
                if (!values.roles) {
                  errors.roles = 'Required';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                const phoneRegex = /([^()-\s]\d*)/g
                const numberConvert = values.cellPhone.match(phoneRegex).join('')
                const toCapitalize = (word) => {
                  return word.replace(/^\w/, (c) => c.toUpperCase())
                }
                sendInfo({
                  ...values,
                  email: tempInfo.email,
                  newEmail: values.email, 
                  cellPhone: numberConvert,
                  firstName: toCapitalize(values.firstName),
                  lastName: toCapitalize(values.lastName)
                })
                setSubmitting(false);
                handleClose()
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit
              }) => (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField 
                      id="email" 
                      label="Email" 
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      fullWidth
                    />
                    {errors.email && touched.email && <FormHelperText error>{errors.email}</FormHelperText>}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      id="firstName" 
                      label="First Name"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      fullWidth
                    />
                    {errors.firstName && touched.firstName && <FormHelperText error>{errors.firstName}</FormHelperText>}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      id="lastName" 
                      label="Last Name" 
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      fullWidth
                    />
                    {errors.lastName && touched.lastName && <FormHelperText error>{errors.lastName}</FormHelperText>}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                    <InputLabel htmlFor="formatted-text-mask-input">Phone number</InputLabel>
                      <Input
                        value={values.cellPhone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="cellPhone"
                        id="cellPhone"
                        inputComponent={TextMaskCustom}
                      />
                    </FormControl>
                    {errors.cellPhone && touched.cellPhone && <FormHelperText error>{errors.cellPhone}</FormHelperText>}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" >
                      <InputLabel htmlFor="outlined-age-native-simple">Role</InputLabel>
                      <Select
                        name="roles"
                        native
                        autoWidth
                        value={values.roles}
                        onChange={handleChange}
                        label="Role"
                      >
                        <option aria-label="None" value="" />
                        <option value="Manager">Manager</option>
                        <option value="User">User</option>
                      </Select>
                      {errors.roles && touched.roles && <FormHelperText error>{errors.roles}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid container direction="row" justify="flex-end" alignItems="center" spacing={12}>
                    <Button variant="outlined" color="primary" style={{margin: 8, border: 0}} onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="outlined" color="primary" style={{margin: 8, border: 0, marginRight: 0}} onClick={handleSubmit}>
                        Save
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Formik>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  )

  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="user-invite-modal"
        aria-describedby="user-invite-modal"
    >
        {body}
    </Modal>
  );
}

export default UpdateInfoModal;
