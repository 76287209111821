import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import AddCircle from '@material-ui/icons/AddCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DocumentUtils, ReferralUtils } from '../../../services';
import { DocumentCatalogsStore } from "../Documents/DocumentCatalogsStore";

const MIN_SEARCH_TERM_LENGTH = 3;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  list: {
    width: 398,
    height: 350,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    minWidth: 400,
  },
  searchTextInputBorder: {
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginTop: theme.spacing(1),
  },
  searchTextInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIconButton: {
    padding: 10,
  },
  tooltipItemTitle: {
    fontVariantCaps: 'small-caps',
    marginRight: '10px',
  },
  tooltipItemCell: {
    paddingTop: '4px',
    paddingBottom: '4px',
    borderTop: '1px solid rgba(255, 255, 255, 0.54)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.54)',
    verticalAlign: 'middle',
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function TransferList(props) {
  const { documents, referral, onLinkableContentsChanged } = props;
  const classes = useStyles();
  const [linked, setLinked] = useState(ReferralUtils.findLinkedDocuments(documents, referral));
  const [selected, setSelected] = useState([]);
  const [available, setAvailable] = useState(not(documents, linked));
  const [linkable, setLinkable] = useState(linked);
  const [searchTerm, setSearchTerm] = useState('');
  const [catalogs, setCatalogs] = useState(null);

  useEffect(
    () => {
      (async () => {
        setCatalogs(await DocumentCatalogsStore.getDocumentCatalogs());
      })();
    }
  )

  useEffect(
    () => {
      const _linked = ReferralUtils.findLinkedDocuments(documents, referral);
      setLinked(_linked);
      setLinkable(_linked);
      setAvailable(not(documents, _linked))
    },
    [documents, referral]
  )

  const availableSelected = intersection(selected, available);
  const linkableSelected = intersection(selected, linkable);

  const clearSearchTerm = () => setSearchTerm('');

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  }

  const handleToggle = (value) => () => {
    const currentIndex = selected.indexOf(value);
    const newChecked = [...selected];
    if (currentIndex === -1) {
      newChecked.push(value);
    }
    else {
      newChecked.splice(currentIndex, 1);
    }
    setSelected(newChecked);
  };

  const numberOfSelected = (items) => intersection(selected, items).length;

  const moveCheckedRight = () => {
    setLinkable(linkable.concat(availableSelected));
    setAvailable(not(available, availableSelected));
    setSelected(not(selected, availableSelected));
  };

  const moveAllRight = () => {
    clearSearchTerm();
    setLinkable(linkable.concat(available));
    setAvailable([]);
    setSelected([]);
  };

  const moveCheckedLeft = () => {
    setAvailable(available.concat(linkableSelected));
    setLinkable(not(linkable, linkableSelected));
    setSelected(not(selected, linkableSelected));
  };

  const moveAllLeft = () => {
    setAvailable(available.concat(not(linkable, linked)));
    setLinkable(linked);
    setSelected([]);
  };

  const selectedText = (items) => (
    <div style={{ fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.54)', marginTop: '0.5rem' }}>
      {`${numberOfSelected(items)}/${items.length} selected`}
    </div>
  )

  const TooltipItems = ({ children }) => (
    <table style={{ marginTop: '5px', marginBottom: '5px' }}>
      {children}
    </table>
  )

  const TooltipItem = ({ title, text }) => (
    text
      ? (
        <tr>
          <td className={classes.tooltipItemCell} style={{ paddingLeft: '5px' }}>
            <span className={classes.tooltipItemTitle}>
              {title}:
            </span>
          </td>
          <td className={classes.tooltipItemCell} style={{ paddingRight: '5px' }}>
            {text}
          </td>
        </tr>
      )
      : <Fragment/>
  );

  const hasSearchTerm = () => searchTerm && searchTerm.length >= MIN_SEARCH_TERM_LENGTH;

  const documentMatchesSearchTerm = (document, enableSearch) => {
    if (!enableSearch || !hasSearchTerm()) {
      return true;
    }
    return Object
      .values(omit(DocumentUtils.getDisplayInfo(document, catalogs), ['iconName', 'size']))
      .find(value => (
          value
          && typeof value === 'string'
          && value.toUpperCase().includes(searchTerm.toUpperCase())
        )
      );
  }

  const documentList = (title, documents, enableSearch) => (
    <Fragment>
      <div style={{
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        marginBottom: enableSearch ? '-10px' : '8px',
        marginTop: enableSearch ? '0' : '8px',
      }}>
        {title}
        {enableSearch &&
          <IconButton
            aria-label="Add Document"
            color="primary"
            component="span"
            onClick={props.onUploadDocumentRequested}
          >
            <AddCircle />
          </IconButton>
        }
      </div>
      {enableSearch &&
        <FormGroup row>
          <FormControl className={classes.formControl}>
            <Paper className={classes.searchTextInputBorder} variant="outlined">
              <InputBase
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon/>
                  </InputAdornment>
                }
                disabled={documents.length === 0}
                className={classes.searchTextInput}
                inputProps={{ 'aria-label': 'search documents' }}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
              <IconButton
                aria-label="clear-search-term"
                className={classes.searchIconButton}
                onClick={clearSearchTerm}
              >
                <CloseIcon />
              </IconButton>
            </Paper>
          </FormControl>
        </FormGroup>
      }
      <Card variant="outlined">
        <List
          dense
          className={classes.list}
          style={{ height: enableSearch ? '350px' : '396px' }}
          component="div"
          role="list"
        >
          {
            documents
              .filter(document => documentMatchesSearchTerm(document, enableSearch))
              .sort(DocumentUtils.documentCreationDateComparator)
              .map((document) => {
                const docKey = DocumentUtils.getKey(document);
                const labelId = `transfer-list-all-document-${docKey}-label`;
                const docInfo = DocumentUtils.getDisplayInfo(document, catalogs);
                return (
                  <ListItem
                    key={docKey}
                    role="listitem"
                    button
                    disableRipple
                    disabled={linked.indexOf(document) >= 0}
                    onClick={handleToggle(document)}
                    selected={selected.indexOf(document) !== -1}
                  >
                    <Tooltip
                      title={
                        <TooltipItems>
                          <TooltipItem title="Content Type" text={docInfo.contentType}/>
                          <TooltipItem title="Size" text={docInfo.size}/>
                          <TooltipItem title="Author" text={docInfo.author}/>
                          <TooltipItem title="Description" text={docInfo.description}/>
                          <TooltipItem title="Facility Type" text={docInfo.facilityType}/>
                          <TooltipItem title="Practice Setting" text={docInfo.practiceSetting}/>
                          <TooltipItem title="Category" text={docInfo.category}/>
                          <TooltipItem title="Format Code" text={docInfo.formatCode}/>
                        </TooltipItems>
                      }
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon
                          icon={docInfo.iconName}
                          size="2x"
                          style={{ color: '#006181', marginLeft: '10px' }}
                        />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      id={labelId}
                      primary={
                        <div style={{ fontWeight: 'bold', fontSize: 'smaller' }}>
                          {docInfo.title}
                        </div>
                      }
                      secondary={
                        <Fragment>
                          <div style={{ color: 'black', fontStyle: 'italic', fontSize: 'smaller' }}>
                            {docInfo.type}
                          </div>
                          <div style={{ color: '#006181', fontSize: 'x-small' }}>
                            {docInfo.creationDate}
                          </div>
                        </Fragment>
                      }
                    />
                  </ListItem>
                );
            })}
          <ListItem />
        </List>
      </Card>
    </Fragment>
  );

  const notYetLinked = not(linkable, linked);
  const moveAllLeftDisabled = notYetLinked.length === 0;
  if (onLinkableContentsChanged) {
    onLinkableContentsChanged(!moveAllLeftDisabled, notYetLinked);
  }

  if (!catalogs) {
    return null;
  }

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>
        {documentList('Available Documents', available, true)}
        {selectedText(available)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={moveAllRight}
            disabled={available.length === 0 || hasSearchTerm()}
            aria-label="Move all right"
          >
            &gt;&gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={moveCheckedRight}
            disabled={availableSelected.length === 0}
            aria-label="Move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={moveCheckedLeft}
            disabled={linkableSelected.length === 0}
            aria-label="Move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={moveAllLeft}
            disabled={moveAllLeftDisabled}
            aria-label="Move all left"
          >
            &lt;&lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        {documentList('Linked / To Be Linked Documents', linkable)}
        {selectedText(linkable)}
      </Grid>
    </Grid>
  );
}

TransferList.propTypes = {
  documents: PropTypes.object.isRequired,
  referral: ReferralUtils.REFERRAL_DETAIL_SHAPE.isRequired,
  onUploadDocumentRequested: PropTypes.func.isRequired,
  onLinkableContentsChanged: PropTypes.func,
}

export default TransferList;
