import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import colors from "../../../utils/colors";
import { ReferralsAPI } from "../../../services";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: "48%",
        position: "absolute",
        bottom: "10px",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 500,
  },
  checkBox: {
    margin: theme.spacing(1),
    marginBottom: 0,
  },
}));


export function ReferralDialog(props) {

  const { referral, patientId, parentReferralId } = props;

  const wrongUsage = !!(referral && parentReferralId);

  const title = wrongUsage
    ? "Wrong usage"
    : referral
      ? "Update Referral"
      : parentReferralId
        ? "Create Child Referral"
        : "Create Referral"
  ;

  const classes = useStyles();
  const [urgent, setUrgent] = useState(!!referral?.urgent || false);
  const [serviceCategory, setServiceCategory] = useState(referral?.serviceCategory || undefined);
  const [organizationName, setOrganizationName] = useState(referral?.receivingOrganization || "");
  const [receivingFacilityName, setReceivingFacilityName] = useState(referral?.["facility"]?.receivingFacilityName || undefined);
  const [description, setDescription] = useState(referral?.description || "");
  const [serviceCategoryMessage, setServiceCategoryMessage] = useState("");
  const [organizationNameError, setOrganizationNameError] = useState(false);
  const [receivingFacilityNameMessage, setReceivingFacilityNameMessage] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState("");
  const [serviceCategories, setServiceCategories] = useState([]);
  const [facilityNames, setFacilityNames] = useState([]);
  const organizations = useRef(JSON.parse(sessionStorage.getItem("organizations")));

  const orgId = organizationName
    ? organizations.current?.find(org => org["organizationName"] === organizationName)?.["authUniversalId"]
    : undefined
  ;

  // console.log("DEBUG organizations: ", organizations);

  useEffect(
    () => {
      ReferralsAPI.getFacilityNames(orgId).then(facilityNames => {
        const _facilityNames = Array.from(new Set([receivingFacilityName, ...facilityNames])).filter(Boolean);
        if (!facilityNames || (Array.isArray(_facilityNames) && _facilityNames.length === 0)) {
          setReceivingFacilityNameMessage("There are no facilitiy names for selected Organization.");
        }
        setFacilityNames(_facilityNames);
      });
    },
    [orgId, receivingFacilityName],
  );

  useEffect(
    () => {
      ReferralsAPI.getServiceCategories(orgId).then(serviceCategories => {
        setServiceCategories(serviceCategories);
        if ((!serviceCategories || serviceCategories?.length === 0) && organizationName) {
          setServiceCategoryMessage("There are no service categories for selected Organization.");
        }
      });
    },
    [orgId],
  );

  useEffect(() => {
    console.log("DEBUG orgId: ", orgId);
  }, [orgId])
  

  const isInvalid = (value) => value.trim() === "";

  const isFormValid = (showErrors = false) => {
    const isInvalidOrgName = !referral && isInvalid(organizationName);
    const isInvalidDescription = isInvalid(description);
    if (showErrors) {
      setOrganizationNameError(isInvalidOrgName);
      setDescriptionError(isInvalidDescription);
    }
    return !(isInvalidOrgName || isInvalidDescription);
  };

  const handleSave = async () => {
    setSaveError("");
    if (!isFormValid(true)) {
      return;
    }
    setSaving(true);
    let referralToSave;
    if (referral) {
      referralToSave = {
        urgent,
        receivingFacilityName,
        description,
      };
    } else {
      referralToSave = {
        parentReferralId,
        description,
        patientId,
        urgent,
        serviceCategory,
        receivingOrganizationName: organizationName,
        receivingFacilityName
      };
    }
    const error = await props.handleSave(referralToSave);
    if (error) {
      setSaving(false);
      setSaveError(error);
    }
  };

  const handleIsUrgentChange = (event) => {
    setUrgent(event.target.checked);
  };

  const handleServiceCategoryChange = (event) => {
    setServiceCategory(event.target.value);
  };

  const handleOrganizationNameChange = (event) => {
    setOrganizationName(event.target.value);
    setOrganizationNameError(isInvalid(event.target.value, true));
    setServiceCategories([]);
    setServiceCategoryMessage("");
    setReceivingFacilityName(undefined);
    setReceivingFacilityNameMessage("");
    setFacilityNames([]);
  };

  const handleFacilityNameChange = (event) => {
    setReceivingFacilityName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    setDescriptionError(isInvalid(event.target.value, true));
  };

  const reset = () => {
    setSaving(false);
    if (!referral) {
      setUrgent(false);
      setServiceCategory(undefined);
      setOrganizationName("");
      setReceivingFacilityName(undefined);
      setDescription("");
      setServiceCategoryMessage("");
      setOrganizationNameError(false);
      setReceivingFacilityNameMessage("");
      setDescriptionError(false);
      setSaveError("");
    }
  };

  return (
    <div>
      <Dialog
        open={props.open} onClose={props.handleClose} onEnter={reset}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <FormGroup row>
            <FormControl className={classes.checkBox}>
              <FormControlLabel
                disabled={wrongUsage}
                control={
                  <Checkbox
                    autoFocus
                    checked={urgent}
                    onChange={handleIsUrgentChange}
                  />
                }
                label="Urgent"
              />
            </FormControl>
          </FormGroup>
          {
            !referral &&
            <FormGroup row>
              <FormControl className={classes.formControl} error={organizationNameError}>
                <InputLabel id="referral-organization-name-label">
                  Organization Name
                </InputLabel>
                <Select
                  labelId="referral-organization-name-label"
                  required
                  value={organizationName}
                  onChange={handleOrganizationNameChange}
                >
                  <MenuItem value="">
                    None
                  </MenuItem>
                  {
                    organizations?.current?.map(org => org["organizationName"]).map(organizationName => (
                      <MenuItem value={organizationName}>
                        {organizationName}
                      </MenuItem>
                    ))
                  }
                </Select>
                {
                  organizationNameError &&
                  <FormHelperText>Organization Name is required.</FormHelperText>
                }
              </FormControl>
            </FormGroup>
          }
          {
            !referral &&
            <FormGroup row>
              <FormControl className={classes.formControl}>
                <InputLabel id="referral-service-category-label">
                  Organization Service Category
                </InputLabel>
                <Select
                  labelId="referral-service-category-label"
                  required
                  disabled={serviceCategories.length === 0}
                  value={serviceCategory}
                  onChange={handleServiceCategoryChange}
                >
                  {
                    serviceCategories?.map(serviceCategory => (
                      <MenuItem key={serviceCategory} value={serviceCategory}>
                        {serviceCategory}
                      </MenuItem>
                    ))
                  }
                </Select>
                {
                  serviceCategoryMessage &&
                  <FormHelperText>{serviceCategoryMessage}</FormHelperText>
                }
              </FormControl>
            </FormGroup>
          }
          <FormGroup row>
            <FormControl className={classes.formControl}>
              <InputLabel id="referral-facility-name-label">
                Organization Facility Name
              </InputLabel>
              <Select
                labelId="referral-facility-name-label"
                disabled={wrongUsage || facilityNames.length === 0}
                required
                value={receivingFacilityName}
                onChange={handleFacilityNameChange}
              >
                {
                  facilityNames.map(facilityName => (
                    <MenuItem key={facilityName} value={facilityName}>{facilityName}</MenuItem>
                  ))
                }
              </Select>
              {
                receivingFacilityNameMessage &&
                <FormHelperText>{receivingFacilityNameMessage}</FormHelperText>
              }
            </FormControl>
          </FormGroup>
          <FormGroup row>
            <FormControl className={classes.formControl} error={descriptionError}>
              <TextField
                disabled={wrongUsage}
                required
                multiline
                rows={4}
                variant="outlined"
                margin="dense"
                label="Description"
                fullWidth
                value={description}
                onChange={handleDescriptionChange}
              />
              {
                descriptionError &&
                <FormHelperText>Description is required.</FormHelperText>
              }
            </FormControl>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary" disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={wrongUsage || saving || !isFormValid()}>
            {saveError ? "Retry" : "Save"}
          </Button>
        </DialogActions>
        {
          saving &&
          <ThemeProvider theme={theme}>
            <div>
              <CircularProgress/>
            </div>
          </ThemeProvider>
        }
        {
          saveError &&
          <MuiAlert severity="error" elevation={6} variant="filled">
            {saveError}
          </MuiAlert>
        }
        {
          wrongUsage &&
          <MuiAlert severity="error" elevation={6} variant="filled">
            <code>referral</code> and <code>parentReferralId</code> cannot be used at the same time.
          </MuiAlert>
        }
      </Dialog>
    </div>
  );
}

ReferralDialog.propTypes = {
  referral: PropTypes.shape({
    description: PropTypes.string.isRequired,
    receivingOrganizationName: PropTypes.string.isRequired,
    urgent: PropTypes.bool.isRequired,
  }),
  patientId: PropTypes.string.isRequired,
  parentReferralId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};