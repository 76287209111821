import React, { Fragment, useEffect, useState } from "react"
import { get } from 'lodash'
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Alert from '@material-ui/lab/Alert'
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { LoadProgress } from './LoadProgress'
import { ItemList } from './ItemList'
import { UserHistory, handleError } from '../../services'
import colors from '../../utils/colors'

const useStyles = makeStyles(theme => (
  {
    root: {
      width: "100%",
      maxWidth: 400,
      backgroundColor: theme.palette.background.paper,
    },
    disabled: {
      color: 'rgba(0, 0, 0, 0.26)',
    },
    icon: {
      minWidth: '30px'
    },
    itemIcon: {
      minWidth: '30px'
    },
    itemLabel: {
      fontSize: '15px',
      width: '100%'
    },
    infoLabel: {
      color: colors.baseBlue
    },
    nested: {
      paddingLeft: '2px',
      paddingBottom: '26px'
    },
    patientInfo: {
      fontSize: '10px',
      left: '33px',
      position: 'absolute',
      top: '30px',
      width: ''
    },
    title: {
      paddingLeft: '0'
    }
  }
))

export function CategoryList({
  token,
  authenticated,
  useMockData,
  categoryName,
  dataUrl,
  dataPath,
  labelProperty,
  CategoryIconComponent,
  ItemIconComponent,
  itemLimit,
  onItemClick,
}) {

  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(
    () => {
      UserHistory(dataUrl, token, useMockData)
        .then(_data => {
          setLoading(false);
          setData(_data);
        })
        .catch(error => handleError(error, setData, setLoading))
      ;
    },
    [token, authenticated, dataUrl, onItemClick, useMockData]
  )

  if (data && data.message) {
    return (
      <Alert severity="error">
        <div>
          Can't render user history due to server error:
        </div>
        <b>{data.message}</b>
      </Alert>
    )
  }

  let valueLabelPairsArray = get(data, dataPath, []).map(obj => (
    {
      value: obj,
      label: obj[labelProperty],
      error: obj.error
    }
  ))

  if (valueLabelPairsArray.length === 0) {
    valueLabelPairsArray = undefined;
  }

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleItemClick = (value) => {
    if (onItemClick) {
      onItemClick({patientData: value})
    }
  };

  const hasItems = (
    !loading
    && valueLabelPairsArray
    && valueLabelPairsArray.length > 0
  );

  const error = (
    valueLabelPairsArray
    && valueLabelPairsArray.length === 1
    && valueLabelPairsArray[0].error
  );

  const enabled = hasItems && !error;

  const noop = () => {
  };

  if (!authenticated) {
    return <Alert severity="error">User is not authenticated.</Alert>
  }

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem
        button={enabled}
        onClick={enabled ? toggleOpen : noop}
        color={enabled ? undefined : 'disabled'}
        className={classes.title}
      >
        <ListItemIcon className={classes.icon}>
          {
            loading
              ? <LoadProgress/>
              : (
                <CategoryIconComponent
                  color={enabled ? undefined : 'disabled'}
                />
              )
          }
        </ListItemIcon>
        <ListItemText
          primary={categoryName}
          className={enabled ? undefined : classes.disabled}
        />
        {
          enabled ? (
            open ? <ExpandLess/> : <ExpandMore/>
          ) : <Fragment/>
        }
      </ListItem>
      {
        <ItemList
          valueLabelPairsArray={valueLabelPairsArray}
          ItemIconComponent={ItemIconComponent}
          itemLimit={itemLimit}
          classes={classes}
          handleItemClick={handleItemClick}
          open={open}
        />
      }
    </List>
  )
}

