export function noCarePlans(carePlans, error) {
  return (carePlans || []).length === 0 && !error;
}

export function newEmptyTask() {
  return {
    resourceType: "Task",
    status: "draft",
    intent: "unknown",
  };
}
