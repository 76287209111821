import _ from "lodash";

export function validateTarget(target, propertyName, isSetter) {
  if (!target) {
    const action = isSetter ? "setting" : "getting";
    throw new Error(
      `Error ${action} value for property "${propertyName}". Target object was not provided.`
    );
  }
}

function newGetter(propertyPath) {
  return (target, bundle) => {
    validateTarget(target, propertyPath);
    const value = _.get(target, propertyPath);
    return bundle ? bundle.resolve(target, value) : value || "";
  };
}

function newSetter(propertyPath) {
  return (target, value) => {
    validateTarget(target, propertyPath, true);
    _.set(target, propertyPath, value);
    return target;
  };
}

export function newProperty(propertyPath) {
  return {
    getFrom: newGetter(propertyPath),
    setTo: newSetter(propertyPath),
  };
}
