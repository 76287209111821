import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { dateTimeRenderer, yesNoRenderer } from '../value-renderers';
import { Link } from '@material-ui/icons';
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import styles from "../../../styles/viewer.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import { DeleteIconButton } from "../DeleteIconButton";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgb(0, 97, 129)',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function ReferralReferencesTable({ references, onReferenceDelete }) {
  const classes = useStyles();
  return (
    <>
      <h1 style={{
        marginTop: '20px',
        marginBottom: '10px',
        fontSize: '18px',
        fontWeight: 'bold',
      }}>
        References
      </h1>
      <TableContainer component={Paper}>
        <Table size="small" className={classes.table} aria-label="Referral Details Table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Date/Time</StyledTableCell>
              <StyledTableCell>Author</StyledTableCell>
              <StyledTableCell>Annotation</StyledTableCell>
              <StyledTableCell>Link</StyledTableCell>
              <StyledTableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {references.map((reference) => (
              <StyledTableRow key={reference.createTs}>
                <StyledTableCell component="th" scope="row">
                  {dateTimeRenderer(reference.createTs)}
                </StyledTableCell>
                <StyledTableCell>
                  {reference.createdByUserName}
                </StyledTableCell>
                <StyledTableCell>
                  {reference.annotation}
                </StyledTableCell>
                <StyledTableCell>
                  <a href={reference.documentUrl} target="_blank" rel="noopener noreferrer">
                    <Link style={{ color: "black"}}/>
                  </a>
                </StyledTableCell>
                <StyledTableCell style={{ width: "5px"}}>
                  <DeleteIconButton caption="Delete Reference" onClick={() => onReferenceDelete({
                    id: reference.id,
                    type: 'url',
                    title: 'Delete Document Reference?'
                  })}/>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
