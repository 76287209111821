// noinspection JSValidateTypes

import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField
} from '@material-ui/core';
import styles from '../../../styles/viewer.module.css';
import CuresAPI from '../../../services/CuresAPI';
import auth from '../../../utils/auth';

const RegisterModal = ({ userData, open, setOpen, onUserSaved }) => {

  const [userType, setUserType] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [formData, setFormData] = useState({
    givenName: '',
    familyName: '',
    NPI: '',
    SLN: '',
  });
  const [missingData, setMissingData] = useState(false);
  const [serverError, setServerError] = useState(false);

  const handleFormData = ({ target }) => {
    setFormData(formData => ({
      ...formData,
      [target.name]: target.value
    }));
  };

  const handleChangeBusinessName = (e) => {
    console.log("DEBUG handleChangeBusinessName: ", e.target.value)
    setBusinessName({ title: e.target.value });
  }

  const handleSubmit = () => {
    console.log('DEBUG: formData: ', formData)
    if (
      !formData.familyName
      || !formData.givenName
      || !formData.SLN
    ) {
      return setMissingData(true);
    }
    if (userType === 'Prescriber' && !formData.NPI) {
      return setMissingData(true);
    }
    if (userType === 'Pharmacist' && !businessName) {
      return setMissingData(true);
    }
    setMissingData(false);
    const userData = {
      userType,
      businessName: businessName?.title,
      formData,
      username: auth.username
    };
    CuresAPI.saveCuresUser(userData)
      .then(savedUser => {
        onUserSaved(savedUser);
      })
      .catch(_ => setServerError(true));
  };

  const fillUserData = useCallback(() => {
    const { providerData, providerType } = userData;
    setUserType(providerType);
    setFormData({
      SLN: providerData.SLN,
      NPI: providerData.NPI,
      familyName: providerData.familyName,
      givenName: providerData.givenName
    });
    if (providerType === 'Pharmacist') {
      setBusinessName({ title: providerData.businessName });
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.['providerData']) {
      return fillUserData();
    }
  }, [userData, fillUserData]);

  useEffect(() => {
    console.log("DEBUG businessName: ", businessName)
  }, [businessName])
  

  return (
    <Dialog open={open} handleClose={() => setOpen(false)}>
      <DialogTitle>Please complete your profile to get CURES data</DialogTitle>
      <DialogContent>
        <div className={styles.curesRegistration}>
          <TextField label="Select your user type" select value={userType}
            onChange={e => setUserType(e.target.value)} style={{ marginTop: '-10px' }}>
            <MenuItem value="Prescriber">Prescriber</MenuItem>
            <MenuItem value="Pharmacist">Pharmacist</MenuItem>
          </TextField>
        </div>
        {
          userType ?
            <div className={styles.curesRegistration}>
              <TextField name="SLN" onChange={handleFormData} label={`${userType} SLN*`}
                placeholder={`${userType} SLN*`} value={formData.SLN}/>
            </div>
            :
            null
        }
        {
          userType === 'Prescriber' ?
            <div className={styles.curesRegistration}>
              <TextField name="NPI" onChange={handleFormData} label={`${userType} NPI*`}
                placeholder={`${userType} NPI*`} value={formData.NPI}/>
            </div>
            : userType === 'Pharmacist' ?
              <div className={styles.curesRegistration}>
                <TextField label="Business Name" placeholder={`Business Name*`} value={businessName?.title}
                  margin="normal" onChange={e => handleChangeBusinessName(e)}/>
              </div>
              : null
        }
        {
          userType ?
            <div className={styles.curesRegistration}>
              <TextField onChange={handleFormData} name="givenName" label="Given Name"
                placeholder={`Given Name*`} value={formData.givenName}/>
              <TextField onChange={handleFormData} name="familyName" label="Family Name"
                placeholder={`Family Name*`} value={formData.familyName}/>
            </div>
            :
            null
        }
        {missingData && <p style={{
          color: 'red',
          fontSize: '0.9rem',
          textAlign: 'center',
          margin: '15px 0 0',
          fontWeight: 'bold'
        }}>Please fill all inputs</p>}
        {serverError && <p style={{
          color: 'red',
          fontSize: '0.9rem',
          textAlign: 'center',
          margin: '15px 0 0',
          fontWeight: 'bold'
        }}>An error ocurred, please try again</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>CANCEL</Button>
        {userType && <Button onClick={handleSubmit}>SAVE</Button>}
      </DialogActions>
    </Dialog>
  )
}

export default RegisterModal
